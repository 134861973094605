import { v4 as uuidv4 } from 'uuid';
import zips from './zips.json';

const findGmapsCounty = (data) => {
    const potential_counties = data.filter(item => {
        if (item.types.includes('administrative_area_level_1') || item.types.includes('administrative_area_level_2') || item.types.includes('administrative_area_level_3')) {
            return true;
        }
        return false;
    })

    return potential_counties.find(county => county.types[0] === 'administrative_area_level_3')
    || potential_counties.find(county => county.types[0] === 'administrative_area_level_2')
    || potential_counties.find(county => county.types[0] === 'administrative_area_level_1')
}

const findGmapsCountry = (data) => {
    const country = data.filter(item => {
        if (item.types.includes('country')) {
            return true;
        }
        return false;
    })

    return country[0];
}

const findGmapsZip = (data) => {
    const country = data.filter(item => {
        if (item.types.includes('postal_code')) {
            return true;
        }
        return false;
    })

    return country[0];
}

//Check if this url only returned a generic country address
const returnedCountry = (data) => {
    if (data.length !== 1) {
        return false;
    }
    const country = findGmapsCountry(data);
    if (!country) {
        return false;
    }
    return true;
}

//Google maps is weirdly bad at handling addresses with # in them. So we are removing them as they have
// no impact on geocoding
const formatAddressWithHashtag = (address) => {
    const index = address.indexOf('#');
    // # does not exist in this address
    if (index < 0) {
        return address
    }
    //Return index only up to where # showed up
    else {
        return address.slice(0, index);
    }
}

const geocodeAddresses = async (data, index, searchCountry) => {
    return new Promise(async (resolve, reject) => {
        const url = data.latitude && data.longitude
        ? `https://maps.googleapis.com/maps/api/geocode/json?latlng=${data.latitude},${data.longitude}&key=${process.env.REACT_APP_GMAPS_KEY}`
        : data.address
        ? `https://maps.googleapis.com/maps/api/geocode/json?address=${formatAddressWithHashtag(data.address)} ${data.city? data.city : ''} ${data.state? data.state : ''}&key=${process.env.REACT_APP_GMAPS_KEY}&components=country:${searchCountry}`
        : `https://maps.googleapis.com/maps/api/geocode/json?address=${data.zip}&key=${process.env.REACT_APP_GMAPS_KEY}&components=country:${searchCountry}`
        resolve(fetch(url)
        .then(addresses => addresses.json())
        .then(resp => {
            if (resp?.error_message) {
                return {
                    ...data,
                    row: index + 1,
                    error: true,
                    personId: data.personId ? data.personId : uuidv4(),
                }
            }
            if (!resp.results) {
                return {
                    ...data,
                    row: index + 1,
                    error: true,
                    personId: data.personId ? data.personId : uuidv4(),
                }
            }
            //Only one component and it is the entire nation
            if (returnedCountry(resp?.results[0]?.address_components)) {
                return {
                    ...data,
                    row: index + 1,
                    error: true,
                    personId: data.personId ? data.personId : uuidv4(),
                }
            }
            //Just taking the first item as it is likely the most accurate to given address
            const newResult = resp.results.slice(0,1).map((result) => {
                const county = findGmapsCounty(result.address_components);
                const zip = data.zip ? data.zip : findGmapsZip(result.address_components)?.long_name;
                return {
                ...data,
                zip,
                personId: data.personId ? data.personId : uuidv4(),
                address: result.formatted_address,
                latitude: result.geometry?.location?.lat || null,
                longitude: result.geometry?.location?.lng || null,
                county: county?.long_name,
            }})[0];
            return newResult;
        }))
    })
}

const getCsvData = async (data, searchCountry, setLoadingText) => {

    const csvData = await data.map((result, index) =>            
        new Promise(resolve => {
            return setTimeout(() => {
                //Only change loading text every 500 rows
                if (Number.isInteger(index / 500)) {
                    setLoadingText(`Validating rows ${index + 1} to ${index + 500}`)
                }
                if (result.address === 'Address') resolve();
                //If no info in the row, skip
                if (!result.address && !result.zip && (!result.latitude && !result.longitude)) {
                    resolve();
                }
                if (!result.address && zips[result.zip]) {
                    //Get coords under zip code with census tracts
                    const zipsWithId = zips[result.zip].map(zip => {
                        resolve({
                            ...zip,
                            personId: uuidv4(),
                        })
                    })
                    resolve(zipsWithId);
                }
                resolve(geocodeAddresses(result, index, searchCountry))
            },  25000 * Math.floor(index / 500) + 1)
        }
        )
      )
      return Promise.all(csvData).then((addresses) => {
          return addresses
      })
}

async function geocodeBySearchString(searchString, invalidAddress, searchCountry, setResults) {
    return new Promise(async (resolve, reject) => {
        const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${searchString}&key=${process.env.REACT_APP_GMAPS_KEY}&components=country:${searchCountry}`;

        return fetch(url)
        .then(addresses => addresses.json())
        .then(resp => {
            if (resp.status === 'OK') {
                const results = resp.results;
                if (!results) {
                    return;
                }
                if (results.length === 0 || results[0]?.types?.includes('country')) {
                    return;
                }
                const newResults = results.slice(0,5).map((result) => {
                    const county = findGmapsCounty(result.address_components);
                    const zip = result.address_components.find(item => item.types.includes('postal_code'));
                    const city = result.address_components.find(item => item.types.includes('locality'));                  
                    const state = result.address_components.find(item => item.types.includes('administrative_area_level_1'))
                    if (!county || !zip || !city || !state) {
                        return;
                    }
                    return {
                        name: result.formatted_address.split(',')[0],
                        address: result.formatted_address,                            
                        latitude: result.geometry?.location?.lat || null,
                        longitude: result.geometry?.location?.lng || null,
                        county: county?.long_name,
                        city: city?.long_name,
                        state: state?.long_name,
                        zip: zip?.long_name,
                        personId: invalidAddress.personId,
                        row: invalidAddress.row
                    }
                });
                setResults(newResults);
                resolve(newResults);
            }
            return;
        })
    })
}

export {
    findGmapsCounty,
    findGmapsCountry,
    findGmapsZip,
    geocodeAddresses,
    getCsvData,
    geocodeBySearchString,
}
