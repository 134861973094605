import React, {
    useState,
    useEffect
} from 'react';
import Select from 'react-select';
import Switch from "react-switch";
import dropdownOptions from './dropdownOptions.json';
import constants from './constants.json';
import { HexColorInput } from "react-colorful";
import { PopoverPicker } from '../ColorPicker/PopoverPicker';

import { Circles } from 'react-loader-spinner';

import railIcon from '../../images/icons/screens/rail.svg';
import busIcon from '../../images/icons/screens/bus.svg';
import micromobilityIcon from '../../images/icons/screens/micromobility.svg';
import carIcon from '../../images/icons/screens/car.svg';
import otherIcon from '../../images/icons/screens/other-modes.svg';
import mapMarkerIcon from '../../images/icons/screens/map-marker.svg';

const icons = {
    railIcon,
    busIcon,
    micromobilityIcon,
    carIcon,
    otherIcon,
    mapMarkerIcon
};

const mobUrl = process.env.REACT_APP_MOB_URL + "/mobilityscore/v1/score.json?coordinates={coords}&key=" + process.env.REACT_APP_MOB_API_KEY;

async function getMobilityScore(coords) {
    var queryUrl = mobUrl.replace("{coords}", coords);
    let response = await fetch(queryUrl);
    let data = await response.json();

    return data;
}

async function getScoreImage(url) {
    let response = await fetch(url);
    let data = await response.text();

    return data;
}

const TransportationContent = ({
    state,
    setState,
    setStateChanged
}) => {

    let configs = state?.playlist?.transportationConfigs;
    const [scoreDetails, setScoreDetails] = useState(null);

    useEffect(() => {
        if (state?.playlist?.id) {
            let coordinates = state.playlist.location?.latitude
                ? state.playlist.location.latitude + ',' + state.playlist.location.longitude
                : state.playlist.latitude + ',' + state?.playlist.longitude;
            try {
                getMobilityScore(coordinates)
                .then((mobResponse) => {
                    getScoreImage(mobResponse.data?.badgeUrlPng)
                    .then((badgeUrlPngContents) => {
                        mobResponse.data.badgeUrlPngContents = badgeUrlPngContents;
                        setScoreDetails(mobResponse.data);
                    });
                });
            } catch (e) {
                console.log(e);
            }
        }
    }, [state?.playlist]);

    //For transportation configs
    const handleConfigChanges = (propNames, values) => {
        const tempConfigs = {...state.playlist.transportationConfigs};
        propNames.forEach((item, index) => {
            tempConfigs[item] = values[index];
        });

        setState({
            ...state,
            playlist: {
                ...state.playlist,
                transportationConfigs: tempConfigs
            }
        });
        setStateChanged(true);
    }

        //Handling allowed modes
    const handleMultiConfigChange = (property, value) => {
        const tempConfigs = {...state.playlist.transportationConfigs};

        //New value and proeprty
        if (tempConfigs[property] === undefined) {
            tempConfigs[property] = value;
        }
        //Remove from property
        else if (tempConfigs[property].includes(value)) {
            const removeIndex =  tempConfigs[property].indexOf(value);
            if (removeIndex === 0) {
                //Cut the first character and the following comma
                tempConfigs[property] = tempConfigs[property].slice(2);
            }
            else {
                //Cut the first character and the previous comma
                tempConfigs[property] = tempConfigs[property].slice(0, removeIndex - 1) + tempConfigs[property].slice(removeIndex + 1);
            }
        }
        //Add to the property
        else {
            tempConfigs[property] = tempConfigs[property].length > 0 ? tempConfigs[property] + ',' + value : value;
        }


        setState({
            ...state,
            playlist: {
                ...state.playlist,
                transportationConfigs: tempConfigs
            }
        });
        setStateChanged(true);
    }


    const handleSliderChange = (property, value) => {
        const tempConfigs = {...state.playlist.transportationConfigs};
        tempConfigs[property] = value;
        setState({
            ...state,
            playlist: {
                ...state.playlist,
                transportationConfigs: tempConfigs
            }
        });
        setStateChanged(true);
    }


    const allowedModes = constants.ALLOWED_MODES.map(mode => {
        let isChecked = configs?.allowedModes ? configs?.allowedModes.includes(mode.value) : false;
        let icon = null;
        switch(mode.value) {
            case 'r':
                icon = railIcon;
                break;
            case 'b':
                icon = busIcon;
                break;
            case 'm':
                icon = micromobilityIcon;
                break;
            case 'c':
                icon = carIcon;
                break;
            case 'o':
                icon = otherIcon;
                break;
        }

        return <div className="mode-container" key={mode.value}>
            <div className="icon-container"><img src={icon} /></div>
            <div className="label">{mode.label}:</div>
            <Switch
                className="switch"
                checked={ isChecked }
                onChange={() => handleMultiConfigChange('allowedModes', mode.value)}
                onColor="#2386EE"
                uncheckedIcon
                checkedIcon={false}
            />
        </div>
    })

    const getLabelAndSwitch = (label, key) => {
        let onChange = (checked) => handleConfigChanges([key], [checked]);

        return <div className="input-container">
            <div className="label">{label}:</div>
            <Switch
                className="switch"
                checked={ configs && key in configs ? configs[key] : constants.DEFAULT_TRANSPORTATION_CONFIGS[key] }
                onChange={onChange}
                onColor="#2386EE"
                uncheckedIcon
                checkedIcon={false}
            />
        </div>
    }

     const getLabelAndSelect = (label, dropdownKey, configKey) => {
        let onChange = (e) => handleConfigChanges([configKey], [e.value]);

        return <div className="input-container">
            <div className="label">{label}:</div>
            <Select
                onChange={onChange}
                options={dropdownOptions[dropdownKey]}
                defaultValue={dropdownOptions[dropdownKey][0]}
                value={dropdownOptions[dropdownKey].find(option => option.value === (configs ? configs[configKey] : null))}
                className={"input dropdown-list"}
                styles={basicConfigDropdownStyling}
            />
        </div>
    }

    /* React-Select custom styling */
    const basicConfigDropdownStyling = {
        container: (baseStyles, state) => ({
          ...baseStyles,
          // Too much gray if the dropdown is disabled.
          boxShadow: state.isDisabled ? "unset" : "0px 2px 8px 4px rgba(0, 0, 0, 0.07)",
          borderRadius: "8px"
        }),
        control: (baseStyles, state) => ({
            ...baseStyles,
            border: "none",
            cursor: "pointer"
        }),
        indicatorSeparator: (baseStyles, state) => ({
            ...baseStyles,
            display: "none"
        }),
        option: (baseStyles, state) => ({
            ...baseStyles,
            color: state.isDisabled ? "#919191" : "inherit",
            cursor: "pointer"
        })
    };

    const getBadge = () => {
        if (scoreDetails?.badgeUrlPngContents) {
            return <img src={scoreDetails?.badgeUrlPngContents} />
        } else if (state?.playlist?.id) {
            return <Circles
                height="120"
                width="80"
                color="#2386EE"
                ariaLabel="circles-loading"
                visible={true}
            />
        } else {
            return <img src={mapMarkerIcon} />
        }
    }

    return (
        <div className="TransportationContent">
            <div className="photo">
            </div>
            <div className="configuration">
                <h2>TransitScreen&reg;</h2>

                <div className="section" style={{marginTop: "1.5em"}}>
                    <h3>Your location’s MobilityScore®</h3>
                    <div className="mobilityscore">
                        <div id="score">
                            { getBadge() }
                            <div className="brand">MOBILITYSCORE&reg;</div>
                        </div>
                        <div id="score-blurb">
                            <div>Using Actionfigure’s proprietary MobilityScore&reg; algorithm, we have analyzed this location’s transportation connectivity to the surrounding area.</div>
                            <div>{scoreDetails?.scoreDescription} <a href="https://actionfigure.ai/mobilityscore/">Learn more.</a></div>
                            <div className={scoreDetails?.score > 0 ? '' : "hide-section"}>
                                {state?.playlist?.location?.name ? state?.playlist?.location?.name : 'Your location'}'s MobilityScore: <span style={{ fontFamily: "proxima_novabold" }}>{scoreDetails?.score}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`section ${ state?.playlist?.product === 'screen_pro' ? 'disabled-section' : ''}`}>
                    <h3>Modes of transportation</h3>
                    <div className="description">Choose the modes of transportation you would like to show.</div>
                    <div className="allowed-modes">
                        <div>
                            {allowedModes[0]}
                            {allowedModes[1]}
                            {allowedModes[2]}
                        </div>
                        <div>
                            {allowedModes[3]}
                            {allowedModes[4]}
                        </div>
                    </div>
                </div>

                <div className={`section ${ state?.playlist?.product === 'screen_pro' ? 'disabled-section' : ''}`}>
                    <h3>Search area</h3>
                    <div className="description">
                        Based on the modes of transportation you have selected, if you have too many or too few results, you may adjust the search area accordingly.
                    </div>
                    <div className="input-container">
                        <div className="label">Search area:</div>
                        <div className="search-radius-container">
                            <input type="range" min="0.1722" max="1.8278" step="0.4139" list="tickmarks" value={configs?.radius ? configs.radius : "1"} onChange={(e) => handleSliderChange('radius', e.target.value)}></input>
                            <datalist id="tickmarks">
                                {/* The search radius here eventually becomes the radius _multiplier_ in MOB.
                                So if radius=1, that means use the default radius (1208 m). If radius=2, that means
                                double the radius, not search within 1.3 mi. */}
                                <option value="0.1722" label="750 ft"></option>
                                <option value="0.5861" label="0.5 mi"></option>
                                <option value="1" dangerouslySetInnerHTML={{ __html: "0.75 mi <br/>(default)" }}></option>
                                <option value="1.4139" label="1 mi"></option>
                                <option value="1.8278" label="1.3 mi"></option>
                            </datalist>
                        </div>
                    </div>
                </div>

                <div className={`section ${ state?.playlist?.product === 'screen_pro' ? 'disabled-section' : ''}`}>
                    <h3>Zoom level</h3>
                    <div className="description">
                        Every screen or monitor has different sizes and resolutions. Adjust the zoom level to ensure your TransitScreen content shows properly on your display.
                    </div>
                    <div className="input-container">
                        <div className="label">Zoom level:</div>
                        <div className="search-radius-container">
                            <input className="zoom-range" type="range" min="50" max="300" step="1" list="tickmarks" value={configs?.zoom} onChange={(e) => handleSliderChange('zoom', e.target.value)}></input>
                            <datalist id="tickmarks">
                                <option value="50" label="Low"></option>
                                <option value="300" label="High"></option>
                            </datalist>
                        </div>
                    </div>
                </div>

                <div className={`section ${ state?.playlist?.product === 'screen' ? '' : 'disabled-section' }`}>
                    <h3>Scrolling content</h3>
                    <div className="description">
                        If there are more transportation options than can fit on your display at one time, you can enable Scrolling. Scrolling will vertically “roll” each column of transportation content. This feature is not available for Mobile.
                    </div>
                    { getLabelAndSelect('Scrolling', 'SCROLL_SPEED', 'scrollSpeed') }
                </div>


                <div className={`section ${ state?.playlist?.product === 'screen' ? '' : 'disabled-section' }`}>
                    <h3>TransitScreen&reg; "cards"</h3>
                    <div className="dsecription">
                        These are additional controls available only to information cards on the TransitScreen® view. Adjustments to these settings will not impact Nearby, Directory, or other views.
                    </div>
                    <div className="information-cards">
                        <div>
                            { getLabelAndSelect('Time format', 'TIME_FORMAT', 'timeDisplay') }
                            { getLabelAndSelect('Sort order', 'SORT_ORDER', 'sortOrder') }
                        </div>
                        <div>
                            {getLabelAndSwitch('Vehicle color', 'vehicleColor')}
                            {getLabelAndSwitch('Walk time', 'cardWalkTime')}
                            {getLabelAndSwitch('Group rows', 'groupTransitRows')}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TransportationContent;
