import React, { useState, useEffect } from 'react';
import dropdownOptions from './dropdownOptions.json';
import photo from "../../images/photos/nearby.png";
import flaskIcon from "../../images/icons/screens/flask.svg";
import diamondIcon from "../../images/icons/screens/diamond.svg";

const nearbySearchUrl = 'https://places.googleapis.com/v1/places:searchText';
let nearbySearchOptions = {
  method: "POST",
  headers: {
    "Content-Type": "application/json",
    "X-Goog-Api-Key": "AIzaSyA2gZH_aT9G4RUbuhCn-bGPvq3lETqWwQw",
    "X-Goog-FieldMask": "places.displayName,places.formattedAddress,places.id"
  }
}


const NearbyConfiguration = ({state, setState, setStateChanged}) => {
  /*
    Nearby configuration of a playlist is in the nearbyConfigs (jsonb) column.
    Its current format is:
    {
      "radius": 0.34,
      "curated": true,
      "position1": {
        "id":"ChIJcZ7CuLu3t4kREL4wNDegRGg",
        "formattedAddress":"1729 H St NW, Washington, DC 20006, USA",
        "displayName":{
          "text":"Hampton Inn Washington, D.C./White House",
          "languageCode":"en"
        }
      },
      "position2": {"id": ...}
      ...If nothing in position 3, then it's not in the JSON
    }
  */

  const [showNearbyModal, setShowNearbyModal] = useState(false);
  const [keyword, setKeyword] = useState(null);
  const [selectedNearby, setSelectedNearby] = useState(null);
  const [results, setResults] = useState(null);
  const [userInputs, setUserInputs] = useState({});
  // What position is currently being edited
  const [position, setPosition] = useState(null);
  const initRadius = "0.15";


  /* Render radius slider */
  let datalistOptions = [];
  dropdownOptions.NEARBY_RADIUS_OPTIONS.forEach((tick) => {
    datalistOptions.push(<option value={tick.value} label={tick.label} key={tick.label}></option>)
  });

  /* Render radius slider labels */
  let datalistLabels = [];
  dropdownOptions.NEARBY_RADIUS_OPTIONS.forEach((tick) => {
    let labelParts = tick.label.split(" ");

    let imperial = labelParts[0] + " " + labelParts[1];
    let metric = labelParts[2] + " " + labelParts[3];
    // Remove parentheses
    let id = (labelParts[3] + labelParts[2]).replace(/[\.()]/g, '');

    let label = <div key={tick.label} id={id} className="tick-label">{imperial}<br/><span className="label-metric">{metric}</span></div>;
    datalistLabels.push(label);
  });

  const initUserInputs = () => {
    if (state?.playlist?.nearbyConfigs) {
      let nearbyConfigs = state?.playlist?.nearbyConfigs;
      for (const [key, value] of Object.entries(nearbyConfigs)) {
        if (key.startsWith("position")) {
          userInputs[key] = value;
        }
      }
      setUserInputs({...userInputs});
    }
  }

  useEffect(() => {
    initUserInputs();
  }, [state?.playlist?.nearbyConfigs]);

  /* Make the form inputs controlled. */
  const updateUserInputs = (position, value) => {
    userInputs[position] = value;
    if (value === null) {
      delete(userInputs[position]);
    }
    setUserInputs({...userInputs});
  }

  /* Render the input boxes */
  let positions = [
    {"label": "Position 1 (top right):", "key": "position1" },
    {"label": "Position 2 (middle left):", "key": "position2" },
    {"label": "Position 3 (middle right):", "key": "position3" },
    {"label": "Position 4 (bottom left):", "key": "position4" },
    {"label": "Position 5 (bottom right):", "key": "position5" }
  ];
  const nearbyInputBoxes = [];
  positions.forEach((position) => {
    let key = position.key;
    let row = null;
    // This handles case where a Google Place has been selected by the user
    if (userInputs[key] && typeof userInputs[key] === "object") {
      let place = userInputs[key];
      row = <div className="nearby" key={key}>
        <div className="label">{position.label}</div>
        <div className="nearby-readonly-container">
          <div className="nearby-readonly">
            <div>
              {place.displayName.text}
            </div>
            <div className="nearby-readonly__address">
              {place.formattedAddress}
            </div>
          </div>
          <button className="clear-nearby" title="Delete the nearby place" onClick={() => handleInputChange(key, null)}>x</button>
        </div>
      </div>
    } else {
      // This handles user case where user is still typing in the search keyword.
      row = <div className="nearby" key={key}>
        <div className="label">{position.label}</div>
        <div className="input-text">
          <input
            type="text"
            id={key}
            name={key}
            value={userInputs[key] || ''}
            placeholder="Enter a business name or address, then press Enter."
            onKeyDown={(e) =>  {if (e.key === "Enter") { handleSubmit(e); } else { setPosition(position.key); } }}
            onChange={(e) => updateUserInputs(key, e.target.value) }
          />
        </div>
      </div>;
    }
    nearbyInputBoxes.push(row);
  });

  // const handleSubmit = (e) => {
  //   setKeyword(e.target.value);
  //   setResults(searchResults);
  //   setShowNearbyModal(true);
  // }

  const handleSubmit = async (e) => {
    setKeyword(e.target.value);

    // @TODO - Access state.playlist.location here?
    let location = state.playlist.location;
    let radius = state.playlist.nearbyConfigs.radius ? state.playlist.nearbyConfigs.radius : parseFloat(initRadius);
    let tempSearchOptions = {...nearbySearchOptions};
    let body = {
        "textQuery": e.target.value,
        "locationBias": {
            "circle": {
                "center": {
                    "latitude": parseFloat(location.latitude),
                    "longitude": parseFloat(location.longitude)
                },
                "radius": radius * 1000
            }
        }
    };

    tempSearchOptions["body"] = JSON.stringify(body);
    nearbySearchOptions = {...tempSearchOptions};
    const response = await fetch(nearbySearchUrl, nearbySearchOptions)
      .then((response) => response.json())
      .then(data => {
        setResults(data);
        setShowNearbyModal(true);
      });
  }

  const handleInputChange = (property, value) => {
    let tempPlaylist = {...state.playlist};
    if (!tempPlaylist["nearbyConfigs"]) {
      tempPlaylist["nearbyConfigs"] = {"curated": false};
    }

    value = (property === "radius") ? Math.round(value * 100) / 100 : value;

    tempPlaylist["nearbyConfigs"][property] = value;

    if (property.startsWith("position")) {
      updateUserInputs(property, value);
      if (value === null) {
        delete(tempPlaylist["nearbyConfigs"][property]);
      }
    }

    setState({
        ...state,
        playlist: tempPlaylist
    });
    setStateChanged(true);
  }

  return (
    <div className="NearbyConfiguration">
      {showNearbyModal && <NearbyModal keyword={keyword} nearbyPlaces={results} position={position} handleInputChange={handleInputChange} setShowNearbyModal={setShowNearbyModal} /> }
      <div className="photo">
        {/*<img src={photo} style={{visibility: "hidden"}} />*/}
      </div>
      <div className="configuration">
        <h2>Nearby</h2>
        <h3>Dynamic or curated content</h3>
        <div className="description">
          Nearby is capable of selecting up to 5 nearby businesses. You may choose to have these selected dynamically (automated) for you or, if purchased, you may choose specific businesses to feature.
        </div>
        <div className="choices">
          <div className="nearby-option">
            <div className="radio-button">
              <input type="radio" name="curated" onChange={(e) => handleInputChange("curated", false)} checked={ !state?.playlist?.nearbyConfigs || state?.playlist?.nearbyConfigs?.curated === false} />
            </div>
            <div className="option-icon">
              <img src={flaskIcon} id="flask-icon" />
            </div>
            <div className="proxima-bold">Dynamic (default)</div>
          </div>
          <div className="option-description">
            <div className="description">
              Randomly chooses a collection of 5 nearby businesses to display.
            </div>
          </div>

          <div className="nearby-option">
            <div className="radio-button">
              <input type="radio" name="curated" onChange={(e) => handleInputChange("curated", true)} checked={state?.playlist?.nearbyConfigs?.curated === true} />
            </div>
            <div className="option-icon">
              <img src={diamondIcon} id="diamond-icon" />
            </div>
            <div className="proxima-bold">Curated</div>
          </div>
          <div className="option-description">
            <div className="description">
              You may enter a business name or address for any position you would like to curate. If a field is left blank for a particular position a random business will be selected. Tip: Go to <a href="https://www.google.com/maps" target="_blank">Google Maps</a> to search and identify the exact business name or address to input here.
            </div>
            <div className={`nearby-places ${state?.playlist?.nearbyConfigs?.curated ? '': 'disabled-section'}`}>
              { nearbyInputBoxes }
            </div>
          </div>
        </div>

        <h3>Search area</h3>
        <div className="description">
          You may increase or decrease the search area we will use to dynamically select nearby businesses. Decreasing this radius will help ensure we show businesses closer to your location. Increasing the radius will help us present a more diverse range of businesses.
        </div>
        <div className="label">Search area: {state?.playlist?.nearbyConfigs?.radius || initRadius} km</div>
        <div className="search-radius-container">
            <input type="range" min="0.15" max="2.4" step="any" list="tickmarks" value={state?.playlist?.nearbyConfigs?.radius || initRadius } onChange={(e) => handleInputChange('radius', e.target.value)}></input>
            <datalist id="tickmarks">
                {datalistOptions}
            </datalist>
            <div className="range-labels">
              {datalistLabels}
            </div>
        </div>
      </div>
    </div>
  );
}

const NearbyModal = ({keyword, nearbyPlaces, position, handleInputChange, setShowNearbyModal}) => {
  const [highlightNearby, setHighlightNearby] = useState(null);

  const handleSelect = () => {
    handleInputChange(position, highlightNearby);
    setShowNearbyModal(false);
  }

  let options = [];
  nearbyPlaces.places.forEach((place) => {
    options.push(
      <div key={place.id} id={place.id} className={`nearby-option ${place.id === highlightNearby?.id ? 'selected': ''}`} onClick={() => setHighlightNearby(place)}>
        {place.displayName.text} <span className="nearby-option__address">({place.formattedAddress})</span>
      </div>
    );
  })
  return <div className="modal-wrapper">
    <div className="nearby-options-wrapper modal">
      <h3>Nearby places or businesses with "{keyword}"</h3>
      <div className="nearby-options">
        {options}
      </div>
      <div className="bottom-container">
        <button className="button cancel-button" onClick={() => setShowNearbyModal(false)}>Cancel</button>
        <button className={`button ${highlightNearby ? 'modal-button' : 'disabled-button'}`} onClick={() => handleSelect()}>Select</button>
    </div>
    </div>
  </div>;
}


export default NearbyConfiguration;
