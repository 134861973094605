import { bizRequest } from "../../bizClient"; // Import the API helper

const getPlaylistData = async (customerId) => {
    const url = `/api/playlists/getPlaylistsForContentManagement/${customerId}`;
    return await bizRequest(url)
    .then(data => {
        //Filter by base playlists
        var screenContent = data.filter(pl => {
            return pl?.playlist?.type === 'content' && pl?.playlist?.product === 'screen'
        });
        // JS sort sorts the array in place.
        screenContent.sort((a, b) => a.playlist.label.localeCompare(b.playlist.label));

        return screenContent;
    })
    .catch((error) => {
        console.error('Something went wrong:', error);
    });
}

const createOrUpdatePlaylistSlide = async (playlistSlides) => {
    const url = `/api/playlistsSlides/createOrUpdate`;

    return await bizRequest(url, 'POST', { 'Content-Type': 'application/json' }, playlistSlides)
    .then((response) => {
        return response;
    })
    .catch((error) => {
        console.error('Something went wrong:', error);
    });    
}

const removePlaylist = async (playlistId) => {
    const url = `/api/playlists/remove/${playlistId}`

    return await bizRequest(url)
    .then(response => {
        return response
    })
    .catch((error) => {
        console.error('Something went wrong:', error);
        return error
    });
}

const getSlideUploadUrl = async (customerId, slideKey, slideType, fileType) => {
    return await bizRequest(`/api/slides/getSlideUploadUrl/${customerId}/${slideKey}/${slideType}/${fileType}`)
    .then(response => response)
}

const uploadSlideToAws = async (url, file, type) => {
    return await fetch(url, {
        method: 'PUT',
        body: file,
        headers: {
            'Content-Type': type
        }
    })
    .then(response => {
        return response
    })
    .catch(error => {
        console.error(error);
    });
}

const createSlide = async (body) => {
    const url = `/api/slides/createSlide`;

    await bizRequest(url, 'POST', { 'Content-Type': 'application/json' }, body)
    .then((response) => {
        return response;
    })
    .catch((error) => {
        console.error('Something went wrong:', error);
    });
    
    return;
}

const updateSlide = async (id, body) => {
    return await bizRequest(`/api/slides/${id}`, 'PUT', { 'Content-Type': 'application/json' }, body)
    .then((response) => {
        return response;
    })
    .catch((error) => {
        console.error('Something went wrong:', error);
    });
}

const removeSlide = async(id) => {
    // Delete slide from Slides and PlaylistsSlides tables
    return await bizRequest(`/api/slides/remove/${id}`)
    .then((response) => {
        return response;
    })
    .catch((error) => {
        console.error('Something went wrong:', error);
    });
}

const createPlaylist = async (body) => {
    const url = `/api/playlists/createPlaylist`;

    return await bizRequest(url, 'POST', { 'Content-Type': 'application/json' }, body)
    .then(data => data)
    .catch((error) => {
        console.error('Something went wrong:', error);
    });
}

const setLoopDuration = (playlistSlides, slideCount) => {
    let lastPs = {};
    let lastIndex;
    //Find the last playlist slide with content
    for (let i = slideCount - 1; i >= 0; i--) {
        if (playlistSlides[i]?.url && !lastPs?.url) {
            lastPs = playlistSlides[i]
            lastIndex = i;
        }
        //If this playlist slide has duration set to permanent, set it back to default 10sec
        else if (playlistSlides[i]?.durationMs === null) {
            playlistSlides[i].durationMs = 10000
        }
    }
    if (lastIndex) {
        lastPs.durationMs = null;
        playlistSlides[lastIndex] = lastPs;
    }    
    return playlistSlides;
}

export {
    getPlaylistData,
    createOrUpdatePlaylistSlide,
    removePlaylist,
    getSlideUploadUrl,
    uploadSlideToAws,
    createSlide,
    updateSlide,
    createPlaylist,
    setLoopDuration,
    removeSlide
}
