import { v4 as uuidv4 } from 'uuid';
import { bizRequest } from "../../bizClient"; // Import the API helper

const saveCustomer = async (customer, uploadedImage, updatedBy) => {
    const imageKey = uuidv4();
    //Only upload image if there is a new file to upload
    const imageUploadUrl = uploadedImage.file
    ? await bizRequest(`/api/customers/getCustomerImageUploadUrl/${customer.id}/${uploadedImage.type}/${imageKey}`)
        .then(async url => {
            return await fetch(url, {
                method: 'PUT',
                body: uploadedImage.file,
                headers: {
                    'Content-Type': uploadedImage.file.type
                }
            })
            .then(response => {
                if (response.status === 200) {
                    // If the image extensions is 'svg+xml', it will not properly render in Portal. So encode.
                    const imageExtension = uploadedImage.type === 'svg+xml' ? encodeURIComponent('svg+xml') : uploadedImage.type;
                    return `${process.env.REACT_APP_AWS_CUSTOMER_LOGO_URL}/${customer.id}/${imageKey}.${imageExtension}`;
                }
                return null
            })
            .catch(error => {
                console.error(error);
            });
        })
        .catch(e => {
            console.log(e)
        })
    : null;

    const url = `/api/customers/updateCustomer`;

    return await bizRequest(url, 'POST', { 'Content-Type': 'application/json' }, {
        ...customer,
        customerLogo: imageUploadUrl ? imageUploadUrl : customer?.customerLogo,
        updatedBy
    })
    .then(resp => {
        return resp
    })
    .catch((error) => {
        console.error(error);
    });
}

export {
    saveCustomer
}
