import { useState, useEffect } from 'react';
import Switch from "react-switch";
import logo from '../../images/logos/af-logo.svg'
import keyIcon from '../../images/icons/key.svg';
import { constants } from '../../utils';

const ScreenPage = ({
	userData
}) => {

	const [playlistData, setPlaylistData] = useState([]);
	const [placesService, setPlacesService] = useState( new window.google.maps.places.PlacesService(new window.google.maps.Map(document.createElement('div'))));
 	const [status, setStatus] = useState({});
	const [screensPaging, setScreensPaging] = useState(0);
	const [devicePaging, setDevicePaging] = useState(0);
	const [searchAddresses, setSearchAddresses] = useState([]);
	const [pluginParams, setPluginParams] = useState({});
	const [selectedPlaylist, setSelectedPlaylist] = useState({});
	const [savedConfigs, setSavedConfigs] = useState({
		address: '',
		coordinates: '',
		timezone: '',
		shortCode: (Math.random() + 1).toString(36).substring(2,7),
		allowedModes: '',
		lang: 'en',
		ui: 'light',
		isInteractive: false,
		key: '',
		currentActivations: null,
		activationLimit: '',
		activationDate: '',
		deactivationDate: ''
	});
	const [configs, setConfigs] = useState({
		address: '',
		coordinates: '',
		timezone: '',
		shortCode: '',
		allowedModes: '',
		lang: 'en',
		ui: 'light',
		isInteractive: false,
		key: '',
		currentActivations: null,
		activationLimit: '',
		activationDate: '',
		deactivationDate: ''
	});

	useEffect(() => {

		const getPlaylistData = async () => {
			const url = `${process.env.REACT_APP_BIZ_BASE_URL}/api/playlists/getPlaylistByCustomerId/${userData.customerId}`;
			await fetch(url)
			.then(response => response.json())
			.then(data => {
				//Filter only plugin instance types for Screen page
				const filteredData = data.filter(pl => {
					return pl?.playlist?.type === 'base'
				})
				setPlaylistData(filteredData)
				return filteredData
			})
			.catch((error) => {
				console.error('Something went wrong:', error);
			});
		}
		
		getPlaylistData();
		
		return;
	}, []);

	const changeConfigs = (key, value) => {
		const tempConfigs = {...configs};
		key.forEach((k, index) => {
			tempConfigs[k] = value[index];
		})
		setConfigs(tempConfigs)
	}

	const getTimezone = async (lat, long) => {
		const timezone = await fetch(`https://timezones.transitscreen.io/getTimezoneByCoordinates.php?apiKey=${process.env.REACT_APP_TIMEZONE_API_KEY}&lat=${lat}&lon=${long}`)
		.then(response => response.json())
		.then(data => data.tzid);

		changeConfigs(['timezone'], [timezone])
		return timezone;
	}
	
	const generateCitymoUrl= () => {
		let queryParams = "";
		constants.CITYMO_URL_PARAMS.forEach(param => {
			if (configs[param]) {
				queryParams += `${param}=${configs[param]}&`
			}
		})

		//Trim off the last & symbol
		//  queryParams.substring(0, queryParams.length - 1);

		//ADding a default key for now until key starts to live in okta
		queryParams += 'key=kDgUcNxt25pUpxy8ivQ5UYSvXc4dF0acwzLCLu3wMHa0rDZLGDcm3rsZ2LlMjsjs';

		return `${process.env.REACT_APP_CITYMO_URL}/?${queryParams}`;
	}

	const submitInput = async () => {

        let activationTimestamp;
        let deactivationTimestamp;
        if (configs.activationDate !== '') {
            activationTimestamp = new Date(configs.activationDate);
            activationTimestamp.setDate(activationTimestamp.getDate() + 1);
        }

        if (configs.activationDate !== '') {
            deactivationTimestamp = new Date(configs.deactivationDate);
            deactivationTimestamp.setDate(deactivationTimestamp.getDate() + 1);
        }

        const body = {
            // name: isExistingCustomer ? customerName.label : newCustomerName,
			address: configs.address,
            playlistId: selectedPlaylist.playlist ? selectedPlaylist.playlist?.id : null,
            playlistName: selectedPlaylist.playlist ? selectedPlaylist.playlist.name : `${configs.shortCode}-playlist`,
            slideName: selectedPlaylist.slide ? selectedPlaylist.slide.name : `${configs.shortCode}-plugin-slide`,
            customerId: userData.customerId,
            isExistingCustomer: true,
            activationLimit: configs.activationLimit === '' ? null : configs.activationLimit,
            activationDate: activationTimestamp,
            deactivationDate: deactivationTimestamp,
            shortCode: configs.shortCode,
            slideId: selectedPlaylist.slide ? selectedPlaylist.slide.id : null,
            slideUrl: generateCitymoUrl(),
			timezone: configs.timezone === '' ? null : configs.timezone,
			isInteractive: configs.isInteractive,
			pluginUpdatedAt: selectedPlaylist.pluginUpdatedAt
        }

        const url = selectedPlaylist.playlist
        ? `${process.env.REACT_APP_BIZ_BASE_URL}/api/playlists/updatePlaylistWithCustomer`
        : `${process.env.REACT_APP_BIZ_BASE_URL}/api/playlists/createPlaylistWithCustomer`;

        await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        })
        .then((response) => {
			setStatus({
				shortCode: configs.shortCode,
				success: true,
				error: false
			})
            if (!selectedPlaylist) {
				const tempConfigs = {...configs};
				tempConfigs['shortCode'] = (Math.random() + 1).toString(36).substring(2,7);				
				setConfigs(tempConfigs);
				setSavedConfigs(tempConfigs);
            }
            return response;
        })
        .catch((error) => {
            console.error('Something went wrong:', error);
			setStatus({
				shortCode: configs.shortCode,
				success: false,
				error: true
			})
        });
    }	

	const navigatePaging = (value) => {
		setScreensPaging(value)
	};

	const selectPlaylist = async (playlist) => {

		setSelectedPlaylist(playlist);

		const slideUrl = playlist?.slide?.url;
		const searchParams = slideUrl.substring(slideUrl.indexOf('?')+1);
		const pluginParams = JSON.parse('{"' + decodeURI(searchParams).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}');
		setPluginParams(pluginParams);

		const latLong = pluginParams.coordinates.split(',');
		const timezone =  await getTimezone(latLong[0], latLong[1]);

		const playlistConfigs = {
			address: playlist.playlist.address || '',
			coordinates: pluginParams.coordinates  || '',
			timezone: timezone || '',
			shortCode: playlist.playlist.shortCode || '',
			allowedModes: pluginParams.allowedModes || '',
			lang: pluginParams.lang || 'en',
			ui: pluginParams.ui || 'light',
			isInteractive: playlist.playlist.isInteractive || false,
			key: pluginParams.key || '',
			currentActivations: playlist.devices.length || 0,
			activationLimit: playlist.playlist.activationLimit || '',
			activationDate: playlist.playlist.activationDate || '',
			deactivationDate: playlist.playlist.deactivationDate || '',
		}

		setSavedConfigs(playlistConfigs);
		setConfigs(playlistConfigs);
	}

	const undoChanges = () => {
		setConfigs(savedConfigs)
	}

	async function getAddressByLatLong(latLong) {
		const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLong}&key=${process.env.REACT_APP_GMAPS_KEY}`;
		const results = await fetch(url)
		.then(response => response.json())
		return results.results[0].formatted_address || '';
	}

	const createNewScreen = () => {
		setConfigs({
			...configs,
			shortCode: (Math.random() + 1).toString(36).substring(2,7)
		})
	}

	return (
		<div className="ScreenPage">
            <div className="screens-container">
				<h2>Your Actionfigure Screens</h2>
				<ScreensList data={playlistData} screensPaging={screensPaging} navigatePaging={navigatePaging} selectPlaylist={selectPlaylist} selectedPlaylist={selectedPlaylist} createNewScreen={createNewScreen} />
			</div>
			
			<div className="screen-config-container">				
				<div className="column1 pure-u-1-2">
					<h1>Screen Configuration</h1>
					<div>
						<LocationConfig 
							configs={configs} 
							changeConfigs={changeConfigs} 
							searchAddresses={searchAddresses} 
							setSearchAddresses={setSearchAddresses} 
							placesService={placesService} 
							status={status}
							getTimezone={getTimezone}
							getAddressByLatLong={getAddressByLatLong}
						/>
						<ContentConfig shortCode={configs.shortCode} />
						<TransportationConfig configs={configs} changeConfigs={changeConfigs} />
						<DesignConfig configs={configs} changeConfigs={changeConfigs} />
						<InteractivityConfig configs={configs} changeConfigs={changeConfigs} />
					</div>
				</div>
				<div className="column2 pure-u-1-5">
					<div className="access-content">
						<AccessConfig shortCode={configs.shortCode} generateCitymoUrl={generateCitymoUrl} />
						<LicenseConfig configs={configs} changeConfigs={changeConfigs} undoChanges={undoChanges} submitInput={submitInput} status={status} selectedPlaylist={selectedPlaylist} />

					</div>
				</div>
			</div>

			<div className="status-container">
				<h1>Status and analytics</h1>
				<DeviceStatus devices={selectedPlaylist?.devices} devicePaging={devicePaging} setDevicePaging={setDevicePaging} />
			</div>
			
        </div>
	)
}

const ScreensList = ({ data, screensPaging, navigatePaging, selectPlaylist, selectedPlaylist, createNewScreen }) => {

	const maxPages = data.length == 0 
	? 0
	: Math.ceil(data.length / 5)

	const playlistRows = data.length == 0 
	? <></>
	: data.slice(screensPaging * 5, screensPaging * 5 + 5).map((pl, index) => {

		const activations = `${pl.devices.length} of ${pl?.playlist?.activationLimit || "INF"}`;

		//Check that the instance is currently active
		const currentDate = new Date();

		const activationDate = pl.playlist.activationDate
		? new Date(pl.playlist.activationDate)
		: null;

		const deactivationDate = pl.playlist.deactivationDate
		? new Date(pl.playlist.deactivationDate)
		: null;

		const status = activationDate && currentDate < activationDate
		? "Inactive"
		: deactivationDate && currentDate > deactivationDate
		? "Expired"
		: "Active"

		const formattedActivationDate = activationDate
		? `${activationDate.getMonth() + 1}/${activationDate.getDate()}/${activationDate.getFullYear()}`
		: "N/A"

		const formattedDeactivationDate = deactivationDate
		? `${deactivationDate.getMonth() + 1}/${deactivationDate.getDate()}/${deactivationDate.getFullYear()}`
		: "N/A"

		const validDates = formattedActivationDate === 'N/A' && formattedDeactivationDate ==='N/A'
		? "-"
		: `${formattedActivationDate} - ${formattedDeactivationDate}`;
	

		return <tr key={pl?.playlist?.name} className={`playlist-row ${pl === selectedPlaylist ? "selected" : ""}`} onClick={() => selectPlaylist(pl)}>
			<td>{pl?.playlist?.label}</td>
			<td>{pl.playlist.address}</td>
			<td>{activations}</td>
			<td>{pl?.playlist?.shortCode}</td>
			<td className={`${status === 'Inactive' || status ==='Expired' ? "invalid" : ""}`}>{status}</td>
			<td style={{"minWidth" : '100px'}}>{validDates}</td>
		</tr>
	})

	return (
		<div className="ScreenList">
			<table className="screens-table">
				<tbody>
					<tr className="header-row">
						<th className="table-header">Label</th>
						<th className="table-header">Location</th>
						<th className="table-header">Activation</th>
						<th className="table-header">Code</th>
						<th className="table-header">Status</th>
						<th className="table-header">Valid</th>
					</tr>
					{playlistRows}
				</tbody>
			</table>
			<div className="table-nav">
				{
					//Only show paging when there is a need for it
					maxPages <= 1
					? <div className="pagination"></div>
					: <div className="pagination">
						<p className="arrow" onClick={screensPaging !== 0 ? () => navigatePaging(screensPaging - 1) : null}>&laquo;</p>
						<p>{screensPaging + 1} of {maxPages}</p>
						<p className="arrow" onClick={screensPaging < maxPages - 1 ? () => navigatePaging(screensPaging + 1) : null}>&raquo;</p>
					</div>
				}
				<button className="add-screen" onClick={createNewScreen}>+ Create a new Screen</button>
			</div>
		</div>
	)
}

const LocationConfig = ({ configs, changeConfigs, searchAddresses, setSearchAddresses, placesService, status, getTimezone, getAddressByLatLong }) => {

	const [showAddress, setShowAddress] = useState(false);	

	const coordinates = configs.coordinates.split(',');	

	const handleInputChange = async (value) => {
		changeConfigs(['address'], [value]);		

		placesService.textSearch({
			query: value,
			region: 'us'
		}, (results, status) => {
			// https://developers.google.com/maps/documentation/javascript/reference/places-service#PlaceResult
			if (status === window.google.maps.places.PlacesServiceStatus.OK) {
				  const newResults = results.slice(0,5).map((result) => ({
				name: result.name,
				address: result.formatted_address,
				latitude: result.geometry?.location?.lat() || null,
				longitude: result.geometry?.location?.lng() || null,
				googlePlaceId: result.place_id,
			  }));
			  
			  setSearchAddresses(newResults)
			  setShowAddress(true);
			}
		})
	}

	const handleAddressSelect = async (address) => {
		setShowAddress(false);
		changeConfigs(
			['coordinates', 'address', 'timezone'], 
			[
				`${address.latitude.toFixed(6)},${address.longitude.toFixed(6)}`, 
				address.name,
				await getTimezone(address.latitude.toFixed(6), address.longitude.toFixed(6))
			]
		);

	}

	//When losing focus on eitehr coordinates input, do a search
	const onBlurCoordinates = async (lat, long) => {
		if (lat !== '' && long !== '') {
			changeConfigs(['address'],[await getAddressByLatLong(`${lat},${long}`)]);
		}
	}

	return (
		<div className="LocationConfig">
			<h2>Location</h2>
			<div className="content-row">
				<p className="label">Address:</p>
				<input className="radio-button" type="radio" value="address" style={{"visibility": "hidden"}}></input>
				<div className="suggestion-container">
					<input className={`text-input ${status.error ? "error" : ""}`} onChange={(e) => handleInputChange(e.target.value)} value={configs.address}></input>
					{
						//Addresses to select
						showAddress
						? <div className="results-container ">
							{
								searchAddresses.map((address, index) => {
									return <div className="results" key={index}>
										<button 
											className="select-item" 
											onClick={() => handleAddressSelect(address)}
										>
											{address.name}
											<div className="select-item-address">{address.address}</div>
										</button>
									</div>
								})
							}
						</div>
						: <></>
					}
				</div>				
			</div>
			<div className="content-row">
				<p className="label">Coordinates:</p>
				<input className="radio-button" type="radio" value="coordinates" style={{"visibility": "hidden"}}></input>
				<input 
					className={`half-text-input ${status.error ? "error coordinate-input" : "coordinate-input"}`} 
					value={coordinates[0] || ''} 
					onChange={(e) => changeConfigs(['coordinates'], [e.target.value + ',' + `${coordinates[1] ? coordinates[1] : ''}`])}
					onBlur={() => onBlurCoordinates(coordinates[0], coordinates[1])}
				/>
				<p style={{ "paddingLeft": "0.3em", "paddingRight": "0.3em"}}>,</p>
				<input 
					className={`half-text-input ${status.error ? "error coordinate-input" : "coordinate-input"}`} 
					value={coordinates[1] || ''} 
					onChange={(e) => changeConfigs(['coordinates'], [coordinates[0] + ',' + e.target.value])}
					onBlur={() => onBlurCoordinates(coordinates[0], coordinates[1])}
				/>
			</div>
			<div className="content-row">
				<p className="label">Time Zone:</p>
				<input className="radio-button" type="radio" style={{"visibility": "hidden"}}></input>
				<p className="timezone">{configs.timezone}</p>
			</div>
		</div>
	)
}

const ContentConfig = ({ shortCode }) => {

	return (
		<div className="LocationConfig">
			<h2>Content</h2>
			<div className="content-row">
				<p className="label">None / Other:</p>
				<input className="radio-button" type="radio" value="none" disabled></input>
				<input className="text-input" disabled></input>
			</div>
			<div className="content-row">
				<p className="label">Screen Basic:</p>
				<input className="radio-button" type="radio" value="screen-basic" checked readOnly></input>
				<input className="text-input" value={shortCode || ""} disabled></input>
			</div>
			<div className="content-row">
				<p className="label">Screen:</p>
				<input className="radio-button" type="radio" value="screen" disabled></input>
				<input className="text-input" disabled></input>
			</div>
		</div>
	)
}

const TransportationConfig = ({ configs, changeConfigs }) => {

	const allowedModes = configs.allowedModes.split(',');

	const changeAllowedModes = (value) => {
		const allowedModesString = configs.allowedModes;

		//Removing the value
		if (allowedModes.includes(value)) {
			const removeIndex =  allowedModesString.indexOf(value);
			if (removeIndex === 0) {
                //Cut the first character and the following comma
                changeConfigs(['allowedModes'], [allowedModesString.slice(2)]);
            }
            else {
                //Cut the first character and the previous comma
				changeConfigs(['allowedModes'], [allowedModesString.slice(0, removeIndex - 1) + allowedModesString.slice(removeIndex + 1)]);
            }
		}
		//Adding the value
		else {
			changeConfigs(['allowedModes'], allowedModesString.length === 0 ? [value] : [`${allowedModesString},${value}`])
		}
	}

	return (
		<div className="TransportationConfig">
			<h2>Transportation Content</h2>
			<div className="content-row">
				<p className="label">Modes:</p>
				<div className="checkbox-container">
					<div className="checkbox-input">
						<input 
							className="checkbox-button" 
							type="checkbox" 
							name="Rail" 
							value="r" 
							checked={allowedModes.includes('r')}
							onChange={(e) => changeAllowedModes('r')}
						/>
						<label htmlFor="rail">Rail</label>
					</div>
					<div className="checkbox-input">
						<input 
							className="checkbox-button" 
							type="checkbox" 
							name="Bus" 
							value="b" 
							checked={allowedModes.includes('b')} 
							onChange={(e) => changeAllowedModes('b')} 
						/>
						<label htmlFor="bus">Bus</label>
					</div>
					<div className="checkbox-input">
						<input 
							className="checkbox-button" 
							type="checkbox" 
							name="Micro" 
							value="m" 
							checked={allowedModes.includes('m')}
							onChange={(e) => changeAllowedModes('m')}
						/>
						<label htmlFor="micro">Micro</label>
					</div>
					<div className="checkbox-input">
						<input 
							className="checkbox-button" 
							type="checkbox" 
							name="Car" 
							value="c" 
							checked={allowedModes.includes('c')}
							onChange={(e) => changeAllowedModes('c')}
						/>
						<label htmlFor="car">Car</label>
					</div>
					<div className="checkbox-input">
						<input 
							className="checkbox-button" 
							type="checkbox" 
							name="Other" 
							value="o" 
							checked={allowedModes.includes('o')}
							onChange={(e) => changeAllowedModes('o')}
						/>
						<label htmlFor="other">Other (Ferries, Shuttles, etc.)</label>
					</div>		
				</div>				
			</div>
		</div>
	)
}

const DesignConfig = ({ configs, changeConfigs }) => {

	return (
		<div className="DesignConfig">
			<h2>Design and Layout</h2>
			<div className="content-row">
				<p className="label">Language:</p>
				<input className="radio-button" type="radio" value="coordinates" style={{"visibility": "hidden"}}></input>
				<select name="language" className="content-dropdown text-input" value={configs.lang} onChange={(e) => changeConfigs(['lang'], [e.target.value])}>
					<option value="en">English</option>
					<option value="fr">French</option>
					<option value="ge">German</option>
					<option value="it">Italian</option>
				</select>
			</div>
			<div className="content-row">
				<p className="label">Color Mode:</p>
				<input className="radio-button" type="radio" value="coordinates" style={{"visibility": "hidden"}}></input>
				<select name="color-mode" className="content-dropdown text-input" value={configs.ui} onChange={(e) => changeConfigs(['ui'], [e.target.value])}>
					<option value="light">Light</option>
					<option value="dark">Dark</option>
				</select>
			</div>
		</div>
	)
}

const InteractivityConfig = ({ configs, changeConfigs}) => {
	return (
		<div className="InteractivityConfig">
			<h2>Interactivity</h2>
			<div className="content-row">
				<p className="label">Touchscreen:</p>
				<input className="radio-button" type="radio" value="none" style={{"visibility": "hidden"}}></input>
				<Switch 
					className="switch" 
					onColor="#2386EE" 
					onChange={(checked) => changeConfigs(['isInteractive'], [checked])} 
					checked={configs.isInteractive} 
					uncheckedIcon 
					checkedIcon={false}
					height={22}
					width={45}
				/>
			</div>
		</div>
	)
}

const AccessConfig = ({ shortCode, generateCitymoUrl }) => {

	const url = generateCitymoUrl();

	return (
		<div className="AccessConfig">
			<div className="access-header">
				<img src={keyIcon} />
				<h2>Access</h2>
			</div>			
			<hr />
			<div className="content-row">
				<p className="label">Code:</p>
				<input className="radio-button" type="radio" value="none" style={{"visibility": "hidden"}}></input>
				{
					shortCode
					? <>
						<p>{shortCode}</p>
						<p className="copy" onClick={() => navigator.clipboard.writeText(shortCode)}>copy</p>
					</>
					: <></>
				}
			</div>
			<div className="content-row">
				<p className="label">Short URL:</p>
				<input className="radio-button" type="radio" value="none" style={{"visibility": "hidden"}}></input>
				{
					shortCode
					? <>
						<p>{`${process.env.REACT_APP_LOADER_URL}/${shortCode}`}</p>
						<p className="copy" onClick={() => navigator.clipboard.writeText(`${process.env.REACT_APP_LOADER_URL}/${shortCode}`)}>copy</p>
					</>
					: <></>
				}
			</div>
			<div className="content-row">
				<p className="label">Full URL:</p>
				<input className="radio-button" type="radio" value="none" style={{"visibility": "hidden"}}></input>
				{
					shortCode
					? <>
						<p>{`${url.substring(0,37)}[...]`}</p>
						<p className="copy" onClick={() => navigator.clipboard.writeText(url)}>copy</p>
					</>
					: <></>
				}
			</div>
		</div>
	)
}

const LicenseConfig = ({ configs, changeConfigs, undoChanges, submitInput, status, selectedPlaylist }) => {

	const [selectActivation, setSelectActivation] = useState(null);

	const currentDate = new Date();
	const currentMonth = currentDate.getMonth() + 1 < 10 ? `0${currentDate.getMonth() + 1}` : currentDate.getMonth() + 1;
	const currentDay = currentDate.getDate() < 10 ? `0${currentDate.getDate()}` : currentDate.getDate();
	const formattedCurrentDate = `${currentDate.getFullYear()}-${currentMonth}-${currentDay}`;

	const activationDate = configs.activationDate ? new Date(configs.activationDate) : null;
	const deactivationDate = configs.deactivationDate ? new Date(configs.deactivationDate) : null;
	
	const activationMonth = configs.activationDate
	? activationDate.getMonth() + 1 < 10 ? `0${activationDate.getMonth() + 1}` : activationDate.getMonth() + 1
	: null;

	const activationDay = configs.activationDate
	? activationDate.getDate() < 10 ? `0${activationDate.getDate()}` : activationDate.getDate()
	: null;
	
	const deactivationMonth = configs.deactivationDate
	? deactivationDate.getMonth() + 1 < 10 ? `0${deactivationDate.getMonth() + 1}` : deactivationDate.getMonth() + 1
	: null;

	const deactivationDay = configs.deactivationDate
	? deactivationDate.getDate() < 10 ? `0${deactivationDate.getDate()}` : deactivationDate.getDate()
	: null;

	const formattedActivationDate = activationDate
		? `${activationDate.getFullYear()}-${activationMonth}-${activationDay}`
		: ''
		
	const formattedDeactivationDate = deactivationDate
		? `${deactivationDate.getFullYear()}-${deactivationMonth}-${deactivationDay}`
		: ''

	return (
		<div className="LicenseConfig">
			<h2>License</h2>
			<hr />
			<div className="content-row">
				<p className="label">Current Activations:</p>
				<input className="radio-button" type="radio" style={{"visibility": "hidden"}}></input>
				<p>{configs.currentActivations}</p>
			</div>
			<div className="content-row">
				<p className="label">Maximum Activations:</p>
				<input className="radio-button" type="radio" value="max-activation" checked={configs.activationLimit !== ''} onChange={() => changeConfigs(['activationLimit'], [0])}></input>
				<input value={configs.activationLimit} onChange={(e) => {changeConfigs(['activationLimit'], [e.target.value])}}></input>
			</div>
			<div className="content-row">
				<p className="label" style={{"visibility": "hidden"}}></p>
				<input className="radio-button" type="radio" value="unlimited" checked={configs.activationLimit === ''} onChange={() => {changeConfigs(['activationLimit'], [''])}}></input>
				<p>Unlimited</p>
			</div>
			<div className="content-row">
				<p className="label">Activation Date:</p>
				<input className="radio-button" type="radio" value="none" style={{"visibility": "hidden"}}></input>
				<input type="date" value={formattedActivationDate} onChange={(e) => {changeConfigs(['activationDate'], [e.target.value])}}></input>
			</div>
			<div className="content-row">
				<p className="label">Deactivation Date:</p>
				<input className="radio-button" type="radio" value="deactivation-date" checked={configs.deactivationDate} onChange={() => {changeConfigs(['deactivationDate'], [formattedCurrentDate])}}></input>
				<input type="date" value={formattedDeactivationDate} onChange={(e) => {changeConfigs(['deactivationDate'], [e.target.value])}}></input>
			</div>
			<div className="content-row">
				<p className="label" style={{"visibility": "hidden"}}></p>
				<input className="radio-button" type="radio" value="none" checked={!configs.deactivationDate} onChange={() => changeConfigs(['deactivationDate'], [''])}></input>
				<p>Never Expires</p>
			</div>
			<div  className="buttons-container">
				<div className="content-row">
					<button className="disabled-button">Send to activation code to customer</button>
				</div>
				<div className="content-row">
					<button className="secondary-button">Deactivate screen</button>
				</div>
				<div className="content-row" style={{"marginTop": "7em"}}>
					<button className="secondary-button" onClick={undoChanges}>Undo Changes</button>
				</div>
				<div className="content-row">
					<button className="primary-button" onClick={() => submitInput()}>Save Screen</button>					
				</div>
				{
					status.success && selectedPlaylist.playlist
					? <p className="success">Screen updated successfully with {status.shortCode}</p>
					: status.success
					? <p className="success">Screen created successfully with {status.shortCode}</p>
					: <></>
				}
			</div>			
		</div>
	)
}

const DeviceStatus = ({ devices, devicePaging, setDevicePaging }) => {

	const maxPages = !devices
	? 0
	: devices.length == 0 
	? 0
	: Math.ceil(devices.length / 5)

	const devicesRows = !devices
	? <></>
	: devices.slice(devicePaging * 5, devicePaging * 5 + 5).map(device => {
		return <tr key={device.id} className="device-row">
			<td>{device.deviceId}</td>
			<td>{device.deviceInformation.deviceModel}</td>
			<td>{device.deviceInformation.os}</td>
			<td></td>
			<td>View</td>
		</tr>
	})

	return (
		<div className="DeviceStatus">
			<table className="status-table">
				<tbody>
					<tr className="header-row">
						<th className="table-header">Player ID</th>
						<th className="table-header">Player</th>
						<th className="table-header">OS</th>
						<th className="table-header">Last Refresh</th>
						<th className="table-header">Preview</th>
					</tr>
					{devicesRows}
				</tbody>
			</table>
			{
				//Only show paging when there is a need for it
				maxPages <= 1
				? <></>
				: <div className="pagination">
					<p className="arrow" onClick={devicePaging !== 0 ? () => setDevicePaging(devicePaging - 1) : null}>&laquo;</p>
					<p>{devicePaging + 1} of {maxPages}</p>
					<p className="arrow" onClick={devicePaging < maxPages - 1 ? () => setDevicePaging(devicePaging + 1) : null}>&raquo;</p>
				</div>
			}
		</div>
	)
}

export default ScreenPage
