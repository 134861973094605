import { bizRequest } from "../../bizClient"; // Import the API helper

const getLocations = async (customerId) => {
    return await bizRequest(`/api/locations/getLocationsByCustomerId/${customerId}`)
    .then(data => {
        return data
    })
    .catch((error) => error);
}

const saveLocation = async (location) => {
    //If location already exists, update instead of create
    const url = `/api/locations/saveLocation`;
    
    return await bizRequest(url, 'POST', { 'Content-Type': 'application/json' }, { location })
    .then(resp => {
        return resp
    })
    .catch((error) => {
        console.error(error);
    });
}

const formatLocationAddress = (location) => {
    if (!location) return "";

    const { address1, city, state, zip, country } = location;

    //Build the parts while ensuring correct formatting
    const addressParts = [];

    if (address1) addressParts.push(address1);
    if (city) addressParts.push(city);

    //Correctly format "State Zip"
    if (state && zip) {
        addressParts.push(`${state} ${zip}`);
    } else if (state) {
        addressParts.push(state);
    } else if (zip) {
        addressParts.push(zip);
    }

    if (country) addressParts.push(country);

    // Join with ", " for correct address formatting
    return addressParts.join(", ");
}

export {
    getLocations,
    saveLocation,
    formatLocationAddress,
}
