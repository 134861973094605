import { useState, useRef } from 'react';
import Papa from 'papaparse';
import { Circles } from 'react-loader-spinner';
import csvTemplate from './af_template.csv';

import { findGmapsCounty, getCsvData, geocodeBySearchString } from './controller';

const EmployeesAddress = ({
    changeState,
    employeeAddresses,
    ctaButtonClicked,
    searchCountry,
    uploadedFile
}) => {

    // const [uploadedFile, setUploadedFile] = useState(null);
    const [showLoading, setShowLoading] = useState(false);
    const [loadingText, setLoadingText] = useState("");
    const [invalidAddresses, setInvalidAddresses] = useState(null);
    const [currentAddressError, setCurrentAddressError] = useState(false);
    const [fileError, setFileError] = useState(false);
    const [uploadError, setUploadError] = useState(false);

    const hiddenFileInput = useRef(null) 

    const showError = ctaButtonClicked && ( !employeeAddresses || !uploadedFile );

    const handleClick = ( event ) => {
        if (!searchCountry) {
            setCurrentAddressError(true);
            return;
        }
        setCurrentAddressError(false);
        hiddenFileInput.current.click();
    };    
      
    const handleChange = async ( event ) => {
        const file = event.target.files[0];

        if (file.type === 'text/csv') {
            // Read the file and process the
            Papa.parse(file, {
                header: true,
                skipEmptyLines: true,
                beforeFirstChunk: function (chunk) {
                    let rows = chunk.split( /\r\n|\r|\n/ );
                    //Switching column headers to agreed parameter names
                    rows[0] = 'latitude,longitude,address,city,state,zip,personId';
                    return rows.join("\r\n");
                },
                complete: async function (results) {
                        const data = results.data.filter(n => {
                            return n.latitude !== "" || n.address !== "" || n.zip !== ""
                        })                  
                        setShowLoading(true);
                        //Wait for all google addresses to be completed and update state
                        Promise.all(await getCsvData(data, searchCountry, setLoadingText)).then(rows => {
                            const flatData = rows.flat().filter(n => n);
                            if (flatData.length !== rows.length) {
                                setUploadError(true);
                                // setUploadedFile(false);
                                changeState(['uploadedFile'], [null]);
                            }
                            else {
                                const errorAddresses = flatData.filter(address => address.error)

                                if (errorAddresses.length > 0) {
                                    setInvalidAddresses(errorAddresses)
                                }
                                changeState(['uploadedFile', 'employeeAddresses'], [file,flatData]);
                            }                            
                            setShowLoading(false);
                        })                                  
                }
            });
            setFileError(false);
        }
        else {
            setFileError(true);
            // setUploadedFile(null);
            changeState(['uploadedFile'], [null]);
        }      
    };

    const cancelUpload = () => {
        setInvalidAddresses(null);
        // setUploadedFile(null);
        changeState(['uploadedFile'], [null]);
        changeState(['employeeAddresses'], [null]);
    }
    
    return (
        <div className="EmployeesAddress">
            {
				showLoading
				? <div className="modal">
                    <h3>Validating addresses…</h3>
                     <Circles
                        height="80"
                        width="80"
                        color="#2386EE"
                        ariaLabel="circles-loading"
                        visible={true}
                    />
                    <p>{loadingText}</p>
                </div>
				: <></>
			}
            {
                invalidAddresses
                ? <InvalidAddressModal 
                    employeeAddresses={employeeAddresses}
                    changeState={changeState}
                    addresses={invalidAddresses} 
                    cancelUpload={cancelUpload} 
                    findGmapsCounty={findGmapsCounty}
                    setInvalidAddresses={setInvalidAddresses}
                    searchCountry={searchCountry}
                />
                : <></>
            }
            <h3>Step 4: <span style={{color:"#000"}}>Upload a list of your employee’s locations</span></h3>
            <div className="container">
                <UploadSetting 
                    handleClick={handleClick} 
                    handleChange={handleChange}
                    hiddenFileInput={hiddenFileInput}
                    uploadedFile={uploadedFile}
                    fileError={fileError}
                    showError={showError}
                    uploadError={uploadError}
                    currentAddressError={currentAddressError}
                />             
            </div>
        </div>
    );
}

const UploadSetting = ({ handleClick, handleChange, hiddenFileInput, uploadedFile, fileError, showError, uploadError, currentAddressError }) => {
    return <div className="employees-container">
        <div className="content-container">
            <p className="text">First, download our simple template and fill out the information for your employees. Once complete, return to this page and upload the file. Only CSV files may be uploaded. You may use ZIP/Post Codes, Geocoordinates, or fully formatted addresses. Addresses or geocoordinates will provide the most accurate results.</p>
            <div className="upload-container">
                <div className="buttons-container">
                    <a className={`button`}  href={csvTemplate} download={"af_template"} target='_blank'>
                        Download template
                    </a>
                    <div className={`button upload`} onClick={() => handleClick()}>
                        Upload CSV file
                    </div>
                    <input
                        type="file"
                        onChange={handleChange}
                        ref={hiddenFileInput}
                        style={{display: 'none'}} // Make the file input element invisible
                    />
                </div>
                <div className="upload-info">
                    {
                        uploadedFile
                        ? <p style={{color:'green'}}>File: {uploadedFile?.name}</p>    
                        :<></>
                    }
                    {
                        currentAddressError
                        ? <p style={{color:'red'}}>Please input a current location on step 2 first</p>    
                        : <></>
                    }
                    {
                        fileError
                        ? <p style={{color:'red'}}>Please input a correct file type</p>    
                        : <></>
                    }
                    { 
                        uploadError
                        ? <p style={{color:'red'}}>CSV Upload failed. Please try again later</p>    
                        :<></>
                    }
                    {
                        showError
                        ? <p className="error-message">Please provide a file</p>
                        : <></>
                    }
                </div>   
            </div>         
        </div>
    </div>
}

const InvalidAddressModal = ({employeeAddresses, changeState, addresses, cancelUpload, findGmapsCounty, setInvalidAddresses, searchCountry}) => {

    const [addressFixNeeded, setAddressFixNeeded] = useState(false);

    const submitValidAddresses = () => {
        const errorAddresses = employeeAddresses.filter(address => address.error)
        if (errorAddresses.length > 0) {
            setAddressFixNeeded(true)
        }
        else {
            setInvalidAddresses(null)
        }        
    }

    const addressRows = addresses.map((address, index) => {
        return <tr key={index} className="table-row">
            <td>{address?.row}</td>
			<td className="address">{address?.address || address?.zip}</td>
            <InputRow invalidAddress={address} findGmapsCounty={findGmapsCounty} employeeAddresses={employeeAddresses} changeState={changeState} searchCountry={searchCountry} />
		</tr> 
    })

    return (
        <div className="modal">
            <h3>Invalid addresses found</h3>
            <div className="table-container">
                <table>
                    <tbody>
                        <tr className="header-row">
                            <th className="table-header">Row number</th>
                            <th className="table-header">Invalid address</th>
                            <th className="table-header">New address</th>
                        </tr>
                        {addressRows}
                    </tbody>
                </table>
            </div>
            <div className="button-container">
                <button className="modal-button" onClick={cancelUpload}>Cancel</button>
                <button className="modal-button" onClick={submitValidAddresses}>Submit</button>
            </div>
            {
                addressFixNeeded
                ? <p style={{"color": "#FD4A5C"}}>Please find a valid address for each row</p>
                : <></>
            }
        </div>
    )
}

const InputRow = ({ invalidAddress, findGmapsCounty, employeeAddresses, changeState, searchCountry }) => {
    const [searchString, setSearchString] = useState('');
    const [address, setAddress] = useState({})
    const [results, setResults] = useState([]);
    const [searchTimeout, setSearchTimeout] = useState(null);

    const onChangeInput = (value) => {
        setSearchString(value);
        clearTimeout(searchTimeout); 

        if (!value) return null; 
        try {
            setSearchTimeout(setTimeout(async() => {
                await geocodeBySearchString(value, invalidAddress, searchCountry, setResults);
            }, 1000))
            
        } catch (e) {
            console.error(e);
        }
    }

    const onClickResult = (result) => {
        //Replace address
        let tempEmployeeAddresses = [...employeeAddresses];
        const replaceAddress = tempEmployeeAddresses.find(address => address.personId === result.personId);
        const replaceIndex = tempEmployeeAddresses.indexOf(replaceAddress)
        tempEmployeeAddresses[replaceIndex] = result;
        changeState(['employeeAddresses'], [tempEmployeeAddresses]);

        setSearchString(result.address);
        setResults([]);
        setAddress(result);
    }

     function Results({ results }) {
        if (!results) return null;
        return results.map((result, i) => {
            if (!result) return null;
            return (
                <div className="results" key={i}>
                    <button className="select-item" onClick={() => onClickResult(result)}>
                        {result.name}
                        <div className="select-item-address">{result.address}</div>
                    </button>
                </div>
            )
        });
    }    

    return <td className="input-cell">
        <input onChange={(e) => onChangeInput(e.target.value)} value={searchString}></input>
        {
            results
            ? <div className="results-container"><Results results={results}/></div>
            : <></>
        }
    </td>
}

export default EmployeesAddress;
