import React, {
    useState,
    useEffect
} from 'react';
import Select from 'react-select';
import AddressSearch from './AddressSearch';
import { getTimezone, getContentPlaylists } from './utils';
import pauseIcon from '../../images/icons/screens/pause.svg';
import transitBlackIcon from '../../images/icons/screens/transit-black.svg';
import playlistIcon from '../../images/icons/screens/playlist.svg';
import globeIcon from '../../images/icons/screens/globe.svg';
import landscapeIcon from '../../images/icons/screens/landscape.svg';
import portraitLeftIcon from '../../images/icons/screens/portrait-left.svg';
import portraitRightIcon from '../../images/icons/screens/portrait-right.svg';

import MapWrapper from './MapWrapper';

const ScreenConfiguration = ({
    state,
    setState,
    handleInputChange,
    handleMultiInputChanges,
    customerId,
    selectedProduct,
    customer
}) => {

    const LANGUAGE_OPTIONS = [
        {
            value: 'en',
            label: 'English'
        },        
        {
            value: 'fr',
            label: 'French'
        },
        {
            value: 'fr_ca',
            label: 'French Canadian'
        },
        {
            value: 'ge',
            label: 'German'
        },
        {
            value: 'pt',
            label: 'Portuguese'
        },
    ]

    const playlist = {...state?.playlist};

    const [contentPlaylists, setContentPlaylists] = useState([]);

    useEffect(() => {
        getContentPlaylists(customerId)
        .then(data => {
            setContentPlaylists(data)
        })
    }, []);

    const handleAddressSelect = async (address) => {
        await getTimezone( address.latitude, address.longitude)
        .then(timezone => {
            playlist.latitude = address.latitude;
            playlist.longitude = address.longitude;
            playlist.address = address.address;
            playlist.timezone = timezone;
            setState({
                ...state,
                playlist: playlist
            });
        });
    }

    const handleRadioSelect = (attribute, radioValue) => {
        if (attribute === 'screenType') {
            //Transportation radio button clicked
            if (radioValue === 'transportation' || radioValue === 'standby') {
                handleMultiInputChanges(['screenType', 'contentShortCode', 'otherSlideUrl'], [radioValue, null, null]);
            }
            else if (radioValue === 'contentPlaylist') {
                handleMultiInputChanges(['screenType', 'otherSlideUrl'], [radioValue, null]);
            }
            else if (radioValue === 'other') {
                handleMultiInputChanges(['screenType', 'contentShortCode'], [radioValue, null]);
            }
        } else if (attribute === 'messagingShortCode') {
            handleMultiInputChanges(['messagingShortCode'], [radioValue]);
        }
    }

    const inScreenMessagingEnabled = () => {
        var enabled = false;
        if (customer.enabledDash2Navs.includes("playlists") &&
            customer.enabledDash2Navs.includes("messaging") &&
            selectedProduct === 'screen_pro') {
            enabled = true;
        }

        return enabled;
    }

    return (
        <div className="ScreenConfigurationPage">
            <div className="ScreenConfiguration">
                <div className="left-col pure-u-1-2">
                    <h2>Screen configuration</h2>
                    <div className="input-container">
                        <p className="bold pure-u-1-5">Activation code:</p>
                        <p className="bold text">{playlist.shortCode}</p>
                    </div>
                    <div className="input-container">
                        <p className="pure-u-1-5">Screen name:</p>
                        <input type="text" className="input-field" value={playlist.label || ''} onChange={(e) => handleInputChange('label', e.target.value)} disabled={!state.playlist?.customerId} />
                    </div>
                    <h3>Location</h3>
                    {
                        selectedProduct === 'screen_pro'
                        ? <p>This is the installation or assigned location for your Screen Pro. Your Screen Pro will use this, coupled with your other curated content, to determine what to show for transportation, weather, nearby amenities, and more.</p>
                        : <p>Search by address or click and drag the map pin to an exact location. Your Screen will use this to automatically determine what to show for transportation, weather, nearby amenities, and more.</p>
                    }
                    <div className="input-container">
                        <p className="pure-u-1-5">Address:</p>
                        <AddressSearch 
                            handleAddressChanges={handleInputChange} 
                            handleResultSelect={handleAddressSelect}
                            value={ playlist.address || '' }
                            propName='address'
                            disabled={selectedProduct === 'screen_pro' || !state.playlist?.customerId}
                        />
                    </div>
                    <div className="input-container">
                        <p className="pure-u-1-5">Coordinates:</p>
                        <p className="text">{playlist.latitude ? `${(playlist.latitude * 1).toFixed(6)}, ${(playlist.longitude * 1).toFixed(6)}` : ''}</p>
                    </div>
                    <div className="input-container">
                        <p className="pure-u-1-5">Time zone:</p>
                        <p className="text">{ playlist.timezone ?? ''}</p>
                    </div>
                    <div className="input-container">
                        <p className="pure-u-1-5">Language:</p>
                        <Select
                            onChange={(option) => handleInputChange('language', option)}
                            options={LANGUAGE_OPTIONS}
                            value={ LANGUAGE_OPTIONS.find(lang => lang.value === playlist?.language) }
                            className={"input-field"}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            isDisabled={selectedProduct === 'screen_pro' || !state.playlist?.customerId}
                        />
                    </div>
                    <h3 style={{visibility: "hidden"}}>Display</h3>
                    <p style={{visibility: "hidden"}}>Adjust these settings to ensure your Screen looks its best on nearly any display. Be sure to save your changes and then check your Screen in person to ensure things look right!</p>
                    {/* <div className="input-container">
                        <p className="pure-u-1-5">Resolution:</p>
                        <input type="range" className="input-field" min="0" max="4" step="1" />
                    </div> */}
                </div>
                <div className="pure-u-1-2" id="map-container">
                    <MapWrapper state={state} setState={setState} />

                </div>
            </div>
            <div className="ScreenTypeConfiguration">
                <h2 className="proxima-bold">Content management</h2>
                <div className="content-management-settings">
                    <div className="cm-setting">
                        <h3>What's playing on this Screen</h3>
                        <p>Specify what content you would like this Screen to be showing. All Screens default to Standby mode.</p>
                        <div className="screen-type-container pure-g">
                            <div className="pure-u-1-12">
                                <input type="radio" checked={playlist.screenType === 'standby'} onChange={() => handleRadioSelect('screenType', 'standby')} disabled={!state.playlist?.customerId} />
                            </div>
                            <div className="pure-u-1-12 type-icon"><img src={pauseIcon} /></div>
                            <div className="pure-u-5-6 radio-label">Standby</div>
                        </div>
                        <div className="screen-type-container pure-g">
                            <div className="pure-u-1-12">
                                <input type="radio" checked={playlist.screenType === 'transportation'} onChange={() => handleRadioSelect('screenType', 'transportation')} disabled={!state.playlist?.customerId} />
                            </div>
                            <div  className="pure-u-1-12 type-icon"><img src={transitBlackIcon} /></div>
                            <div className="pure-u-5-6 radio-label">Transportation</div>
                        </div>
                        <div className={`screen-type-container pure-g ${!customer.enabledDash2Navs.includes("playlists") ? "disabled-label" : ''}` }>
                            <div className="pure-u-1-12">
                                <input type="radio" checked={playlist.screenType === 'contentPlaylist'} onChange={() => handleRadioSelect('screenType', 'contentPlaylist')} disabled={!state.playlist?.customerId || !customer.enabledDash2Navs.includes("playlists")} />
                            </div>
                            <div  className="pure-u-1-12 type-icon"><img className={!customer.enabledDash2Navs.includes("playlists") ? 'disabled': ''} src={playlistIcon} /></div>
                            <div className="pure-u-1-4 radio-label">Playlist:</div>
                            <Select
                                onChange={(option) => handleMultiInputChanges(['screenType', 'contentShortCode', 'otherSlideUrl'], ['contentPlaylist', option.value, null])}
                                options={contentPlaylists.map(pl => {
                                    return {
                                        label: pl.label,
                                        value: pl.shortCode
                                    }
                                })}
                                className="playlist-dropdown pure-u-7-12"
                                value={contentPlaylists.find(pl => pl.shortCode === playlist.contentShortCode)}
                                styles={{ control: base => ({ ...base, zIndex: 9999 }) }}
                                menuShouldScrollIntoView={false}
                                isDisabled={!state.playlist?.customerId || !customer.enabledDash2Navs.includes("playlists")}
                            />
                        </div>
                        <div className="screen-type-container pure-g">
                            <div className="pure-u-1-12">
                                <input type="radio" checked={playlist.screenType === 'other'} onChange={() => handleRadioSelect('screenType', 'other')} disabled={!state.playlist?.customerId} />
                            </div>
                            <div  className="type-icon pure-u-1-12"><img src={globeIcon} /></div>
                            <div className="radio-label pure-u-1-4">Website</div>
                            <div className="pure-u-7-12">
                                <input
                                    type="text"
                                    style={{background: '#fff'}}
                                    value={playlist?.otherSlideUrl || ''}
                                    onChange={(e) => handleMultiInputChanges(['screenType', 'otherSlideUrl', 'contentShortCode'], ['other', e.target.value, null])}
                                    className="input-field"
                                    placeholder='https://...'
                                    disabled={!state.playlist?.customerId}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="cm-setting">
                        <h3>In-Screen Messaging (Screen Pro only)</h3>
                        <p>For Screen Pro customers with In-Screen Messaging, please specify what playlist should be shown on this Screen.</p>
                        <div className="screen-type-container pure-g">
                            <div className="pure-u-1-12">
                                <input type="radio" checked={playlist.messagingShortCode === null} onChange={() => handleRadioSelect('messagingShortCode', null)} disabled={!inScreenMessagingEnabled()} />
                            </div>
                            <div className="pure-u-1-12 type-icon"><img className={!inScreenMessagingEnabled() ? "disabled" : ''} src={pauseIcon} /></div>
                            <div className={`pure-u-5-6 radio-label ${!inScreenMessagingEnabled() ? "disabled-label": ''}`}>Standby</div>
                        </div>
                        <div className="screen-type-container pure-g">
                            <div className="pure-u-1-12">
                                <input type="radio" checked={playlist.messagingShortCode !== null && selectedProduct === 'screen_pro'} onChange={() => handleRadioSelect('messagingShortCode', '')} disabled={!inScreenMessagingEnabled() ? "disabled" : ''} />
                            </div>
                            <div  className="pure-u-1-12 type-icon"><img className={!inScreenMessagingEnabled() ? "disabled" : ''} src={playlistIcon} /></div>
                            <div className={`pure-u-1-4 radio-label ${!inScreenMessagingEnabled() ? "disabled-label": ''}`}>Playlist:</div>
                            <Select
                                onChange={(option) => handleMultiInputChanges(['messagingShortCode'], [option.value])}
                                options={contentPlaylists.map(pl => {
                                    return {
                                        label: pl.label,
                                        value: pl.shortCode
                                    }
                                })}
                                className="playlist-dropdown pure-u-7-12"
                                value={contentPlaylists.find(pl => pl.shortCode === playlist.messagingShortCode)}
                                styles={{ control: base => ({ ...base, zIndex: 9999 }) }}
                                menuShouldScrollIntoView={false}
                                isDisabled={!inScreenMessagingEnabled()}
                            />
                        </div>
                    </div>
                    <div className="cm-setting">
                        <h3>Screen orientation</h3>
                        <p>Specify which direction your Screen is mounted. For the majority of users, this will be Landscape.</p>
                        <div className="screen-type-container pure-g">
                            <div className="pure-u-1-12">
                                <input type="radio" checked={playlist.rotation === '0'} onChange={() => handleInputChange('rotation', '0')} disabled={!state.playlist?.customerId} />
                            </div>
                            <div className="type-icon pure-u-1-12"><img src={landscapeIcon} /></div>
                            <div className="pure-u-5-6 radio-label">Landscape (default)</div>
                        </div>
                        <div className="screen-type-container pure-g">
                            <div className="pure-u-1-12">
                                <input type="radio" checked={playlist.rotation === '90'} onChange={() => handleInputChange('rotation', '90')} disabled={!state.playlist?.customerId} />
                            </div>
                            <div className="type-icon pure-u-1-12"><img src={portraitLeftIcon} /></div>
                            <div className="pure-u-5-6 radio-label">Portrait - bottom on left</div>
                        </div>
                        <div className="screen-type-container pure-g">
                            <div className="pure-u-1-12">
                                <input type="radio" checked={playlist.rotation === '270'} onChange={() => handleInputChange('rotation', '270')} disabled={!state.playlist?.customerId} />
                            </div>
                            <div className="type-icon pure-u-1-12"><img src={portraitRightIcon} /></div>
                            <div className="pure-u-5-6 radio-label">Portrait - bottom on right</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ScreenConfiguration;
