import { ReactSVG } from 'react-svg';

import logo from '../../images/logos/af-logo.svg';
import question from '../../images/icons/home/question.svg';
import uptime from '../../images/icons/home/uptime.svg';
import productSuite from '../../images/product_suite.png';

const HomeLanding = ({
	userData,
	customer
}) => {
	const customerHasFeature = (feature) => {
		return customer?.enabledDash2Navs.includes(feature) ? true : false;
	}

	return (
		<div className="HomeLanding">
			<h2 className="welcome">Welcome to the new Actionfigure Portal</h2>
			<div className="pure-g card-container">
				<div className="pure-u-1-2 flex-card">
					<div className="pure-g homepage-card left-col">
						<div className="pure-u-1-5 question-svg"><ReactSVG src={question} /></div>
						<div className="pure-u-4-5">
							<h3>Check out our growing Support Center</h3>
							<p className="blurb">Have questions about new features, your content player, or anything in between? Take a look at our new Support Center in the left menu bar. If you don’t find the answer to your question, simply reach out to our amazing Customer Success team! </p>
						</div>
					</div>
				</div>
				<div className="pure-u-1-2">
					<div className="pure-g homepage-card right-col">
						<div className="pure-u-1-5 uptime-svg"><ReactSVG src={uptime} /></div>
						<div className="pure-u-4-5">
							<h3>We're obsessive about uptime</h3>
							<p className="blurb">We work relentlessly to make sure your Actionfigure solutions are always up and running at the top of their game, 24/7. In fact, our up-time performance is over 99.9%! If you are curious though, or think there may be an issue, simply <a href="https://status.actionfigure.ai/" target="_blank">click here to check out our system status</a>.</p>
						</div>
					</div>
				</div>
			</div>
			<h2 className="welcome">Explore our Solutions</h2>
			<div><img src={productSuite} alt="Actionfigure product suite includes Foresight, Insight, Mobile, Screen" className="product-suite" /></div>

			<div className="product-links">
				<div className="button-container">
					{
						customerHasFeature('foresight') ?
						<div className="button-cta highlight-button">
							<a href="/foresight">Generate a report</a>
						</div> :
						<div className="button-cta">
							<a href="https://actionfigure.ai/foresight/" alt="Link to Foresight" target="_blank">Learn more</a>
						</div>
					}
				</div>
				<div className="button-container">
					<div className="button-cta">
						<a href="https://actionfigure.ai/insight/" alt="Link to Insight" target="_blank">Learn more</a>
					</div>
				</div>

				<div className="button-container">
					<div className="button-cta">
						<a href="https://actionfigure.ai/mobile/" alt="Link to Mobile" target="_blank">Learn more</a>
					</div>
				</div>

				<div className="button-container">
				{
					customerHasFeature('screen') ?
					<div className="button-cta highlight-button">
						<a href="/screen">Manage screens</a>
					</div> :
					<div className="button-cta">
						<a href="https://actionfigure.ai/screen/" alt="Link to Actionfigure Screen" target="_blank">Learn more</a>
					</div>
				}
				</div>
			</div>
        </div>
	)
}

export default HomeLanding
