import React, {
} from 'react';

const ScreenProTransportationContent = ({
}) => {

    return (
        <div className="ScreenProTransportationContent"> 
            <div className="screen-pro-transportation-box">
                <div style={{padding: "1em"}}>
                    <h3>Transportation</h3>
                    <div>
                        {/* <div className="mobility-score-container">
                            <img />
                            <div>
                                <p>MobilityScore</p>
                                <p>This location has excellent connectivity with a wide variety of frequently available transportation choices. Learn more</p>
                            </div>
                        </div>            */}
                        <h4>This Screen Pro is just for you!</h4>
                        <p>Congratulations! This Screen Pro is specifically curated to your needs by the talented Screen Designers at Actionfigure.</p>
                        <h4>Have questions? Want to make a change?</h4>
                        <p>Our Customer Success team is here to help, whenever you need it. Simply press the Contact button below, provide a brief description, and a member of our team will be in touch as soon as possible.</p>
                        <button className="customer-button">Contact Customer Success</button>
                    </div>
                    {/* This is where MobilityScore map would come in */}
                    {/*<div className="pure-u-1-2"></div>*/}
                </div>
            </div>
        </div>
    );
}

export default ScreenProTransportationContent;
