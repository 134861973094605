import { bizRequest } from "../../bizClient"; // Import the API helper
// import { Context } from "../Context"; // Import context to get token
// import { useContext } from "react";
import constants from './constants.json';

const getBasePlaylists = async (customerId) => {
    const playlists = await bizRequest(`/api/playlists/getBasePlaylists/${customerId}`)
    .then(data => {
        return data.map(d => {
            if (d.playlist.product === 'screen' || d.playlist.product === 'screen_pro') {
                return {
                    ...d.playlist,
                    devices: d?.devices,
                    location: d.location,
                    url: d?.slide?.url
                }
            }
            return;
        }).filter(n => n)
    })
    return playlists
}

const getTimezone = async (lat, long) => {
    const timezone = await fetch(`https://timezones.transitscreen.io/getTimezoneByCoordinates.php?apiKey=${process.env.REACT_APP_TIMEZONE_API_KEY}&lat=${lat}&lon=${long}`)
    .then(response => response.json())
    .then(data => data.tzid);

    return timezone;
}

const getContentPlaylists = async (customerId) => {
    return await bizRequest(`/api/playlists/getContentPlaylists/${customerId}`)
    .then(data => {
        return data
    })
    .catch((error) => {
        console.error('Something went wrong:', error);
    });
}

const removeMatchingParams = (mainObj, compareObj) => {
    return Object.fromEntries(
        Object.entries(mainObj).filter(([key, value]) => {
            const compareValue = compareObj[key];

            // If both values are strings, compare them case-insensitively
            if (typeof value === "string" && typeof compareValue === "string") {
                return value.toLowerCase() !== compareValue.toLowerCase();
            }

            // Otherwise, compare normally
            return value !== compareValue;
        })
    );
};

const savePlaylist = async (state, customer) => {

    const playlist = {
        ...state.playlist,
        language: state?.playlist?.language,
        updatedBy: customer?.name
    }

    //Determine if url should save with coordinates, or locationCode
    let url = playlist.locationType === 'locationCode'
    ?`${process.env.REACT_APP_CITYMO_URL}/?key=${customer?.apiKey || 'kDgUcNxt25pUpxy8ivQ5UYSvXc4dF0acwzLCLu3wMHa0rDZLGDcm3rsZ2LlMjsjs'}&locationCode=${playlist?.locationCode}`
    : `${process.env.REACT_APP_CITYMO_URL}/?key=${customer?.apiKey || 'kDgUcNxt25pUpxy8ivQ5UYSvXc4dF0acwzLCLu3wMHa0rDZLGDcm3rsZ2LlMjsjs'}&coordinates=${playlist?.latitude},${playlist?.longitude}`;

    const transportationConfigs = removeMatchingParams(state.transportationConfigs, constants.DEFAULT_TRANSPORTATION_CONFIGS)

    for (let key in transportationConfigs) {
        //Special case where transportation config affects columns in the playlist table
        if (key === 'interactive') {
            playlist.isInteractive = transportationConfigs[key]
        }

        let value = transportationConfigs[key]?.toString();        

        if (key !== 'key' && key !== 'coordinates' && key !== 'locationCode' && key !== 'environment' && key !== 'context'  && key !== 'lang' && key !== 'geolocate' && key !== 'geolocation' && value) {
            url += `&${key}=${value.replace(/\s/g, '')}`;
        }
    }

    //Add langauge value
    url += `&lang=${state?.playlist?.language}`;

    //Add geolocation only if it is needed
    if(state?.playlist?.geolocation === true) {
        url+= `&geolocation=${state?.playlist?.geolocation}`;
    }

    //Create or update depending on playlist
    const bizUrl = playlist?.id 
    ? `/api/playlists/savePlaylist`
    : `/api/playlists/createBasePlaylist`    
   
    return await bizRequest(bizUrl, 'POST', { 'Content-Type': 'application/json' }, {
        //If screen pro, don't bother moving to citymotion link just yet
        url: playlist.product === 'screen_pro' ? playlist.url : url,
        playlist: playlist
    })
    .then(resp => {
        return resp
    })
    .catch((error) => {
        console.error(error);
    });
}

const updatePlaylistVersion = async (playlistId) => {
    const url = `/api/playlists/updatePlaylistVersion`;

    return await bizRequest(url, 'POST', { 'Content-Type': 'application/json' }, {
        id: playlistId
    })
    .then(resp => {
        return resp;
    })
    .catch(error => {
        return error
    });    
}

export {
    getBasePlaylists,
    getTimezone,
    getContentPlaylists,
    savePlaylist,
    updatePlaylistVersion,
}
