import React, { useState, useEffect, useRef, useMemo } from 'react';
import { ReactSVG } from 'react-svg';
import Switch from "react-switch";
import Select from 'react-select';
import { Context } from '../Context';
import { HexColorInput } from "react-colorful";
import { PopoverPicker } from '../ColorPicker/PopoverPicker';

import plusIcon from '../../images/icons/screens/white-plus.svg';
import openReport from '../../images/icons/analytics/open-report.svg';
// import gearIcon from '../../images/icons/menu/gear.svg';
import adminIcon from '../../images/icons/admin.svg';
import whiteGearIcon from '../../images/icons/screens/white-gear.svg';
import returnIcon from '../../images/icons/menu/return.svg';
import saveIcon from '../../images/icons/menu/save.svg';
import keyIcon from '../../images/icons/key.svg';
import addImage from '../../images/icons/add-image.svg';
import deleteIcon from '../../images/icons/analytics/trash.svg';
import uploadIcon from '../../images/icons/upload.svg';
import tsIcon from '../../images/icons/ts-icon.svg';
import searchIcon from '../../images/icons/search.svg';

import { saveCustomer } from './utils';
import { getCustomers, getCustomersByShortCode } from '../../utils';
import AddressField from './AddressField';

import playlistOptions from '../ContentManagement/playlistOptions.json';
import screenFeatures from '../Screen/screenFeatures.json';

const CustomersPage = ({
    userData,
    lang
}) => {

    const [state, setState] = React.useContext(Context);
    
    const [customers, setCustomers] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState({});
    const [savedCustomer, setSavedCustomer] = useState({});
    const [showCustomersModal, setShowCustomersModal] = useState(false);
    const [showManageCustomerModal, setShowManageCustomerModal] = useState(false);
    const [customerEdited, setCustomerEdited] = useState(false);
    // const [selectedTab, setSelectedTab] = useState('screen-config');
    const [uploadedImage, setUploadedImage] = useState({
		file: null,
		name: null,
		type: null,
		url: ''
	});
    const dropFile = useRef(null);
    const fileInput = useRef(null);

    const defaultColorSwatch = '#d9e0ee';

    const onUpload = async (upload) => {
		const file = upload[0];
		//Only accept image types jpeg/jpg and gif
		if (file.type !== 'image/jpeg' && file.type !== 'image/gif' && file.type !== 'image/svg+xml' && file.type !== 'image/png' && file.type !== 'image/webp') {
        // if (file.type !== 'image/svg+xml') {
			// setTypeError(true);
			return;
		}
		const objectUrl = URL.createObjectURL(file);
        
    	setUploadedImage({
			file: file,
			name: file.name,
			type: file.type.split('/')[1],
			url: objectUrl
		});
		// setTypeError(false);
	};

    const handleDragOver = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const handleDrop = (e) => {
		e.preventDefault();
		e.stopPropagation();
	  
		const {files} = e.dataTransfer;
	  
		if (files && files.length) {
		  onUpload(files);
		}
	};

    const handleClick = () => {
        fileInput.current.click();
    };

    const customerFetch = async () => {
        await getCustomers()
        .then(data => {
            setCustomers(data);
            var customer = data.find(customer => customer.id === state.customer.id);
            setSelectedCustomer(customer)
            setUploadedImage({
                file: null,
                name: null,
                type: null,
                url: customer?.customerLogo ? customer?.customerLogo : ''
            })
        })
    }

    useEffect(() => {
        customerFetch();

		//Add event lister for file upload
		dropFile.current.addEventListener('dragover', handleDragOver);
		dropFile.current.addEventListener('drop', handleDrop);
	  
		return () => {
			dropFile.current.removeEventListener('dragover', handleDragOver);
			dropFile.current.removeEventListener('drop', handleDrop);
		};
	}, []);

    const handleInputChange = (property, value) => {
		const tempCustomer = {...selectedCustomer};
        tempCustomer[property] = value;
        setSelectedCustomer(tempCustomer);
        setCustomerEdited(true);
    }

    const setCustomerPrimaryColor = (color) => {
        handleColorChange("primaryColor", color);
    }

    const setCustomerSecondaryColor = (color) => {
        handleColorChange("secondaryColor", color);
    }

    const setCustomerBackgroundColor = (color) => {
        handleColorChange("backgroundColor", color);
    }

    const handleColorChange = (property, value) => {
		const tempCustomer = {...selectedCustomer};	
		if (value !== '') {
			tempCustomer[property] = value.includes('#') ? value : `#${value}`;
		}
		else {
			tempCustomer[property] = '';
		}
		setSelectedCustomer(tempCustomer);
        setCustomerEdited(true);
    }

    const handleCheckboxChange = (nav, value) => {

        if (!selectedCustomer?.id) return;

        let tempNavs = [...selectedCustomer?.enabledDash2Navs];
        if (value) {
            tempNavs.push(nav);
        }
        else {
            tempNavs = tempNavs.filter(item => item !== nav);
        }

        // In case there are sub-types of a product (eg., playlists can be basic or full)
        // do it here.
        tempNavs = addProductSubTypes(tempNavs, nav, value);

        // In case there are upgrades for a feature (eg. Trip Planner is a further
        // upgrade from Interactivity), remove the upgrade if the parent feature is
        // unselected.
        tempNavs = updateUpgrades(tempNavs, nav, value);

        setSelectedCustomer({
            ...selectedCustomer,
            enabledDash2Navs: tempNavs
        });
        setCustomerEdited(true);
    }

    const handleRadioInputChange = (option, value) => {
        if (!selectedCustomer?.id) return;

        let tempNavs = [...selectedCustomer?.enabledDash2Navs];
        var validTypes = [];
        if (option === 'playlist') {
            // Get list of valid types of playlist
            validTypes = playlistOptions.types.map((type) => type.id);
        }
        // First, remove the old value from tempNavs.
        tempNavs = tempNavs.filter(item => !validTypes.includes(item));
        // Next, add the new value.
        tempNavs.push(value)

        setSelectedCustomer({
            ...selectedCustomer,
            enabledDash2Navs: tempNavs
        });
        setCustomerEdited(true);
    }

    const handleCustomerSelection = (customer) => {        
        setSelectedCustomer(customer);
        setSavedCustomer(customer);
        setUploadedImage({
            file: null,
            name: null,
            type: null,
            url: customer?.customerLogo ? customer?.customerLogo : ''
        })
        setShowCustomersModal(false);
    }

    const handleSave = async() => {
        await saveCustomer(selectedCustomer, uploadedImage, userData?.name)
        .then(async data => {
            if (data.status === 200) {
                //Success so update customers
                await customerFetch();
                setSavedCustomer(selectedCustomer);
                setCustomerEdited(false);
                setState((prevState) => {
                    return {
                        ...prevState,
                        customer: selectedCustomer
                    }
                })
            }
        })
    }

    const selectAddress = (address) => {
        const tempCustomer = {...selectedCustomer};	
        tempCustomer.city = address.city;
        tempCustomer.zip = address.zip;
        tempCustomer.state = address.county;
        tempCustomer.address1 = address.address;

        setSelectedCustomer(tempCustomer);
        setCustomerEdited(true);
    }

    const switchCustomerId = () => {
        const isAFCustomer = state.userData.customerId === process.env.REACT_APP_ACTIONFIGURE_CUSTOMER_ID;
        //Only attempt when there is a selectedCustomer
        if (selectedCustomer?.id || !isAFCustomer) {
            setState((prevState) => {
                return {
                    ...prevState,
                    userData: {
                        ...prevState.userData,
                        //If AF customer, switch to selected customer. Vice versa if currently set to selected customer
                        customerId: isAFCustomer ? selectedCustomer.id : process.env.REACT_APP_ACTIONFIGURE_CUSTOMER_ID,
                    },
                    //Switch back to Af customer data if we are already assuming another customer identify
                    customer: isAFCustomer ? selectedCustomer : state.afCustomer
                }
            })
        }        
    }

    const fillSvgColor = (svg, stroke, fill) => {
		if (stroke || fill) {
			for (var i = 0; i < svg.children.length; i++) {
                svg.children[i].setAttribute('stroke', stroke);
				svg.children[i].setAttribute('fill', fill);
            }
		}
	}

    const handleDeleteImage = () => {
        // Set preview blank
        setUploadedImage({
            file: null,
            name: null,
            type: null,
            url: null
        })

        // Flag image to be deleted.
        var tempCustomer = {...selectedCustomer};
        if (tempCustomer.customerLogo.startsWith('http') && tempCustomer.customerLogo.includes('amazonaws.com')) {
            let urlParts = tempCustomer.customerLogo.split('/');
            let filename = urlParts.pop();
            // Filename extensions for SVG files look like "svg%2Bxml".
            // Revert to "svg+xml".
            if (filename.includes('.svg')) {
                filename = decodeURIComponent(filename);
            }
            let customerId = urlParts.pop();
            tempCustomer.deleteImageId = customerId + '/' + filename;
        }
        tempCustomer.customerLogo = null;
        setSelectedCustomer(tempCustomer);
    }

    const playlistTypeChecked = (customer, playlistType) => {
        var checked = false;
        if (customer?.enabledDash2Navs) {
            // No playlist type? Choose "playlistFull" as default
            if (customer.enabledDash2Navs.includes("playlists")
                && !customer.enabledDash2Navs.includes("playlistBasic")
                && !customer.enabledDash2Navs.includes("playlistFull")
                && playlistType.id === "playlistFull") {
                checked = true;
            } else {
                checked = customer.enabledDash2Navs.includes(playlistType.id);
            }
        }

        return checked;
    }

    const addProductSubTypes = (tempNavs, nav, value) => {
        if (nav === "playlists") {
            // Get the default playlist type
            var defaultType = playlistOptions.types.find(type => type.defaultPlaylistType === true);
            if (value) {
                tempNavs.push(defaultType.id);
            } else if (!value) {
                // Remove any mention of the playlist types
                playlistOptions.types.forEach(type => {
                    tempNavs = tempNavs.filter(item => item !== type.id);
                });
                // Messaging is also auto-disabled
                tempNavs = tempNavs.filter(item => item !== 'messaging');
            }
        }

        return tempNavs;
    }

    const updateUpgrades = (tempNavs, nav, value) => {
        // Example situation this is meant to handle:
        // A customer has purchased both Interactivity and Trip Planner.
        // They had to purchase Interactivity first because Trip Planner is an upgrade.
        // But now they haven un-purchased Interactivity.
        // We have to unselect Trip Planner now, too.
        if (!value) {
            let feature = screenFeatures.find((feature) => feature.key === nav);
            if (feature && "nested" in feature) {
                tempNavs = tempNavs.filter((tempNav) => feature.nested?.some((nested) => nested.key !== tempNav ));
            }
        }

        return tempNavs;
    }

    const screenMobileCheckboxes = useMemo(() => {
        if (!selectedCustomer) {
            return [];
        }

        let checkboxes = [];
        // These shouldn't be checkboxes
        let excludeFeatures = ['screenConfig', 'directory'];
        screenFeatures.forEach((feature) => {
            if (feature.endGrouping) {
                return;
            }
            if (!excludeFeatures.includes(feature.key)) {
                checkboxes.push(
                    <div className="checkbox-container" key={feature.key}>
                        <input className="checkbox-input" type="checkbox" onChange={(e) => handleCheckboxChange(feature.key, e.target.checked)} checked={selectedCustomer?.enabledDash2Navs ? selectedCustomer?.enabledDash2Navs.includes(feature.key) : false} disabled={!feature.enabled} />
                        <div className={`purchase-option ${!feature.enabled ? 'disabled' : ''}`}>
                            {feature.title}
                        </div>
                    </div>
                );

                if ("nested" in feature) {
                    feature.nested.forEach((nested) => {
                        let disabled = !selectedCustomer?.enabledDash2Navs?.includes(feature.key); 
                        checkboxes.push(
                            <div
                                key={nested.key}
                                className={`checkbox-container checkbox-container-nested ${disabled ? 'disabled' : '' }`}>
                                <input
                                    className="checkbox-input"
                                    type="checkbox"
                                    onChange={(e) => handleCheckboxChange(nested.key, e.target.checked)}
                                    checked={selectedCustomer?.enabledDash2Navs ? selectedCustomer?.enabledDash2Navs.includes(nested.key) : false}
                                    disabled={disabled}
                                />
                                <div className={`purchase-option ${!nested.enabled ? 'disabled' : ''}`}>{nested.title}</div>
                            </div>
                        );
                    })
                }
            }
        });

        return checkboxes;
    }, [selectedCustomer]); // Recalculate only when customer changes

    return (
        <div className="CustomersPage">
            {
                showCustomersModal
                ? <OpenCustomersModal 
                    customers={customers} 
                    selectedCustomer={selectedCustomer}
                    handleCustomerSelection={handleCustomerSelection}
                    setShowCustomersModal={setShowCustomersModal} />
                : <></>
            }
            {
                showManageCustomerModal
                ? <ManageCustomerModal
                    selectedCustomer={selectedCustomer}
                    setShowManageCustomerModal={setShowManageCustomerModal}
                    handleInputChange={handleInputChange}
                />
                : <></>
            }
            <div className="screen-actions">
                <div className="buttons-container pure-u-1-9">
                    <button className="open-screen">
                        <img className="open-icon" src={plusIcon}/>
                    </button>
                    <button className="open-screen" onClick={() => setShowCustomersModal(true)}>
                        <img className="open-icon" src={openReport}/>
                    </button>
                </div>                
				<div className="screen-name pure-u-1-7">
					{
						selectedCustomer?.name
						? <div className="name-container">
							<p className="name">{selectedCustomer?.name}</p>
							<p>{selectedCustomer?.address1}</p>
						</div>
						: <div className="name-container">
							<p className="name">No customer selected.</p>
							<p>Select a customer to view configurations</p>
						</div>
					}
				</div>
				<div className="customer-tabs pure-u-1-2">
					<div className={`icon-container selected-tab`} title="Screen configuration">
						<ReactSVG className="icon" src={whiteGearIcon} />
					</div>
                    <div 
                        className={`icon-container ${state.userData.customerId !== process.env.REACT_APP_ACTIONFIGURE_CUSTOMER_ID ? "switch-active" : ""}`}
                        title={`Switch to ${ selectedCustomer ? selectedCustomer.name : null}`}
                        onClick={switchCustomerId}
                    >
                        <ReactSVG className="icon" src={adminIcon} beforeInjection={(svg) => state.userData.customerId !== process.env.REACT_APP_ACTIONFIGURE_CUSTOMER_ID ? fillSvgColor(svg, '#fff', null) : fillSvgColor(svg, null, null)}/>
                    </div>
                    <div className={`icon-container`} title="Open in Admin v1" onClick={selectedCustomer?.id ? ()=> window.open(`https://transitscreen.io/admin/customers/edit/${selectedCustomer.transitScreenId}`, "_blank") : null}>
						<ReactSVG className="icon" src={tsIcon} beforeInjection={(svg) => { svg.setAttribute('height', '21px'); }} />
					</div>
					<div className="vertical-line"></div>
                    <div className={`icon-container`} title="Undo all changes" onClick={() => setSelectedCustomer(savedCustomer)}>
						<ReactSVG className="icon" src={returnIcon} />
					</div>
                    <div className={`icon-container ${customerEdited ? "changed" : ""}`} title="Save changes" onClick={selectedCustomer?.id ? () => handleSave(selectedCustomer) : null}>
                        <ReactSVG className="icon" src={saveIcon} beforeInjection={(svg) => customerEdited ? fillSvgColor(svg, null, '#fff') : null}/>
					</div>
                    <div className="vertical-line"></div>
                    <div className={`icon-container`} title="Manage license" onClick={selectedCustomer?.id ? () => setShowManageCustomerModal(true) : null}>
						<img className="icon" src={keyIcon} />
					</div>
				</div>                
			</div>
            <div className="customers-box">
                <div className="left-col pure-u-1-2">
                    <h3>Customers</h3>
                    <h4>Company information</h4>
                    <p>This will be the legal name, address, and phone number for your primary office location or corporate registration.</p>
                    <div className="input-container">
                        <div className="label pure-u-1-4">Company name:</div>
                        <div className="pure-u-3-4">
                            <input className="input" value={selectedCustomer?.name || ''} onChange={(e) => handleInputChange("name", e.target.value)}></input>
                        </div>
                    </div>
                    <div className="input-container">
                        <div className="label pure-u-1-4">Address:</div>
                        <AddressField 
                            handleAddressChanges={handleInputChange}
                            selectAddress={selectAddress}
                            value={ selectedCustomer.address1 || '' }
                            propName='address1'
                        />
                    </div>
                    <div className="input-container">
                        <div className="label pure-u-1-4">Phone:</div>
                        <div className="pure-u-3-4">
                            <input className="input" value={selectedCustomer?.phone || ''} onChange={(e) => handleInputChange("phone", e.target.value)}></input>
                        </div>
                    </div>
                    <div className="input-container">
                        <div className="label pure-u-1-4">Website:</div>
                        <div className="pure-u-3-4">
                            <input className="input" value={selectedCustomer?.website || ''} onChange={(e) => handleInputChange("website", e.target.value)}></input>
                        </div>
                    </div>
                    <h4>Branding</h4>
                    <div className="input-container">
                        <div className="label pure-u-1-4">Primary color:</div>
                        <div className="pure-u-2-4">
                            <HexColorInput className="input" color={selectedCustomer?.primaryColor || ''} onChange={setCustomerPrimaryColor} />
                        </div>
                        <div className="pure-u-1-4">
                            <PopoverPicker color={selectedCustomer?.primaryColor || defaultColorSwatch} onChange={setCustomerPrimaryColor} />
                        </div>
                    </div>
                    <div className="input-container">
                        <div className="label pure-u-1-4">Secondary color:</div>
                        <div className="pure-u-2-4">
                             <HexColorInput className="input" color={selectedCustomer?.secondaryColor || ''} onChange={setCustomerSecondaryColor} />
                        </div>
                        <div className="pure-u-1-4">
                            <PopoverPicker color={selectedCustomer?.secondaryColor || defaultColorSwatch} onChange={setCustomerSecondaryColor} />
                        </div>
                    </div>

                    <div className="input-container">
                        <div className="label pure-u-1-4">Background:</div>
                        <div className="pure-u-2-4">
                            <HexColorInput className="input" color={selectedCustomer?.backgroundColor || ''} onChange={setCustomerBackgroundColor} />
                        </div>
                        <div className="pure-u-1-4">
                            <PopoverPicker color={selectedCustomer?.backgroundColor || defaultColorSwatch} onChange={setCustomerBackgroundColor} />
                        </div>
                    </div>
                </div>
                <div className="right-col pure-u-1-2">
                    <div className="pure-g add-image-container">
                        <div className="pure-u-7-8 image-container">
                            <div className="add-image-border">
                                <div className="add-image" ref={dropFile}>
                                    {
                                        //This is a temporary check while all customer logos are changed from the old format
                                        //of saving svg as string in BIZ to storing those images in AWS and saving the URLs in BIZ
                                        uploadedImage?.url && uploadedImage.url[0] === '<'
                                        ? <img className="uploaded-image svg-image-flag" src={`data:image/svg+xml;utf8,${encodeURIComponent(uploadedImage.url)}`}></img>
                                        : uploadedImage?.url
                                        ? <img className="uploaded-image" src={uploadedImage.url}></img>
                                        : <img className="icon" src={addImage} />
                                    }
                                </div>
                            </div>
                            <div className="grey-text">Supported file types: SVG (recommended), WebP, JPG, PNG, or GIF.</div>
                        </div>                                              
                        <div className="pure-u-1-8 icon-container">
                            <div className="icon-box" style={{backgroundColor: 'var(--Blueberry, #2386EE)'}} onClick={handleClick}>
                                <img className="icon" src={uploadIcon} alt="Upload image" />
                                <input
                                    type="file"
                                    onChange={e => onUpload(e.target.files)}
                                    ref={fileInput}
                                    style={{display: 'none'}}
                                />
                            </div>
                            <div className="icon-box" onClick={() => handleDeleteImage()}>
                                <img className="icon" src={deleteIcon} alt="Delete image" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="purchases-box">
                <h4>Purchases and permissions</h4>
                <div className="solutions-container pure-g">
                    <div className="purchase-col pure-u-1-3">
                        <h4>Solutions</h4>
                        <div className="checkbox-container">
                            <input className="checkbox-input" type="checkbox" onChange={(e) => handleCheckboxChange('screen', e.target.checked)} checked={selectedCustomer?.enabledDash2Navs ? selectedCustomer?.enabledDash2Navs.includes('screen') : false} />
                            <div className="purchase-option">Screen</div>
                        </div>
                        <div className="checkbox-container">
                            <input className="checkbox-input" type="checkbox" onChange={(e) => handleCheckboxChange('mobile', e.target.checked)} checked={selectedCustomer?.enabledDash2Navs ? selectedCustomer?.enabledDash2Navs.includes('mobile') : false} />
                            <div>Mobile</div>
                        </div>
                        <div className="checkbox-container">
                            <input className="checkbox-input" type="checkbox" onChange={(e) => handleCheckboxChange('foresight', e.target.checked)} checked={selectedCustomer?.enabledDash2Navs ? selectedCustomer?.enabledDash2Navs.includes('foresight') : false} />
                            <div>Foresight</div>
                        </div>
                        <div className="checkbox-container">
                            <input className="checkbox-input" type="checkbox" onChange={(e) => handleCheckboxChange('insight', e.target.checked)} checked={selectedCustomer?.enabledDash2Navs ? selectedCustomer?.enabledDash2Navs.includes('insight') : false} />
                            <div>Insight</div>
                        </div>
                        <div className="checkbox-container">
                            <input className="checkbox-input" type="checkbox" onChange={(e) => handleCheckboxChange('mobilityscore', e.target.checked)} checked={selectedCustomer?.enabledDash2Navs ? selectedCustomer?.enabledDash2Navs.includes('mobilityscore') : false} />
                            <div>MobilityScore</div>
                        </div>
                    </div>
                    <div className="purchase-col pure-u-1-3">
                        <h4>Screen / Mobile features</h4>
                        { screenMobileCheckboxes }
                    </div>
                    <div className="purchase-col pure-u-1-3">
                        <h4>Playlists & Messaging</h4>
                        <div className="checkbox-container">
                            <input className="checkbox-input" type="checkbox" onChange={(e) => handleCheckboxChange('playlists', e.target.checked)} checked={selectedCustomer?.enabledDash2Navs ? selectedCustomer?.enabledDash2Navs.includes('playlists') : false} />
                            <div>Playlists</div>
                        </div>
                        {
                            playlistOptions.types.map((type) => {
                                return <div key={`radio-${type.id}`} className="checkbox-container checkbox-container-nested">
                                    <input
                                        className="checkbox-input"
                                        type="radio"
                                        name="playlistType"
                                        value={type.id}
                                        onChange={(e) => handleRadioInputChange('playlist', type.id)}
                                        checked={playlistTypeChecked(selectedCustomer, type)}
                                        disabled={!selectedCustomer?.enabledDash2Navs?.includes('playlists') ? "disabled": '' }
                                    />
                                    <div className={!selectedCustomer?.enabledDash2Navs?.includes('playlists') ? "disabled" : ''}>{type.name}</div>
                                </div>

                            })
                        }
                        <div className="checkbox-container">
                            <input className="checkbox-input" type="checkbox" onChange={(e) => handleCheckboxChange('messaging', e.target.checked)} checked={selectedCustomer?.enabledDash2Navs ? selectedCustomer?.enabledDash2Navs.includes('messaging') : false} disabled={!selectedCustomer?.enabledDash2Navs?.includes('playlists') ? "disabled": '' } />
                            <div className={!selectedCustomer?.enabledDash2Navs?.includes('playlists') ? "disabled" : ''}>Messaging</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ); 
}

const OpenCustomersModal = ({ customers, selectedCustomer, handleCustomerSelection, setShowCustomersModal }) => {
    let searchOptions = [
        {
            label: 'Name',
            value: 'name'
        },
        {
            label: 'Activation code (short code)',
            value: 'shortCode'
        }
    ]

    const [filteredCustomers, setFilteredCustomers] = useState([...customers]);
    const [searchType, setSearchType] = useState(searchOptions[0]);
    const [searchCustomer, setSearchCustomer] = useState(null);
    const [highlightedCustomer, sethighlightedCustomer] = useState(selectedCustomer);

    useEffect(() => {
		// Prevent scrolling when modal is open
		document.body.classList.add("no-scroll");
	
		return () => {
			// Allow scrolling when modal is closed
			document.body.classList.remove("no-scroll");
		};
	}, []);

    const cleanUpSearchString = (searchString) => {
        let res = searchString.trim();
        // Replace multiple spaces with one space
        res = res.replace(/\s+/g, ' ');

        return res;
    }

    //Search and filter by customer
    const filterCustomer = (searchString, type) => {
        let cleanSearchString = cleanUpSearchString(searchString);

        if (cleanSearchString.length > 0) {
            let regexp = new RegExp(cleanSearchString, "ig");
            switch (type){
                case "name":
                    setFilteredCustomers(customers.filter(customer => {
                        return customer.name.match(regexp) || customer.transitScreenName?.match(regexp);
                    }));
                    break;
                case "shortCode":
                    getCustomersByShortCode(cleanSearchString)
                    .then(data => {
                        setFilteredCustomers(data);
                    })
                    break;
            }
        } else {
            //If no search string, set to regular customers array
            setFilteredCustomers(customers);
        }
    }

    const handleSearchTypeChange = (option) => {
        setSearchType(option);
        filterCustomer(searchCustomer || '', option.value);
    }

    const updateModalContents = (searchString) => {
        setSearchCustomer(searchString);
        // If user empties out the search box, display all of the Customers again.
        if (searchString === '') {
            setFilteredCustomers(customers);
        }
    }

	const customerRows = filteredCustomers.length === 0
	? <tr className="no-customers-text"><td colSpan="4">No customers found</td></tr>
	: filteredCustomers.map(customer => {

		return <tr key={customer?.id} className={`customer-row ${customer?.id === highlightedCustomer?.id ? 'selected' : ''}`} onClick={() =>sethighlightedCustomer(customer)}>
			<td>{customer?.transitScreenId}</td>
            <td>{customer?.transitScreenName}</td>
            <td>{customer?.name}</td>
			<td>{customer?.isDeleted ? 'Inactive' : 'Active'}</td>
		</tr>
	})

    return (
        <div className="modal-wrapper">
            <div className="modal CustomerModal">
                <div className="modal-header">
                    <h2>Customers</h2>				
                </div>
                <div className="table-container">
                    <table className="customers-table">
                        <tbody>
                            <tr className="header-row">							
                                <th className="table-header">TS ID</th>
                                <th className="table-header">TS name</th>
                                <th className="table-header">Company Name</th>
                                <th className="table-header">Status</th>
                            </tr>
                            {customerRows}
                        </tbody>
                    </table>
                </div>
                <div className="bottom-container">
                    <div className="filter-container">
                        <div className="customers-search">Search</div>
                        <Select  
                            onChange={(option) => handleSearchTypeChange(option)}
                            options={ searchOptions }
                            value={ searchType }
                            className={"dropdown"}
                            styles={{
                                control: base => ({
                                  ...base,
                                  border: "none",
                                  borderRadius: "6px",
                                  boxShadow: "0px 1.542px 7.708px 3.083px rgba(0, 0, 0, 0.09)"
                                }),
                                indicatorSeparator: base => ({...base, backgroundColor: "none" }),
                                dropdownIndicator: base => ({...base, color: "#373739" }),
                                menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            isSearchable={false}
                        />
                        <div className="customers-search">for</div>
                        <input 
                            onChange={(e) => updateModalContents(e.target.value)}
                            className={"customer-search"}
                            value={searchCustomer || ''}
                        />
                        <button className="customer-search-button" onClick={(e) => filterCustomer(searchCustomer, searchType.value)}><img src={searchIcon} id="customer-search-icon" /></button>
                    </div>
                    <div className="buttons-container">       
                        <button className="cancel-button" onClick={() => setShowCustomersModal(false)}>Cancel</button>
                        <button className="modal-button" onClick={() => handleCustomerSelection(highlightedCustomer)}>Open</button>
                    </div>
			    </div>
            </div>
        </div>
    )
}

const ManageCustomerModal = ({ selectedCustomer, setShowManageCustomerModal, handleInputChange }) => {

    useEffect(() => {
		// Prevent scrolling when modal is open
		document.body.classList.add("no-scroll");
	
		return () => {
			// Allow scrolling when modal is closed
			document.body.classList.remove("no-scroll");
		};
	}, []);

    return (
        <div className="modal-wrapper">
            <div className="modal ManageCustomerModal">
                <div className="modal-header">
                    <h2>Manage customer</h2>
                </div>
                <div className="license-container">
                    <div>
                        <h4>TransitScreen ecosystem (Admin v1)</h4>
                        <div className="input-container">
                            <p className="label">Customer ID:</p>
                            <p className="license-input">{selectedCustomer?.transitScreenId}</p>
                        </div>
                        <div className="input-container">
                            <p className="label">Customer name:</p>
                            <p className="license-input">{selectedCustomer?.transitScreenName}</p>
                        </div>
                        <div className="input-container">
                            <p className="label">"API" Key:</p>
                            <input className="license-input" value={selectedCustomer?.apiKey || ''} disabled />
                        </div>
                    </div>
                </div>
                <div className="activation-details">
                    <div>
                        <h4>Actionfigure ecosystem</h4>
                        <div className="input-container">
                            <p className="label">Customer ID:</p>
                            <p className="license-input">{selectedCustomer?.id}</p>
                        </div>
                        <div className="input-container">
                            <p className="label">Active:</p>     
                            <Switch className="switch" checked={!selectedCustomer?.isDeleted} onChange={(checked) => handleInputChange('isDeleted', !checked)} onColor="#2386EE" uncheckedIcon checkedIcon={false}/>                    
                        </div>
                    </div>
                </div>
                <div className="bottom-container">
                    <div className="buttons-container">
                        <button className="cancel-button" onClick={() => setShowManageCustomerModal(false)}>Cancel</button>
                        <button className="modal-button" onClick={() => setShowManageCustomerModal(false)}>Ok</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomersPage;
