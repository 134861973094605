import { useState, useEffect } from 'react';
import { Circles } from 'react-loader-spinner';
import { getLocations, saveLocation, formatLocationAddress } from './utils';
import { findGmapsState, findGmapsCountry, findGmapsCity, getTimezone } from '../../utils';


import plusIcon from '../../images/icons/screens/white-plus.svg';

const LocationPage = ({
    lang,
	userData,
    customer
}) => {

    const [locations, setLocations] = useState([]);
    const [editLocation, setEditLocation] = useState({
        address:"",
        name: ""
    });
    const [showLocationModal, setShowLocationModal] = useState(false);

    const getBizLocations = async () => {
        return await getLocations(customer.id);
    }

    useEffect(() => {
        getBizLocations().then(data => {
            setLocations(data);
        })
    }, []);

    const locationClicked = (location) => {
        setEditLocation(location);
        setShowLocationModal(true);
    }

    const formatDateDigits = (num) => {
        return num < 10 ? `0${num}` : num;
    }

    const formatDate = (dateTime) => {
        const date = dateTime ? new Date(dateTime) : null
        return date
        ? `${formatDateDigits(date.getMonth() + 1)}/${formatDateDigits(date.getDate())}/${date.getFullYear()}`
        : null
    }

    const locationsRows = locations.length === 0
	? <tr className="no-locations-text"><td></td><td></td><td></td><td>No locations found</td></tr>
	: locations.map(location => {
		return <tr key={location.id} className={`location-row`} onClick={() => locationClicked(location)}>
            <td>{location?.name}</td>
            <td>{location?.address1}</td>
            <td>{location?.city}</td>
            <td>{location?.state}</td>
            <td>{location?.zip}</td>
            <td>{location?.country}</td>
            <td>{formatDate(location.createdAt)}</td>
		</tr>
	})

	return ( 
		<div className="LocationsPage">
            { showLocationModal && <LocationModal setShowLocationModal={setShowLocationModal} customerId={userData.customerId} editLocation={editLocation} setEditLocation={setEditLocation} setLocations={setLocations} getBizLocations={getBizLocations} /> }
            <div className="actions-tab">
                <div className="left-container">
                    <button className="open-location pure-u-1-9" onClick={() => setShowLocationModal(true)}>
                        <img className="open-icon" src={plusIcon}/>
                    </button>
                    <div className="location-name pure-u-1-7">
                        <p style={{marginTop: 0, marginBottom: 0}}>{customer?.name}</p>
                        <p style={{marginTop: "0.5em"}}>{customer?.address1}</p>
                    </div>
                </div>
			</div>
            <div className="locations-container">
                <h3>Locations</h3>
                <table className="locations-table">
                    <tbody>
                        <tr className="header-row">							
                            <th className="table-header">Name</th>
                            <th className="table-header">Address</th>
                            <th className="table-header">City</th>
                            <th className="table-header">State/Province</th>
                            <th className="table-header">Zip/Post</th>
                            <th className="table-header">Country</th>
                            <th className="table-header">Created</th>
                        </tr>
                        {locationsRows}
                    </tbody>
                </table>                
            </div>
            <div className="subscriptions-container">
                <h3>Subscriptions</h3>
                <div className="message-container">
                    <h3>Subscription management</h3>
                    <h3>Coming soon...</h3>
                </div>                
            </div>
		</div>
	);
}

const AddressField = ({
    error,
    existingLocation,
    placeholder,
    searchLocation,
    selectAddress,
    setSearchLocation,
}) => {

    const [isActive, setIsActive] = useState(null);
    const [searchResults, setResults] = useState(null);
    const [searchTimeout, setSearchTimeout] = useState(null);

    function onFocusEvent(e) {
        setIsActive(true);
    }

    async function onChangeEvent(e) {
        clearTimeout(searchTimeout); 
        const val = e.target.value || '';
        setSearchLocation(val)

        if (!val) return null; // The user has zeroed out the field, keeps the last search results up
        setIsActive(true);
        try {
            setSearchTimeout(setTimeout(async() => {
                await search(val);
            }, 1000))
            
        } catch (e) {
            console.error(e);
        }
    }

    async function onSelect(location) {
        selectAddress({
            ...existingLocation,
            address: location?.address,
            address1: location?.address1,
            city: location?.city,
            country: location?.country,
            latitude: location?.latitude,
            longitude: location?.longitude,
            state: location?.state,
            timezone: await getTimezone(location?.latitude, location?.longitude),
            zip: location?.zip
        });
        setSearchLocation(location?.address);
        setIsActive(false);
        setResults(null);
    }

    async function search(searchString) {
        return new Promise(async (resolve, reject) => {
            const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${searchString}&key=${process.env.REACT_APP_GMAPS_KEY}`

            return fetch(url)
            .then(addresses => addresses.json())
            .then(results => {
                if (results.status === 'OK') {
                    const newResults = results.results.slice(0,5).map((result) => {
                        const city = findGmapsCity(result.address_components);
                        const state = findGmapsState(result.address_components);
                        const zip = result.address_components.find(item => item.types.includes('postal_code'));
                        const address1 = result.formatted_address.split(',')[0];
                        return {
                            name: address1,
                            address: result.formatted_address,
                            address1,
                            city,
                            latitude: result.geometry?.location?.lat || null,
                            longitude: result.geometry?.location?.lng || null,
                            state: state,
                            zip: zip?.long_name,
                            country: findGmapsCountry(result.address_components)?.short_name,
                        }
                    });
                    setResults(newResults);
                    resolve(newResults)
                }
                return;
            })
        })
    }

    function Results({ searchResults }) {
        if (!searchResults) return null;
        return searchResults.map((result, i) => {
            if (!result) return null;
            return (
                <div className="results" key={i}>
                    <button className="select-item" onClick={() => onSelect(result)}>
                        {result.name}
                        <div className="select-item-address">{result.address}</div>
                    </button>
                </div>
            )
        });
    }

    return (
        <div className="AddressField">
            <div className="search-container">
                <input
                    className={`input ${error ? "error" : ""}`}
                    type="search"
                    value={searchLocation}
                    placeholder={placeholder}
                    onFocus={onFocusEvent}
                    onChange={(e) => { onChangeEvent(e)} }
                    autoComplete="off"
                    data-lpignore="true"
                    v-model="filter"
                    data-1p-ignore={true}
                    />
                <div className="results-container">
                    <Results searchResults={searchResults} />
                </div>
            </div>
        </div>
    );
}

const LocationModal = ({ setShowLocationModal, customerId, editLocation, setEditLocation, setLocations, getBizLocations }) => {

    const [location, setLocation] = useState({
        ...editLocation,
        address: editLocation ? formatLocationAddress(editLocation) : "",
        customerId,
        name: editLocation ? editLocation?.name : "",
    })
    const [searchLocation, setSearchLocation] = useState(editLocation ? formatLocationAddress(editLocation) : "");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const handleSubmit = async () => {
        if (location?.name.length === 0 || location?.address.length === 0) {
            setError(true);
            return;
        }

        setLoading(true);

        await saveLocation(location)
        .then(async response => {
            //Success
            if (response.status === 201) {
                await getBizLocations().then(data => {
                    setLocations(data);
                })                  
            }
            else {
                console.log("Could not create or update the location");
            }
        });

        setLoading(false);
        setShowLocationModal(false);
        setEditLocation(null);
        setError(false);
    }

    const handleCancel = () => {
        setShowLocationModal(false);
        setEditLocation(null);
    }

    return (
        <div className="modal-wrapper">
            <div className="modal CreateLocationModal">
                {
                    loading
                    ? <div className="Loading">
                        <Circles
                            height="80"
                            width="80"
                            color="#2386EE"
                            ariaLabel="circles-loading"
                            visible={true}
                        />
                    </div>
                    : <> 
                        <div className="modal-header">
                            <h2>{editLocation ? "Edit a location" : "Create a location"}</h2>				
                        </div>
                        <div className="locations-options">
                            <div className="input-container">
                                <p className="pure-u-1-4">Name:</p>
                                <div className="AddressField">
                                    <input className={`input ${error ? "error" : ""}`} onChange={(e) => setLocation({...location, name: e.target.value})} value={location?.name || ''} />
                                </div>
                            </div>
                            <div className="input-container">
                                <p className="pure-u-1-4">Address:</p>
                                <AddressField
                                    error={error}
                                    existingLocation={location}
                                    placeholder={'Search for an address'}
                                    searchLocation={searchLocation}
                                    selectAddress={setLocation}
                                    setSearchLocation={setSearchLocation}
                                />
                            </div>
                        </div>
                        <div className="bottom-container">
                            <div className="buttons-container">
                                <button className="cancel-button" onClick={handleCancel}>Cancel</button>
                                <button className="modal-button" onClick={handleSubmit}>{editLocation?.id ? 'Update': 'Create'}</button>
                            </div>  
                        </div>
                    </>
                }	
            </div>		
        </div>
    )
}

export default LocationPage;
