import React, {
    useState,
    useEffect,
    useCallback
} from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { getTimezone } from './utils';
import { config } from '../../config';

const MapWrapper = ({
    state,
    setState
}) => {

    const [mapRef, setMapRef] = useState(null);
    const [centerMarker, setCenterMarker] = useState(null);
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GMAPS_KEY,
        libraries: config.googleMapScriptLibraries
    });

    // const geocode = useCallback(async (coords) => {
    //     const geocoderRef = new window.google.maps.Geocoder();
    //     var resp = await geocoderRef.geocode({location: {lat: coords.lat, lng: coords.lng}});

    //     // If there are suite or room numbers in the address, remove it.
    //     var address = resp.results[0].formatted_address;
    //     for (let addr of resp.results[0].address_components) {
    //         if (addr.types.includes('subpremise')) {
    //             address = address.replace(' ' + addr.long_name, '');
    //         }
    //     }

    //     return address;
    // }, []);

    // If a new playlist is loaded, change the map marker to its coordinates
    useEffect(() => {
        if (state?.playlist?.location?.latitude && state?.playlist?.location?.longitude) {
            setCenterMarker({ lat: state?.playlist?.location?.latitude * 1, lng: state?.playlist?.location?.longitude *1 });
        }
    }, [state?.playlist?.locationId]);


    const handleOnLoad = map => {
        setMapRef(map);
        var defaultCenter = { lat: 38.89985, lng: -77.03988 };
        setCenterMarker(defaultCenter);
        // const tempPlaylist = {
        //     ...state.playlist
        // };

        // setState({
        //     ...state,
        //     playlist: (tempPlaylist?.id || tempPlaylist?._new) ? tempPlaylist : {
        //         timezone: "America/New_York",
        //         address: "750 17th St NW, Washington, DC 20006, USA",
        //         language: 'en',
        //         latitude: defaultCenter.lat,
        //         longitude: defaultCenter.lng,
        //         rotation: 0
        //     }
        // });
    };

    // const handleOnCenterChanged = () => {
    //     if (!mapRef) { return; }
    //     // Continually update the latitude and longitude fields
    //     const tempPlaylist = {...state.playlist};
    //     setState({
    //         ...state,
    //         playlist: {
    //             ...tempPlaylist,
    //             latitude: tempPlaylist.latitude,
    //             longitude: tempPlaylist.longitude,
    //         }
    //     });

    // };

    // Clear data from the Location section once the map moves.
    // const handleOnDragStart = () => {
    //     const tempPlaylist = {...state.playlist};
    //     setState({
    //         ...state,
    //         playlist: {
    //             ...tempPlaylist,
    //             address: null,
    //             timezone: null,
    //         }
    //     });
    // }

    // https://stackoverflow.com/a/47480429/1021224
    // const delay = ms => new Promise(res => setTimeout(res, ms));
    // Reverse geocode the coordinates and determine the timezone
    // const handleOnDragEnd = async () => {
    //     if (!mapRef) { return; }
    //     // When ending the drag event on a map, there is a very short delay between the mouse/user
    //     // lifting off their figurative finger from the map, and the map actually stopping its drag event.
    //     // The map keeps scrolling/dragging a tiny bit, then stops. Wait for that delay, then actually get
    //     // the center from the map.
    //     await delay(100);
    //     // Get map state before 2s delay to make sure the map didn't move.
    //     var mapCenter = mapRef.getCenter();
    //     var mapLat = parseFloat(mapCenter.lat());
    //     var mapLng = parseFloat(mapCenter.lng());
    //     await delay(2000);
    //     var newCenter = mapRef.getCenter();
    //     var newLat = parseFloat(newCenter.lat());
    //     var newLng = parseFloat(newCenter.lng());
    //     if (mapLat === newLat && mapLng === newLng) {
    //         console.log('Get address for', newLat, newLng);
    //         var coords = {lat: mapLat, lng: mapLng};
    //         var address = await geocode(coords);
    //         var timezone = await getTimezone(mapLat, mapLng);

    //         const tempPlaylist = {...state.playlist};
    //         setState({
    //             ...state,
    //             playlist: {
    //                 ...tempPlaylist,
    //                 address: address,
    //                 latitude: mapLat,
    //                 longitude: mapLng,
    //                 timezone: timezone
    //             }
    //         });
    //     }
    // };

    const onUnmount = useCallback(function callback(map) {
        setMapRef(null);
      }, []);

    return isLoaded
        ? <>
            <GoogleMap
                onLoad={handleOnLoad}
                // onDragStart={handleOnDragStart}
                // onDragEnd={handleOnDragEnd}
                // onCenterChanged={handleOnCenterChanged}
                id="gmap-container"
                center={centerMarker}
                mapContainerStyle={{
                    width: '100%',
                    borderRadius: '8px',
                    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.30)'
                  }}
                zoom={15}
                options={{disableDefaultUI: true, scaleControl: false, zoomControl: false, mapTypeControl: false, mapTypeControl: false, draggable: false}}
                onUnmount={onUnmount}
            >
                <Marker position={centerMarker} />
            </GoogleMap>
    </> : <></>;
}

export default MapWrapper;
