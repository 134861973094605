import { useEffect, useState, useRef } from 'react';
import { Autocomplete, useJsApiLoader } from '@react-google-maps/api';
import { googleMapScriptLibraries } from '../../config';
import { Circles } from 'react-loader-spinner';
import copyIcon from '../../images/icons/screens/copy.svg';

import addressTemplate from './mobscore_address_template.csv';
import coordinatesTemplate from './mobscore_geocoordinates_template.csv';

const mobUrl = process.env.REACT_APP_MOB_URL + "/mobilityscore/v1/score.json?coordinates={coords}&key=" + process.env.REACT_APP_MOB_API_KEY;

const scores = [
  {value: "mobilityscore", label: "MobilityScore&reg;"},
  {value: "transitscore", label: "TransitScore&reg;"},
  {value: "walkscore", label: "WalkScore&reg;"}
];

async function getMobilityScore(coords) {
  var queryUrl = mobUrl.replace("{coords}", coords);
  let response = await fetch(queryUrl);
  let data = await response.json();

  return data;
}

const MobilityScore = ({
  lang,
  userData
}) => {
  const options = {
    fields: ["address_components", "formatted_address", "geometry", "name"]
  };

  const [searchResult, setSearchResult] = useState("Result: none");
  const [singleScoreResults, setSingleScoreResults] = useState({score: null, address: null, scorePage: null});

  const [showErrorMessageModal, setShowErrorMessageModal] = useState(false);
  const [errors, setErrors] = useState();

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successMesage, setSuccessMessage] = useState(null);

  const [downloads, setDownloads] = useState(null);
  const [downloadsError, setDownloadsError] = useState(null);

  const [selectedFile, setSelectedFile] = useState(null);

  // MobilityScore is always selected.
  const [selectedScores, setSelectedScores] = useState(['mobilityscore']);

  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    getDownloads();
  }, []);

  const getDownloads = () => {
    fetch(
        process.env.REACT_APP_TRANSITSCREEN_URL + 'scorefinder/downloads',
        {
          method: 'GET',
          headers: { 'apiKey': process.env.REACT_APP_SCOREFINDER_API_KEY },
        }
      )
      .then(response => response.json())
      .then(json => {
        if (json.status === 200 && json.links) {
          setDownloads(json.links);
        } else {
          setDownloadsError('There was an issue fetching the MobilityScore Pro downloads. Contact the dev team for help.');
        }
      });
  };

  const hiddenFileInput = useRef(null);

  const { isLoaded } = useJsApiLoader({
      id: 'google-map-script',
      googleMapsApiKey: process.env.REACT_APP_GMAPS_KEY,
      libraries: googleMapScriptLibraries
  });

  const clearErrorMessages = () => {
    setShowErrorMessageModal(false);
    setErrors([]);
  }

  const clearSuccessMessages = () => {
    setShowSuccessModal(false);
    setSuccessMessage(null);
  }

  const onLoad = (autocomplete) => {
    setSearchResult(autocomplete);
  }

  const onPlaceChanged = () => {
    if (searchResult != null) {
      const place = searchResult.getPlace();
      if (typeof place === 'object' && place?.geometry !== undefined) {
        getScore(place);
      }
    } else {
      console.log("Please enter text");
    }
  }

  const getScore = (place) => {
    // A spinner gif to tell end user we're working on it
    setShowSpinner(true);

    var latitude = place.geometry.location.lat();
    var longitude = place.geometry.location.lng();
    var coordinates = latitude + ',' + longitude;

    getMobilityScore(coordinates)
    .then((response) => {

      let score = response.data.score;
      let address = place.formatted_address;
      let scorePage = response.data.mapUrl;

      setSingleScoreResults({score: score, address: address, scorePage: scorePage});

      setShowSpinner(false);
    });
  }

  const copyText = (textToCopy) => {
    navigator.clipboard.writeText(textToCopy);
  }

  const labelize = (key) => {
    // Split a camel case
    let label = key.replace(/[A-Z]/g, " $&").replace(/\s[A-Z]/, function(str) { return str.toLowerCase(); })

    return label.charAt(0).toUpperCase() + label.slice(1);
  }

  const getResultsSection = () => {
    let results = [];
    for (const [key, value] of Object.entries(singleScoreResults)) {
      let label = labelize(key);
      let jsx = <div className="pure-g mob_margin_above_below" key={`result-for-${key}`}>
        <div className="pure-u-1-5 mob_label">{label}</div>
        <div className="pure-u-4-5">
          <div className="mob_results_field" onClick={() => copyText(value)} title={`Copy ${label}`}>
            <div className="">{value}</div>
            <img src={copyIcon} alt="copy" className="results_copy"/>
          </div>
        </div>
      </div>

      results.push(jsx);
    }

    return results;
  }

  const handleClick = ( event ) => {
    hiddenFileInput.current.click();
  };

  const handleChange = async (event) => {
    const file = event.target.files[0];

    if (file.type === 'text/csv') {
      const formData = new FormData();
      formData.append("user", userData.nickname);
      formData.append("locations", file, file.name)
      selectedScores.forEach((score) => {
        formData.append("score[]", score);
      })

      fetch(
        process.env.REACT_APP_TRANSITSCREEN_URL + 'scorefinder/getScores',
        {
          method: 'POST',
          headers: { 'apiKey': process.env.REACT_APP_SCOREFINDER_API_KEY },
          body: formData
        }
      )
      .then(response => response.json())
      .then(json => {
        setSuccessMessage(json.message);
        getDownloads();
      });
    } else {
      console.log('Incorrect file type', file);
      setErrors(['Incorrect file type. Please upload a CSV file']);
    }
  }

  const getScoreCheckboxes = () => {
    let jsx = [];
    scores.forEach((score) => {
      jsx.push(<div key={`checkbox-${score.value}`} className="score_checkbox" style={{paddingRight: "1em"}}>
        <div>
          <input
            type="checkbox"
            name="scores[]"
            value={score.value}
            checked={ score.value === 'mobilityscore' ? 'checked' : null }
            readOnly={ score.value === 'mobilityscore' ? true : false }
            onClick={() => score.value === 'mobilityscore' ? null : updateSelectedScores(score.value)}
          />
        </div>
        <div dangerouslySetInnerHTML={{ __html: score.label }}></div>
      </div>
      )
    });

    return jsx;
  }

  const updateSelectedScores = (scoreName) => {
    // MobilityScore will always be selected. Don't do anything.
    if (scoreName === 'mobilityscore') { return; }
    if (!selectedScores?.includes(scoreName)) {
      let tempScores = selectedScores;
      tempScores.push(scoreName);
      setSelectedScores(tempScores);
    } else {
      let tempScores = selectedScores?.filter((score) => score !== scoreName);
      setSelectedScores(tempScores);
    }
  }

  const formattedTime = (download) => {
    let time  = new Date(0);
    // download.time is UTC/unix epoch
    time.setUTCSeconds(download.time);
    let hours = time.getHours();
    let amPm = hours < 12 ? 'am' : 'pm';
    hours = hours > 12 ? hours - 12 : hours;
    let minutes = time.getMinutes().toString().padStart(2, 0);

    return hours + ':' + minutes + ' ' + amPm;
  }

  const getDownloadsRows = () => {
    let results = [];
    if (downloads) {
      downloads.forEach((download) => {
        let jsx = <tr
          key={download.id}
          className={`mob_table_row ${ getRowClassNames(download) }`}
          onClick={() => toggleSelectedFile(download)}
        >
          <td>{download.filename}</td>
          <td>{download.date}</td>
          <td>{formattedTime(download)}</td>
          <td>{download.user}</td>
          <td>{download.status}</td>
        </tr>
        results.push(jsx);
      })
    }

    return results;
  }

  const getRowClassNames = (download) => {
    let classNames = [];
    if (download.status.toLowerCase() === 'in progress') {
      classNames.push('status_in_progress');
    }

    if (selectedFile && download.id === selectedFile.id) {
      classNames.push('highlighted_row');
    }

    return classNames.join(' ');
  }

  const toggleSelectedFile = (download) => {
    if (!selectedFile) {
      setSelectedFile(download);
    } else {
      if (selectedFile.id !== download.id) {
        // Change to a different selected file
        setSelectedFile(download);
      } else {
        // Unselect a file
        setSelectedFile(null);
      }
    }
  }

  const deleteSelectedFile = () => {
    if (selectedFile.id) {
      if (selectedFile.status.toLowerCase() === 'done') {
        fetch(
          selectedFile.deleteLink,
          { method: 'DELETE', headers: { 'apiKey': process.env.REACT_APP_SCOREFINDER_API_KEY } }
        )
        .then(response => response.json())
        .then(json => {
          setSuccessMessage(json.message);
          getDownloads();
        })
      } else if (selectedFile.status.toLowerCase() === 'in progress') {
        setErrors(['You cannot delete a file in progress.']);
      }
    }
  }

  const downloadSelectedFile = () => {
    if (selectedFile.id) {
      fetch(
          selectedFile.downloadLink,
          { method: 'GET', headers: { 'apiKey': process.env.REACT_APP_SCOREFINDER_API_KEY } }
        )
        .then(response => response.blob())
        .then(blob => {
          // Source: https://fmennen.de/post/downloading-files-with-java-script-fetch-api
          // Create a temporary URL for the Blob
          const url = URL.createObjectURL(blob);
          // Create a link and set its href to the temporary URL
          const link = document.createElement('a');
          link.href = url;
          // Set the link attributes for downloading
          link.setAttribute('download', selectedFile.filename);
          // Programmatically click the link to initiate the download
          link.click();
          // Clean up the temporary URL
          URL.revokeObjectURL(url);
        })
    }
  }

  return isLoaded ?
    (
      <div id="mobilityscore">
        { errors && errors.length > 0 ? <ErrorModal errors={errors} clearErrorMessages={clearErrorMessages} /> : null }
        { successMesage ? <SuccessModal message={successMesage} clearSuccessMessages={clearSuccessMessages} /> : null}
        <div className="mobilityscore_single_score">
          <h1>MobilityScore&reg;</h1>

          <h3>Look up a single MobilityScore</h3>
          <div className="instructions">
            <div>Enter an address below and you will be provided with a MobilityScore, fully formatted address, and a Score page URL. The URL enables you to share your MobilityScore with others along with a short description of the score and map reflecting all nearby transportation options. Tap any field to copy the data.</div>
          </div>
          <div className="pure-g">
            <div className="pure-u-1-2 mob_left_column">
              <div className="pure-g mob_margin_above_below">
                <div className="pure-u-1-5 mob_label">Address:</div>
                <div className="pure-u-4-5">
                  <Autocomplete onPlaceChanged={onPlaceChanged} onLoad={onLoad} options={options}>
                    <input name="address" type="text" label="Address" id="addressInput" placeholder="Specify an address"/>
                  </Autocomplete>
                </div>
              </div>
              {showSpinner
                ? <div id="spinner">
                  <Circles
                    height="70"
                    width="80"
                    color="#2386EE"
                    ariaLabel="circles-loading"
                    visible={true}
                  />
                </div>
                : <></>
              }
            </div>
            <div className="pure-u-1-2 mob_right_column">
              { getResultsSection() }
            </div>
          </div>
        </div>

        <div className="mobilityscore_pro">
          <h1>MobilityScore&reg; Pro</h1>
          <h3>Look up a collection of MobilityScores</h3>
          <div className="instructions">
            <div>You may choose to provide either complete addresses OR geo-coordinates. Simply download the appropriate CSV template, fill it out, return to this page, and upload the file. Only CSV files using our provided template may be uploaded.</div>
            <div>Once processing is complete you will find a link below to download the results.</div>
          </div>
          <div className="score_options">
            <div className="mob_label" style={{paddingRight: "1em" }}>Include:</div>
            { getScoreCheckboxes() }
          </div>

          <div className="mob_actions">
            <a className={`mob_button_secondary`}  href={addressTemplate} download={"mobscore_address_template"} target='_blank'>
                Download Address template
            </a>
            <a className={`mob_button_secondary`}  href={coordinatesTemplate} download={"mobscore_geocoordinates_template"} target='_blank'>
                Download Geocoordinate template
            </a>
            <a className={`mob_button_primary`} onClick={() => handleClick()}>
                Upload CSV file
            </a>
            <input
                type="file"
                onChange={handleChange}
                ref={hiddenFileInput}
                style={{display: 'none'}} // Make the file input element invisible
            />
          </div>
          <div>
            <h3>Your MobilityScore Pro results</h3>
            <div className="mob_downloads_error">{ downloadsError }</div>
            <div className="mob_table_container">
              <table className="mob_files">
                <thead>
                  <tr>
                    <th>File name</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Owner</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {getDownloadsRows()}
                </tbody>
              </table>
            </div>

            <div className="mob_actions">
              <a className={`mob_button_delete ${!selectedFile ? 'disabled' : ''}`} onClick={() => deleteSelectedFile()} >Delete selected</a>
              <a className={`mob_button_primary ${!selectedFile ? 'disabled' : ''}`} onClick={() => downloadSelectedFile()} target="_blank">Download selected</a>
            </div>
          </div>
        </div>
      </div>
   ) : <></>;
}

const SuccessModal = ({message, clearSuccessMessages}) => {
  return <div className="mob_success_modal">
    <div className="mob_message_container">
      <div className="mob_message">
        { message }
      </div>
      <div className="mob_actions">
        <button className="primary_button" onClick={() => clearSuccessMessages()}>Okay</button>
      </div>
    </div>
  </div>
}

const ErrorModal = ({errors, clearErrorMessages}) => {
  const getErrorMessages = (errors) => {
    let jsx = [];
    errors.forEach((error, index) => jsx.push(
      <div className="mob_error_message" key={`error-${index}`}>
        <div className="alert">!</div>
        <div>{error}</div>
      </div>
    ));

    return jsx
  }

  return <div className="mob_error_modal">
    <div className="mob_message_container">
      <div className="mob_message">
        { getErrorMessages(errors) }
      </div>
      <div className="mob_actions">
        <button className="primary_button" onClick={() => clearErrorMessages()}>Okay</button>
      </div>
    </div>
  </div>
}

export default MobilityScore
