import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { config } from '../../config';
import { useAuth0 } from "@auth0/auth0-react";
import { ReactSVG } from 'react-svg';
import logoutIcon from '../../images/icons/menu/logout.svg';

// Basic component with logout button
const LogoutButton = ({ lang, setModal }) => {
	const { logout } = useAuth0();
	const [cookies, setCookie, removeCookie] = useCookies([config.userCookieName]);
	
	return (
		<div className="Logout">
			<a onClick={() => logout({logoutParams: {returnTo: window.location.origin}})} className="logout-link pure-u-1-1 pure-u-md-1-1 menu-option nav-container">
				<div className="icon pure-u-1-6 pure-u-md-1-6"> <ReactSVG src={logoutIcon} /> </div>
				<div className="pure-u-5-6 pure-u-md-5-6">{lang['but5']}</div>
			</a>
		</div>
	);
};

export default LogoutButton;
