import platform from 'platform';
const axios = require('axios');

const constants = {
	CITYMO_URL_PARAMS: [
		"coordinates",
		"allowedModes",
		"lang",
		"ui"
	],
	WEEKDAYS: {
		'mon': 'Monday',
		'tue': 'Tuesday',
		'wed': 'Wednesday (default)',
		'thu': 'Thursday',
		'fri': 'Friday',
		'sat': 'Saturday',
		'sun': 'Sunday'
	}
};

const BIZ_BASE_URL = process.env.REACT_APP_BIZ_BASE_URL || 'https://ts-business-core-staging.herokuapp.com';
console.log(BIZ_BASE_URL); // for dev purposes. please do not remove yet

async function getUnseenEvents(accessToken, aud) {
	// if the accessToken is still valid, this post req will return the events not yet seen by the loggedin user
    return await axios.post(`${BIZ_BASE_URL}/api/auth/events`, {
        accessToken: `${accessToken}`,
        aud: `${aud}`
    })
};

async function getCustomerData(customerId) {
	try{
		const url = `${BIZ_BASE_URL}/api/customers/${customerId}`;
		let customerData;
		await axios(url).then(data => {
			customerData = data
		});
		return customerData.data;
	}
	catch(e) {
		console.log(e)
	}
};

async function updateCustomerData(customer) {

	const url = `${BIZ_BASE_URL}/api/customers/${customer.id}`;
	return await axios.put(url, {
		id: customer.id,
		name: customer.name,
		address1: customer.address1,
		address2: customer.address2,
		city: customer.city,
		phone: customer.phone,
		state: customer.state,
		website: customer.website,
		zip: customer.zip,
		// customerLogo: customer.customerLogo
	});
};

async function postUnseenEventsToOkta(token, eventsArray, userId) {

	try {
		fetch(`https://dev-9116264.okta.com/api/v1/users/${userId}`, {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': `SSWS ${token}`,
				'Access-Control-Allow-Origin': 'http://localhost:3000',
				},
			body: JSON.stringify({
				"profile": { "seen_events": eventsArray }
			})
		})

		.then(response => { console.log(response); return response; })
	} catch(e) {
		console.error('something went wrong', e);
	}
};

function cacheSeenEvents( eventsToBeStored, shouldPost, userId ) {
	if (!eventsToBeStored || eventsToBeStored === undefined) { return; } //if there's no data to be stored. return
	let seenEventsToBeCached = [];
	// temporary function to solve okta not updating seen_events
	if (!localStorage.getItem('seenEvents')) {
		localStorage.setItem('seenEvents', JSON.stringify(eventsToBeStored));
	} else {
		const storedEvents = JSON.parse(localStorage.getItem('seenEvents')); //array of seen events ids
		const filteredSeenEvents = eventsToBeStored.filter( el => !storedEvents.includes(el)); // filter array to prevent caching duplicates

		if (filteredSeenEvents.length) {
			seenEventsToBeCached = [...storedEvents, ...filteredSeenEvents]; //makes it into one array if there's a new id in the array
			localStorage.setItem('seenEvents', JSON.stringify(seenEventsToBeCached));
		}
		if (shouldPost && seenEventsToBeCached.length) {
			postUnseenEventsToOkta('00eKv95QDJKg9b1OauMDo33rsZc5RkEbBXagzx1ko9', seenEventsToBeCached, userId);
		}
	}
};

async function getTableauWorkbooks(customerId, topic) {
	try {
		const url = `${BIZ_BASE_URL}/api/TableauWorkbooks/${customerId}/${topic}`;
		let workbooks;
		await axios(url).then(data => {
			workbooks = data
		});
		return workbooks.data;
	}
	catch(e) {
		console.log(e)
	}
};

function removeDuplicateAddress(addresses) {
	const addressArray = addresses.split('\n').map(element => element.trim());
	const filteredAddresses = addressArray.filter((item, index) => {
		//Specifically removing duplicate zip codes only
		if (item.length === 5) {
			return addressArray.indexOf(item) === index;
		}
		return item;
	});
	return filteredAddresses
}

async function getToken() {
	const clientId = process.env.REACT_APP_OKTA_CLIENT_ID;
	const clientSecret = process.env.REACT_APP_OKTA_CLIENT_SECRET;
	const base64Credentials = btoa(clientId + ':' + clientSecret);

	const requestedScopes = encodeURIComponent('data-drop');

	const res = await fetch(
		process.env.REACT_APP_API_GATEWAY_TOKEN_URL,
		{
			method: 'POST',
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
				"Authorization": "Basic " + base64Credentials
			},
			body: 'grant_type=client_credentials&scope=' + requestedScopes
		}
	);

	if (res.ok) {
		const tokenWrapper = await res.json();
		return tokenWrapper;
	}
	return
}

async function sendDataToJourneyBatch(data, reportId) {
	try {
		const formatTime = (time) => {
			let today = new Date();
			today.setHours(time === '9:00am' ? 9 : 17);
			today.setMinutes(0);
			today.setSeconds(0);
			const date = new Date(new Date(today.getTime() - today.getTimezoneOffset() * 60000).toISOString().slice(0, -5));
			const year = date.getFullYear();
			const month = date.getMonth() + 1; // JavaScript months are 0-indexed
			const day = date.getDate();
			const hours = date.getHours();
			const minutes = date.getMinutes();
			const seconds = date.getSeconds();

			// Format the date as a string
			const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
			return formattedDate
		}

		const selectedDays = {
			sunday: data.selectedDay.value === 'sun',
			monday: data.selectedDay.value === 'mon',
			tuesday: data.selectedDay.value === 'tue',
			wednesday: data.selectedDay.value === 'wed',
			thursday: data.selectedDay.value === 'thu',
			friday: data.selectedDay.value === 'fri',
			saturday: data.selectedDay.value === 'sat',
		};
		
		const offices = data.addresses.map((address, index) => {
			if (address.address == '') return;
			return {
				place: address.zip,
				address: address.address,
				coordinates: {
					latitude: address.latitude,
					longitude: address.longitude
				},
				county: address.county,
				label: data.addressLabels[index],
				isExist: index == 0,

			}
		}).filter(n => n);

		const people = data.employeeAddresses.map(address => {
			return {
				place: address.zip,
				address: address.address,
				coordinates: {
					latitude: address.latitude,
					longitude: address.longitude
				},
				county: address.county,
				id: address.personId,
				tractId: address?.tractId
			}
		})		

		// console.log({
		// 	appName: 'Dash2',
		// 	apiKey: process.env.REACT_APP_JOURNEY_API_KEY,
		// 	emailAddress: data.email,
		// 	reportLabel: data.reportName,
		// 	reportId: reportId,
		// 	organization: data.organizationName,
		// 	organizationId: data.organizationId,
		// 	// addresses: people,
		// 	people: people,		
		// 	offices: offices,
		// 	arriveBy: data.isFromOffice.value === 'homeToOffice' ? formatTime(data.travelTime.value) : null,
		// 	departBy: data.isFromOffice.value === 'officeToHome' ? formatTime(data.travelTime.value) : null,
		// 	isFromOffice: data.isFromOffice.value === 'homeToOffice' ? false : true,
		// 	filters: {
		// 		analyticsMode: true
		// 	},
		// 	daysOfWeekCommuting: selectedDays,	
		// })

		const res = await axios.post(process.env.REACT_APP_JOURNEY_BATCH_URL, {
			appName: 'Dash2',
			apiKey: process.env.REACT_APP_JOURNEY_API_KEY,
			emailAddress: data.email,
			reportLabel: data.reportName,
			reportId: reportId,
			organization: data.organizationName,
			organizationId: data.organizationId,
			// addresses: people,
			people: people,		
			offices: offices,
			arriveBy: data.isFromOffice.value === 'homeToOffice' ? formatTime(data.travelTime.value) : null,
			departBy: data.isFromOffice.value === 'officeToHome' ? formatTime(data.travelTime.value) : null,
			isFromOffice: data.isFromOffice.value === 'homeToOffice' ? false : true,
			filters: {
				analyticsMode: true
			},
			daysOfWeekCommuting: selectedDays,			
		})
		.then((response) => response)
		//Filtering to make sure no null value exists
		.then((data) => data.data)
		.catch((error) => {
			console.error('Something went wrong:', error);
		});
		
		return res;
		// return {
		// 	data: {
		// 		position: 1,
		// 		estimatedTime: 1000
		// 	}
		// }
	}
	catch(e) {
		console.log(e)
	}
}

async function postToDataDrop(data, type, existingToken, tokenExpirationTime, result, changeState) {
	// Data Drop
	if (process.env.REACT_APP_SEND_TO_DATADROP === 'true') {
		//Gateway token retrieve
		let token = existingToken;
		//Expired token or first activation
		if (new Date() > tokenExpirationTime) {
			const tokenObject = await getToken();
			token = tokenObject.access_token;
			changeState(['accessToken', 'tokenExpirationTime', 'estimatedTime', 'queuePosition'], [token, new Date(Date.now() + tokenObject.expires_in * 1000), result.data.estimatedTime, result.data.position]);
		}

		const res = await fetch(process.env.REACT_APP_DATADROP_URL, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				"Authorization": "Bearer " + token
			},
			body: JSON.stringify({
				source: 'batch-frontend',
				version: '1',
				type: type,
				metadata: {
					url: window.location.href,
					appName: 'Batch-Frontend',
					appVersion: 1,
					browser: platform.name,
					browserVersion: platform.version,
					os: platform.os.family,
					osVersion: platform.os.version,
					device: platform.product,
					isoDate: new Date().toISOString(),					
				},
				data: {
					date: new Date().toISOString(),
					accessKey: data.apiKey,
					processingTime: data.processingTime,
					email: data.email,
					reportName: data.reportName
				}
			})
		})
		.then((response) => response.json())
		.catch((error) => {
			console.error('Something went wrong:', error);
		});
		return res;
	};
}

//Calculate distance from origin point
function getDistanceFromLatLon(location1, location2) {
	if (!location1 || !location2) {
		return 0;
	}
	const R = 3958.8; // Radius of the earth in mi
	const dLat = deg2rad(location1.latitude-location2.latitude);  
	const dLon = deg2rad(location1.longitude-location2.longitude); 
	const a = 
	  Math.sin(dLat/2) * Math.sin(dLat/2) +
	  Math.cos(deg2rad(location2.latitude)) * Math.cos(deg2rad(location1.latitude)) * 
	  Math.sin(dLon/2) * Math.sin(dLon/2)
	  ; 
	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
	const d = R * c; 
	return d;
}
  
function deg2rad(deg) {
	return deg * (Math.PI/180)
}

function formatSecondsToHours(value) {
	const hour = Math.floor(value / 3600);
	const minutes = Math.floor(value % 3600 / 60);
	const seconds = value % 60;
	return `${hour}h ${minutes}m ${seconds}s`
}

async function uploadCsvToS3(file) {
	const awsUrl = fetch(`${BIZ_BASE_URL}/api/reports/createAwsUrl/${file.name}`)
	.then(resp => resp.json())
	.then(url => {
		// Make a PUT request to the pre-signed URL (from BIZ step) with the file contents
		fetch(url, {
			method: 'PUT',
			body: file,
			headers: {
			'Content-Type': 'text/plain;charset=UTF-8'
			}
		})
		.then(response => {
			if (response.ok) {
			console.log('File uploaded successfully:', response.status);
			} else {
			throw new Error('Failed to upload file: ' + response.statusText);
			}
		})
		.catch(error => {
			console.error('Error uploading file:', error.message);
		});
	})
	return

}

async function createReport(state, userInputs, reportId, reportName, estimatedTime, createdBy) {
	const currentDateTime = new Date();
	currentDateTime.setSeconds(currentDateTime.getSeconds() + estimatedTime);
	const report = {
		id: reportId,
		customerId: state.organizationId,
		name: reportName,
		status: 'processing',
		addressLabels: state.addressLabels, 
		createdBy,
		estimatedCompletionDate: currentDateTime,
		isActive: true,
		userInputs: {
			addressLabels: state.addressLabels,
			addresses: state.addresses,
			email: state.email,
			employeeAddresses: state.employeeAddresses,
			fileName: state.uploadedFile.name,
			isFromOffice: state.isFromOffice,
			reportName: state.reportName,
			selectedDay: state.selectedDay,
			travelTime: state.travelTime
		}
	}

	//If existing file, don't re-upload to S3
	if (!userInputs) {
		await uploadCsvToS3(state.uploadedFile);
	}	
	
	try {
		const data = fetch(`${BIZ_BASE_URL}/api/reports/createReport`, {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(report)
		})
		.then(response => response)
	} catch(e) {
		console.error('something went wrong', e);
	}
};

const findGmapsCounty = (data) => {
    const potential_counties = data.filter(item => {
        if (item.types.includes('administrative_area_level_1') || item.types.includes('administrative_area_level_2') || item.types.includes('administrative_area_level_3')) {
            return true;
        }
        return false;
    })

    return potential_counties.find(county => county.types[0] === 'administrative_area_level_3')
    || potential_counties.find(county => county.types[0] === 'administrative_area_level_2')
    || potential_counties.find(county => county.types[0] === 'administrative_area_level_1')
}

const findGmapsCountry = (data) => {
    const country = data.filter(item => {
        if (item.types.includes('country')) {
            return true;
        }
        return false;
    })

    return country[0];
}

const formatDateDigits = (num) => {
    return num < 10 ? `0${num}` : num
}

const formatDate = (dateTime) => {
    const date = dateTime ? new Date(dateTime) : null
    return date
    ? `${date.getFullYear()}-${formatDateDigits(date.getMonth() + 1)}-${formatDateDigits(date.getDate())}`
    : null
}

const calculateDateDifference = (date1, date2) => {
	let Difference_In_Time = date1.getTime() - date2.getTime();

	// Calculating the no. of days between two dates
	return Math.abs(Math.round(Difference_In_Time / (1000 * 3600 * 24)));
}

const getCustomers = async (customerId) => {
    const url = `${process.env.REACT_APP_BIZ_BASE_URL}/api/customers`;
    return await fetch(url)
    .then(response => response.json())
    .then(data => {
        data.forEach(customer => {
            delete customer.Instances;
        });
        return data
    })
    .catch((error) => {
        console.error('Something went wrong:', error);
    });
}

export {
	constants,
	getUnseenEvents,
	cacheSeenEvents,
	getCustomerData,
	updateCustomerData,
	getTableauWorkbooks,
	removeDuplicateAddress,
	getToken,
	sendDataToJourneyBatch,
	postToDataDrop,
	getDistanceFromLatLon,
	formatSecondsToHours,
	createReport,
	findGmapsCounty,
	findGmapsCountry,
	formatDate,
	calculateDateDifference,
	getCustomers,
}
