import React, {
    useState,
    useEffect
} from 'react';
import Select, { components } from 'react-select';
import { getTimezone, getContentPlaylists } from './utils';
import pauseIcon from '../../images/icons/screens/pause.svg';
import transitBlackIcon from '../../images/icons/screens/transit-black.svg';
import playlistIcon from '../../images/icons/screens/playlist.svg';
import globeIcon from '../../images/icons/screens/globe.svg';
import landscapeIcon from '../../images/icons/screens/landscape.svg';
import portraitLeftIcon from '../../images/icons/screens/portrait-left.svg';
import portraitRightIcon from '../../images/icons/screens/portrait-right.svg';
import oneViewIcon from '../../images/icons/screens/one-view.svg';
import dropdownOptions from './dropdownOptions.json';

import MapWrapper from './MapWrapper';

const ScreenConfiguration = ({
    state,
    setState,
    handleInputChange,
    handleMultiInputChanges,
    customerId,
    selectedProduct,
    customer,
    locations,
    oneViewOptions
}) => {

    const playlist = {...state?.playlist};

    const [contentPlaylists, setContentPlaylists] = useState([]);

    const [locationOptions, setLocationOptions] = useState([]);

    /* Render the little icons in the Orientation and One View (default) dropdowns */
    // https://stackoverflow.com/a/60965985/29499558
    const { Option, SingleValue } = components;
    let defaultOptionStyle = {display: "flex", flexDirection: "row", alignItems: "center" };
    let imgStyle = { width: 20, marginRight: "0.5em" };
    const OrientationIconOption = props => (
        <Option {...props}>
            <div style={defaultOptionStyle}>
                <img
                    src={require('../../images/icons/screens/' + props.data.icon).default}
                    style={imgStyle}
                    alt={props.data.label}
                />
                {props.data.label}
            </div>
        </Option>
    );

    // Had to use ChatGPT to find this one out, so no link
    // This will style the default option.
    // The default option is still determined by the <Select> defaultValue attribute.
    const DefaultSingleValue = props => (
        <SingleValue {...props}>
            <div style={defaultOptionStyle}>
                <img
                  src={require('../../images/icons/screens/' + props.data.icon).default}
                  style={imgStyle}
                  alt={props.data.label}
                />
                {props.data.label}
            </div>
        </SingleValue>
    )

    // Default value of the One View dropdown is based on what the customer has purchased.
    let defaultOption = oneViewOptions.find((option) => !option.isDisabled);

    // Render the One View dropdown icons
    const OneViewIconOption = props => {
        let optionProps = {...props};
        let optionImgStyle = {...imgStyle};
        // Check the customer purchases to see if the One View dropdown option should be enabled or disabled.
        if (!customer.enabledDash2Navs.includes(props.data.requirement)) {
            // The component will modify the label when option is disabled.
            optionProps["isDisabled"] = true;
            // But we must modify the img tag ourselves.
            optionImgStyle["opacity"] = 0.35;
        }

        let oneViewOption = <Option {...optionProps}>
            <div style={defaultOptionStyle}>
                <img
                    src={require('../../images/icons/screens/' + props.data.icon).default}
                    style={optionImgStyle}
                    alt={props.data.label}
                />
                {props.data.label}
            </div>
        </Option>

        return oneViewOption;
    };
    /* End Render */

    /* React-Select custom styling */
    const basicConfigDropdownStyling = {
        container: (baseStyles, state) => ({
          ...baseStyles,
          // Too much gray if the dropdown is disabled.
          boxShadow: state.isDisabled ? "unset" : "0px 2px 8px 4px rgba(0, 0, 0, 0.09)",
          borderRadius: "8px"
        }),
        control: (baseStyles, state) => ({
            ...baseStyles,
            border: "none",
            cursor: "pointer"
        }),
        indicatorSeparator: (baseStyles, state) => ({
            ...baseStyles,
            display: "none"
        }),
        option: (baseStyles, state) => ({
            ...baseStyles,
            color: state.isDisabled ? "#919191" : "inherit",
            cursor: "pointer"
        })
    };

    useEffect(() => {
        getContentPlaylists(customerId)
        .then(data => {
            setContentPlaylists(data)
        })
    }, []);

    useEffect(() => {
        let tempLocations = [];
        locations?.forEach(location => {
            tempLocations.push({label: location.name, value: location.id});
        })
        setLocationOptions(tempLocations);
    }, [locations]);

    const handleAddressSelect = async (address) => {
        await getTimezone( address.latitude, address.longitude)
        .then(timezone => {
            playlist.address = address.address;
            playlist.address1 = address.name;
            playlist.city = address.city;
            playlist.country = address.country;
            playlist.latitude = address.latitude;
            playlist.longitude = address.longitude;
            playlist.state = address.county;
            playlist.timezone = timezone;
            playlist.zip = address.zip;
            setState({
                ...state,
                playlist: playlist
            });
        });
    }

    const handleRadioSelect = (attribute, radioValue) => {
        if (attribute === 'screenType') {
            if (radioValue === 'oneView') {
                handleMultiInputChanges(['screenType', 'contentShortCode', 'otherSlideUrl'], [defaultOption?.value, null, null]);
            }
            //Transportation radio button clicked
            else if (radioValue === 'standby') {
                handleMultiInputChanges(['screenType', 'contentShortCode', 'otherSlideUrl'], [radioValue, null, null]);
            }
            else if (radioValue === 'contentPlaylist') {
                handleMultiInputChanges(['screenType', 'otherSlideUrl'], [radioValue, null]);
            }
            else if (radioValue === 'other') {
                handleMultiInputChanges(['screenType', 'contentShortCode'], [radioValue, null]);
            }
        } else if (attribute === 'messagingShortCode') {
            handleMultiInputChanges(['messagingShortCode'], [radioValue]);
        }
    }

    const inScreenMessagingEnabled = () => {
        var enabled = false;
        if (customer?.enabledDash2Navs.includes("playlists") &&
            customer.enabledDash2Navs.includes("messaging") &&
            selectedProduct === 'screen_pro') {
            enabled = true;
        }

        return enabled;
    }

    let validOneViewValues = [];
    oneViewOptions.forEach((option) => {
        // If disabled, the required feature wasn't purchased.
        if (!option.isDisabled) {
            validOneViewValues.push(option.value);
        }
    });

    const isStandbyByDefault = () => {
        let otherOptionsSelected = playlist?.screenType && ['contentPlaylist', 'other'].includes(playlist?.screenType);
        return (validOneViewValues.length === 0 && !otherOptionsSelected);
    }

    return (
        <div className="ScreenConfigurationPage">
            <div className="ScreenConfiguration">
                <h2>Basic configuration</h2>
                <div className="basic-configuration">
                    <div className="left-col">
                        <h3>Primary use</h3>
                        <div className="description">Ensure your content is formatted properly by indicating where you plan to display it. If showing your content on a mounted TV or similar device, you will also need to set the orientation.</div>
                        <div>
                            <div className="device-option">
                                <div className="device-option-radio-button">
                                    <input
                                        type="radio"
                                        name="device"
                                        value="screen"
                                        onChange={(e) => handleInputChange('deviceType', e.target.value)}
                                        disabled={!playlist?.createdAt}
                                        checked={playlist?.deviceType === "screen"}
                                    />
                                </div>
                                <div className="device-option__details">
                                    <div className="proxima-bold">Actionfigure&reg; Screen</div>
                                    <div className="device-option__description">Generally these are fixed displays (wall mounted TVs, projectors, and large format displays). Because they are fixed, you will need to indicate the orientation of your Screen.</div>
                                    <div className="screen-orientation">
                                        <div className="device-option__label__orientation">Orientation:</div>
                                        <div style={{width:"80%"}} className={!playlist?.createdAt || playlist?.deviceType !== "screen" ? "disabled-section" : ""}>
                                            <Select
                                                onChange={(e) => handleInputChange('rotation', e.value)}
                                                defaultValue={dropdownOptions.SCREEN_ORIENTATION[0]}
                                                options={dropdownOptions.SCREEN_ORIENTATION}
                                                value={dropdownOptions.SCREEN_ORIENTATION.find(option => option.value === playlist.rotation)}
                                                components={{ Option: OrientationIconOption, SingleValue: DefaultSingleValue }}
                                                styles={basicConfigDropdownStyling}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="device-option">
                                <div className="device-option-radio-button">
                                    <input
                                        type="radio"
                                        name="device"
                                        value="mobile"
                                        onChange={(e) => handleInputChange('deviceType', e.target.value)}
                                        disabled={!playlist?.createdAt}
                                        checked={playlist?.deviceType === "mobile"}
                                    />
                                </div>
                                <div className="device-option__details">
                                    <div className="proxima-bold">Actionfigure&reg; Mobile</div>
                                    <div className="device-option__description">Used primarily for distribution on mobile devices such as phones and tablets.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="right-col">
                        <h3>Basic settings</h3>
                        <div className="form-and-map">
                            <div className="basic-settings-form">
                                <div className="description">Ensure your content is formatted properly by choosing the primary device(s) where you plan to display it. </div>
                                <div className="input-container">
                                    <div className="basic-settings__label">Name:</div>
                                    <div className="basic-settings__value">
                                        <input
                                            type="text"
                                            className="input-field"
                                            value={playlist.label || ''}
                                            onChange={(e) => handleInputChange('label', e.target.value)} disabled={!playlist?.customerId}
                                            disabled={!playlist?.createdAt}
                                        />
                                    </div>
                                </div>
                                <div className="input-container">
                                    <div className="basic-settings__label">Location:</div>
                                    <div className="basic-settings__value">
                                        <Select
                                            onChange={(option) => handleInputChange('locationId', option.value)}
                                            options={locationOptions}
                                            value={ locationOptions.find(location => location.value === playlist?.locationId) }
                                            className={"switch-field"}
                                            styles={basicConfigDropdownStyling}
                                            isDisabled={!playlist?.customerId}
                                        />
                                    </div>
                                </div>
                                <div className="input-container">
                                    <div className="basic-settings__label">Language:</div>
                                    <div className="basic-settings__value">
                                        <Select
                                            onChange={(option) => handleInputChange('language', option.value)}
                                            options={ dropdownOptions.LANGUAGE_OPTIONS }
                                            value={ dropdownOptions.LANGUAGE_OPTIONS.find(lang => lang.value === playlist?.language) }
                                            className={"switch-field"}
                                            styles={basicConfigDropdownStyling}
                                            isDisabled={!playlist?.customerId}
                                        />
                                    </div>
                                </div>
                                <div className="input-container">
                                    <div className="basic-settings__label">Measurements:</div>
                                    <div className="basic-settings__value">
                                        <Select
                                            onChange={(option) => handleInputChange('measurement', option.value)}
                                            options={dropdownOptions.MEASUREMENTS}
                                            defaultValue={dropdownOptions.MEASUREMENTS[0]}
                                            value={dropdownOptions.MEASUREMENTS.find((option) => option.value === playlist?.measurement)}
                                            styles={basicConfigDropdownStyling}
                                            isDisabled={!playlist?.customerId}
                                        />
                                    </div>
                                </div>
                                <div className="input-container">
                                    <div className="basic-settings__label">{/* Placeholder */}</div>
                                    <div className="basic-settings__value support-blurb">
                                        Need to add, remove, or update a location? <br/> Contact support@actionfigure.ai
                                    </div>
                                </div>
                            </div>
                            <div className="map-container">
                                <MapWrapper state={state} setState={setState} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ScreenTypeConfiguration">
                <div className="content-management-settings">
                    <div className="cm-setting">
                        <h2 className="proxima-bold">Full-Screen content management</h2>
                        <h3>What people should be seeing</h3>
                        <p>Specify what content you would like to be showing full-screen. Be sure to save any changes.</p>
                        <div className="screen-type-option-wrapper">
                            <div className="screen-type-container">
                                <div className="radio-button">
                                    <input
                                        type="radio"
                                        checked={validOneViewValues.includes(playlist?.screenType)}
                                        onChange={() => handleRadioSelect('screenType', 'oneView')}
                                        disabled={!state.playlist?.customerId || validOneViewValues.length === 0}
                                    />
                                </div>
                                <div className="screen-type-icon">
                                    <img src={oneViewIcon} />
                                </div>
                                <div className="radio-label">
                                    <div className="label-and-dropdown">
                                        <div className="label"> One view: (default) </div>
                                        <div className="screen-type-dropdown">
                                            <Select
                                                onChange={(e) => handleInputChange('screenType', e.value)}
                                                defaultValue={defaultOption}
                                                options={oneViewOptions}
                                                value={dropdownOptions.VIEWS.find(option => !option.isDisabled && option.value === playlist.screenType)}
                                                components={{ Option: OneViewIconOption, SingleValue: DefaultSingleValue }}
                                                styles={basicConfigDropdownStyling}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="screen-type-description">Present only the contents of one view you specify.</div>
                        </div>
                        <div className="screen-type-option-wrapper">
                            <div className={`screen-type-container ${!customer?.enabledDash2Navs.includes("playlists") ? "disabled-label" : ''}` }>
                                <div className="radio-button">
                                    <input type="radio" checked={playlist.screenType === 'contentPlaylist'} onChange={() => handleRadioSelect('screenType', 'contentPlaylist')} disabled={!state.playlist?.customerId || !customer?.enabledDash2Navs.includes("playlists")} />
                                </div>
                                <div  className="screen-type-icon">
                                    <img className={!customer?.enabledDash2Navs.includes("playlists") ? 'disabled': ''} src={playlistIcon} />
                                </div>
                                <div className="radio-label">
                                    <div className="label-and-dropdown">
                                        <div className="label">Playlist:</div>
                                        <div className="screen-type-dropdown">
                                            <Select
                                                onChange={(option) => handleMultiInputChanges(['screenType', 'contentShortCode', 'otherSlideUrl'], ['contentPlaylist', option.value, null])}
                                                options={contentPlaylists.map(pl => {
                                                    return {
                                                        label: pl.label,
                                                        value: pl.shortCode
                                                    }
                                                })}
                                                value={contentPlaylists.find(pl => pl.shortCode === playlist.contentShortCode)}
                                                styles={basicConfigDropdownStyling}
                                                menuShouldScrollIntoView={false}
                                                isDisabled={!state.playlist?.customerId || !customer?.enabledDash2Navs.includes("playlists")}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="screen-type-description">Choose from any of your playlists.</div>
                        </div>
                        <div className="screen-type-option-wrapper">
                            <div className="screen-type-container">
                                <div className="radio-button">
                                    <input type="radio" checked={playlist.screenType === 'other'} onChange={() => handleRadioSelect('screenType', 'other')} disabled={!state.playlist?.customerId} />
                                </div>
                                <div  className="screen-type-icon"><img src={globeIcon} /></div>
                                <div className="radio-label">
                                    <div className="label-and-dropdown">
                                        <div className="label">Website:</div>
                                        <div className="screen-type-input">
                                            <input
                                                type="text"
                                                style={{background: '#fff', width: '100%'}}
                                                value={playlist?.otherSlideUrl || ''}
                                                onChange={(e) => handleMultiInputChanges(['screenType', 'otherSlideUrl', 'contentShortCode'], ['other', e.target.value, null])}
                                                className="input-field"
                                                placeholder='https://...'
                                                disabled={!state.playlist?.customerId}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="screen-type-description">Show the contents of a 3rd party URL. Note: Some 3rd party websites will not allow their websites to be shown in this manner.</div>
                        </div>
                        <div className="screen-type-option-wrapper">
                            <div className="screen-type-container">
                                <div className="radio-button">
                                    <input type="radio" checked={isStandbyByDefault() || playlist.screenType === 'standby'} onChange={() => handleRadioSelect('screenType', 'standby')} disabled={!state.playlist?.customerId} />
                                </div>
                                <div className="screen-type-icon"><img src={pauseIcon} /></div>
                                <div className="radio-label">
                                    <div className="label-and-dropdown">
                                        <div className="label">Standby</div>
                                    </div>
                                </div>
                            </div>
                            <div className="screen-type-description">Turns off all content and shows a "Content not available" message.</div>
                        </div>
                    </div>
                    <div className="cm-setting">
                        <h2>In-Screen content management</h2>
                        <h3>Messaging</h3>
                        <p>For Screen Pro customers with In-Screen Messaging, please specify what playlist should be shown on this Screen.</p>
                        <div className="screen-type-option-wrapper">
                            <div className="screen-type-container">
                                <div className="radio-button">
                                    <input type="radio" checked={playlist.messagingShortCode === null} onChange={() => handleRadioSelect('messagingShortCode', null)} disabled={!inScreenMessagingEnabled()} />
                                </div>
                                <div className="screen-type-icon"><img className={!inScreenMessagingEnabled() ? "disabled" : ''} src={pauseIcon} /></div>
                                <div className={`radio-label ${!inScreenMessagingEnabled() ? "disabled-label": ''}`}>
                                    <div className="label-and-dropdown">
                                        <div className="label">Standby (default)</div>
                                    </div>
                                </div>
                            </div>
                            <div className="screen-type-description">
                                Turns off all content and shows a "Content not available" message.
                            </div>
                        </div>
                        <div className="screen-type-option-wrapper">
                            <div className="screen-type-container">
                                <div className="radio-button">
                                    <input type="radio" checked={playlist.messagingShortCode !== null && selectedProduct === 'screen_pro'} onChange={() => handleRadioSelect('messagingShortCode', '')} disabled={!inScreenMessagingEnabled() ? "disabled" : ''} />
                                </div>
                                <div  className="screen-type-icon"><img className={!inScreenMessagingEnabled() ? "disabled" : ''} src={playlistIcon} /></div>
                                <div className={`radio-label ${!inScreenMessagingEnabled() ? "disabled-label": ''}`}>
                                    <div className="label-and-dropdown">
                                        <div className="label">Playlist:</div>
                                        <div className="screen-type-dropdown">
                                            <Select
                                                onChange={(option) => handleMultiInputChanges(['messagingShortCode'], [option.value])}
                                                options={contentPlaylists.map(pl => {
                                                    return {
                                                        label: pl.label,
                                                        value: pl.shortCode
                                                    }
                                                })}
                                                className="playlist-dropdown"
                                                value={contentPlaylists.find(pl => pl.shortCode === playlist.messagingShortCode)}
                                                styles={basicConfigDropdownStyling}
                                                menuShouldScrollIntoView={false}
                                                isDisabled={!inScreenMessagingEnabled()}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="screen-type-description">Present only the contents of one view you specify.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ScreenConfiguration;
