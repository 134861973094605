import { useState, useEffect } from 'react';
import Select from 'react-select';

import { getBasePlaylists, savePlaylist } from './utils';
import { formatDate, calculateDateDifference, getCustomers } from '../../utils';
import constants from './constants.json';
import { ReactSVG } from 'react-svg';
import ScreenConfiguration from './ScreenConfiguration';
import TransportationContent from './TransportationContent';
import ScreenProTransportationContent from './ScreenProTransportationContent';
import screenFeatures from './screenFeatures.json';

import buildingIcon from '../../images/icons/screens/building.svg';
import coffeeIcon from '../../images/icons/screens/coffee.svg';
import eyeIcon from '../../images/icons/screens/eye.svg';
import gearIcon from '../../images/icons/menu/gear.svg';
import keyIcon from '../../images/icons/key.svg';
import logo from '../../images/logos/af-logo.svg';
import openReport from '../../images/icons/analytics/open-report.svg';
import paperPlaneIcon from '../../images/icons/screens/paper-plane.svg';
import plusIcon from '../../images/icons/screens/white-plus.svg';
import returnIcon from '../../images/icons/screens/return.svg';
import saveIcon from '../../images/icons/screens/save.svg';
import transitIcon from '../../images/icons/screens/transit.svg';
import tripPlannerIcon from '../../images/icons/screens/trip-planner.svg';

const icons = {
	transitIcon,
	coffeeIcon,
	buildingIcon,
	paperPlaneIcon,
	tripPlannerIcon
};

const ScreenPage = ({
	lang,
	userData,
	access,
	customer,
	adminMenu
}) => {

    const [state, setState] = useState({
        playlist: {
			language: 'en',
			rotation: 0
		},
		transportationConfigs: constants.DEFAULT_TRANSPORTATION_CONFIGS
    })
	const [savedState, setSavedState] = useState({
        playlist: {
			language: 'en',
			rotation: 0
		},
		transportationConfigs: constants.DEFAULT_TRANSPORTATION_CONFIGS
    })
	const [stateChanged, setStateChanged] = useState(false);
    const [selectedTab, setSelectedTab] = useState('screen-config');
	const [playlists, setPlaylists] = useState({});
	const [showNewScreenModal, setShowNewScreenModal] = useState(false);
	const [showAdminCreateModal, setShowAdminCreateModal] = useState(false);
	const [showOpenScreenModal, setShowOpenScreenModal] = useState(false);
	const [showPlayModal, setShowPlayModal] = useState(false);
	const [showLicenseModal, setShowLicenseModal] = useState(false);
	const [statusMessage, setStatusMessage] = useState({
		message: null,
		error: false
	});
	const [showUndoConfirmModal, setShowUndoConfirmModal] = useState(false);

	const getPlaylists = async () => {
		return await getBasePlaylists(userData.customerId);
	}

	useEffect(() => {
		getPlaylists(userData.customerId)
		.then(data => {
			setPlaylists(data)
			//If only one playlist, then set that one playlist as the selected one from the start
			if (data.length === 1) {
				const playlist = data[0];
				let transportationConfigs = {...constants.DEFAULT_TRANSPORTATION_CONFIGS};

				if (playlist?.url) {
					const urlObject = new URLSearchParams(playlist?.url.split('?')[1]);
					// Update current state to BIZ values
					for (const [key, value] of urlObject.entries()) {
						//Set boolean types first
						if (value === 'true' ) transportationConfigs[key] = true;
						else if (value === 'false' ) transportationConfigs[key] = false;
						else transportationConfigs[key] = value;
					}
				}				

				// Initial saving of state
				setState({
					playlist: playlist,
					transportationConfigs: transportationConfigs
				});
			}
		});
	}, [])

	const handleInputChange = (property, value) => {
		const tempPlaylist = {...state.playlist};
        tempPlaylist[property] = value;
        setState({
            ...state,
            playlist: tempPlaylist
        });
		setStateChanged(true);
    }

	const handleColorChange = (property, value) => {
		const tempConfigs = {...state.transportationConfigs};	
		if (value !== '') {
			tempConfigs[property] = value.includes('#') ? value : `#${value}`;
		}
		else {
			tempConfigs[property] = '';
		}

		setState({
            ...state,
            transportationConfigs: tempConfigs
        });
		setStateChanged(true);
    }

	//For transportation configs
	const handleConfigChange = (property, value) => {
		const tempConfigs = {...state.transportationConfigs};
        tempConfigs[property] = value;
        setState({
            ...state,
            transportationConfigs: tempConfigs
        });
		setStateChanged(true);
    }

	const handleMultiInputChanges = (propNames, values) => {
		const tempPlaylist = {...state.playlist};        
		
		propNames.forEach((item, index) => {
		  tempPlaylist[item] = values[index];
		});

		setState({
            ...state,
            playlist: tempPlaylist
        });
		setStateChanged(true);
	}

	const handleMultiConfigChange = (property, value) => {
		const tempConfigs = {...state.transportationConfigs};

		//New value and proeprty
		if (tempConfigs[property] === undefined) {
			tempConfigs[property] = value;
		}
		//Remove from property
		else if (tempConfigs[property].includes(value)) {
			const removeIndex =  tempConfigs[property].indexOf(value);
			if (removeIndex === 0) {
				//Cut the first character and the following comma
				tempConfigs[property] = tempConfigs[property].slice(2);
			}
			else {
				//Cut the first character and the previous comma
				tempConfigs[property] = tempConfigs[property].slice(0, removeIndex - 1) + tempConfigs[property].slice(removeIndex + 1);
			}
		}			
		//Add to the property
		else {
			tempConfigs[property] = tempConfigs[property].length > 0 ? tempConfigs[property] + ',' + value : value;
		}
		

        setState({
            ...state,
            transportationConfigs: tempConfigs
        });
		setStateChanged(true);
    }

	const handleSliderChange = (property, value) => {
		const tempConfigs = {...state.transportationConfigs};
        tempConfigs[property] = value;
        setState({
            ...state,
            transportationConfigs: tempConfigs
        });
		setStateChanged(true);
    }

	const saveScreen = async () => {
		await savePlaylist(state, customer)
		.then(data => {
			//Fetch playlists again with update
			if (data.status === 200) {
				setStatusMessage({
					message: 'Your updates have been saved.',
					error: false
				});
				setStateChanged(false);
				getPlaylists(userData.customerId)
				.then(data => {
					setState({
						...state,
						//Update the current playlist in case BIZ gives more details
						//Finding based on label and customerId bc this may be a new playlsit that was just created
						playlist: data.find(pl => pl.label === state.playlist.label && pl.customerId === state.playlist.customerId),
					})
					setPlaylists(data);		
				});
			}
			//Failed save
			else {
				setStatusMessage({
					message: 'Error saving, please try again.',
					error: true
				});
			}
		});
	}

	const handleScreenSelection = (playlist) => {

		const urlObject = new URLSearchParams(playlist?.url.split('?')[1]);
		let transportationConfigs = {...constants.DEFAULT_TRANSPORTATION_CONFIGS};
		// Update current state to BIZ values
		for (const [key, value] of urlObject.entries()) {
			//Set boolean types first
			if (value === 'true' ) transportationConfigs[key] = true;
			else if (value === 'false' ) transportationConfigs[key] = false;
			else transportationConfigs[key] = value;
		}

		// Initial saving of state
		setState({
			playlist: playlist,
			transportationConfigs: transportationConfigs
		});
		setSavedState({
			playlist: playlist,
			transportationConfigs: transportationConfigs
		})
		setStateChanged(false);

		setShowOpenScreenModal(false);
	}

	const userHasAccess = (featureKey) => {
		return customer.enabledDash2Navs.includes(featureKey) || adminMenu;
	}

    const getUserAccessClassNames = (feature) => {
    	var retval = '';
    	// No access means the tab can't be selected.
    	if (!userHasAccess(feature.key) || !feature.enabled || !state?.playlist?.createdAt) {
    		retval += 'disabled-icon-container';
    	} else if (selectedTab === feature.key) {
    		retval += 'selected-tab';
    	}

    	return retval;
    }

	const fillSvgColor = (svg, stroke, fill) => {
		if (stroke || fill) {
			for (var i = 0; i < svg.children.length; i++) {
                svg.children[i].setAttribute('stroke', stroke);
				svg.children[i].setAttribute('fill', fill);
            }
		}
	}

	const determineToggle = (key) => {
		if (key === 'tripplanner') {
			//See if tripPlan is set to true, then turn on toggle
			return state.transportationConfigs?.tripPlan ? state.transportationConfigs?.tripPlan : false;
		}
	}

    const modifyIconColor = (svg, feature) => {
    	const needsFill = ['coffeeIcon'];
        if (!userHasAccess(feature.key) || !feature.enabled || !state?.playlist?.createdAt) {
        	// Gray if no access
            for (var i = 0; i < svg.children.length; i++) {
                svg.children[i].setAttribute('stroke', '#CCD2D3');
                if (needsFill.includes(feature.icon)) {
                	svg.children[i].setAttribute('fill', '#CCD2D3');
                }
            }
        } else if (selectedTab == feature.key) {
        	for (var i = 0; i < svg.children.length; i++) {
                svg.children[i].setAttribute('stroke', 'white');
            }
        }
		//Some icons are only for toggle, and won't change the page displayed
		else if (determineToggle(feature.key)) {
        	for (var i = 0; i < svg.children.length; i++) {
                svg.children[i].setAttribute('stroke', 'white');
            }
        }
    }

	const handleFeatureToggle = (key) => {
		//Set the toggle value to a new one, or if the same icon has been clicked, turn toggle off		
		if (key === 'tripplanner') {
			//Turn tripPlan on or off depending on toggle
			handleConfigChange('tripPlan', state.transportationConfigs?.tripPlan !== null ? !state.transportationConfigs?.tripPlan : false);
		}	
	}

    const getMenuItem = (feature) => {
		//First check for access per feature item
    	if (feature.module.includes(access)) {
	    	return <div 
				key={feature.key}
				className={`icon-container ${getUserAccessClassNames(feature)} ${determineToggle(feature.key) ? "selected-feature" : ""}`} 
				title={feature.title} 
				//The tab can either have a full page to edit, or be a toggle feature to turn on/off
				onClick={() => feature.hasPage ? setSelectedTab(feature.key) : handleFeatureToggle(feature.key)
			}>
				<ReactSVG className="icon feature-icon" src={icons[feature.icon]} beforeInjection={(svg) => modifyIconColor(svg, feature)}/>
			</div>
		}
    }

	const undoChange = () => {
		setState(savedState);
		setStateChanged(false);
	}

    var menu = [];
    screenFeatures.forEach((feature) => {
		menu.push(getMenuItem(feature));
	})

	return (
		<div className="ScreenPage">
			{
				showNewScreenModal
				? <NewScreenModal setShowNewScreenModal={setShowNewScreenModal} setShowAdminCreateModal={setShowAdminCreateModal} isAdmin={userData.role === 'af_admin'} />
				: <></>
			}
			{
				showAdminCreateModal
				? <AdminCreateModal setShowAdminCreateModal={setShowAdminCreateModal} getCustomers={getCustomers} setState={setState} />
				: <></>
			}
			{
				showOpenScreenModal
				? <OpenScreenModal playlists={playlists} selectedPlaylist={state?.playlist} handleScreenSelection={handleScreenSelection} setShowOpenScreenModal={setShowOpenScreenModal} />
				: <></>
			}
			{
				showPlayModal
				? <PlayModal setShowPlayModal={setShowPlayModal} shortCode={state?.playlist?.shortCode} />
				: <></>
			}
			{
				showLicenseModal
				? <LicenseModal state={state} setShowLicenseModal={setShowLicenseModal} handleInputChange={handleInputChange} saveScreen={saveScreen} customer={customer}/>
				: <></>
			}
			{
				statusMessage.message
				? <StatusModal setStatusMessage={setStatusMessage} statusMessage={statusMessage} />
				: <></>
			}
			{
				showUndoConfirmModal
				? <UndoConfirmModal setShowUndoConfirmModal={setShowUndoConfirmModal} undoChange={undoChange} />
				: <></>
			}
			<div className="page-name pure-u-12-24 align-right">
				<img id="action-figure-logo" src={logo} alt="action figure logo"/>
			</div>
            <div className="screen-actions">
                <div className="buttons-container pure-u-1-9">
                    <button className="open-screen" onClick={() => setShowNewScreenModal(true)}>
                        <img className="open-icon" src={plusIcon}/>
                    </button>
                    <button className="open-screen" onClick={() => setShowOpenScreenModal(true)}>
                        <img className="open-icon" src={openReport}/>
                    </button>
                </div>
				<div className="screen-name pure-u-1-7">
					{
						state?.playlist?.label
						? <div className="name-container">
							<p className="name">{state?.playlist?.label}</p>
							<p>{state?.playlist?.address}</p>
						</div>
						: <div className="name-container">
							<p className="name">No screen selected.</p>
							<p>Open a screen to view configurations</p>
						</div>	
					}
				</div>
				<div className="screen-tabs pure-u-1-2">
					<div className={`icon-container ${selectedTab === 'screen-config' && state?.playlist?.createdAt ? 'selected-tab' : ''}`} title="Screen configuration" onClick={() => setSelectedTab('screen-config')}>
						<ReactSVG className="icon" src={gearIcon} beforeInjection={(svg) => fillSvgColor(svg, !state?.playlist?.createdAt ? '#CCD2D3' : selectedTab === 'screen-config' ? '#fff' : '#081754')}/>
					</div>
					<div className="vertical-line"></div>
					{menu}
					<div className="vertical-line"></div>
                    <div className={`icon-container ${!state?.playlist?.createdAt ? "disabled" : ""}`} title="Undo all changes" onClick={() => setShowUndoConfirmModal(true)}>
						<ReactSVG className="icon" src={returnIcon} beforeInjection={(svg) => !state?.playlist?.createdAt ? fillSvgColor(svg, '#CCD2D3') : null}/>
					</div>
                    <div className={`icon-container  ${!state?.playlist?.createdAt ? "disabled" : ""} ${stateChanged ? "changed" : ""}`} title="Save changes"  onClick={saveScreen}>
						<ReactSVG className="icon" src={saveIcon} beforeInjection={(svg) => !state?.playlist?.createdAt ? fillSvgColor(svg, null, '#CCD2D3') : stateChanged ? fillSvgColor(svg, null, '#fff') : null}/>
					</div>
                    <div className={`icon-container  ${!state?.playlist?.createdAt ? "disabled" : ""}`} title="Preview screen" onClick={() => setShowPlayModal(state.playlist?.createdAt ? true: false)} >
						<ReactSVG className="icon" src={eyeIcon} beforeInjection={(svg) => !state?.playlist?.createdAt ? fillSvgColor(svg, '#CCD2D3') : null}/>
					</div>
                    <div className="vertical-line"></div>
                    <div className={`icon-container  ${!state?.playlist?.createdAt ? "disabled" : ""}`} title="Manage license" onClick={() => setShowLicenseModal(state.playlist?.createdAt ? true: false)}>
						<ReactSVG className="icon" src={keyIcon} beforeInjection={(svg) => !state?.playlist?.createdAt ? fillSvgColor(svg, '#CCD2D3') : null}/>
					</div>	
				</div>
			</div>
			{
				selectedTab === 'screen-config'
				? <ScreenConfiguration state={state} setState={setState} handleInputChange={handleInputChange} handleMultiInputChanges={handleMultiInputChanges} customerId={userData.customerId} selectedProduct={state?.playlist?.product}/>
				: selectedTab == 'transportationConfig' && userHasAccess('transportationConfig') && state?.playlist?.product === 'screen_pro'
				? <ScreenProTransportationContent />
				: selectedTab == 'transportationConfig' && userHasAccess('transportationConfig')
				? <TransportationContent configs={state?.transportationConfigs} handleColorChange={handleColorChange} handleConfigChange={handleConfigChange} handleMultiConfigChange={handleMultiConfigChange} handleSliderChange={handleSliderChange} access={access} />
				: <ScreenConfiguration state={state} setState={setState} handleInputChange={handleInputChange} handleMultiInputChanges={handleMultiInputChanges} customerId={userData.customerId} selectedProduct={state?.playlist?.product}/>
			}
        </div>
	)
}

const NewScreenModal = ({ setShowNewScreenModal, setShowAdminCreateModal, isAdmin }) => {

	const handleEmailRequest = () => {
		window.open('mailto:support@actionfigure.ai?subject=New Actionfigure Screen inquiry')
		setShowNewScreenModal(false);
	}

	//Open Admin Create modal
	const hadleAdminClick = () => {
		setShowNewScreenModal(false);
		setShowAdminCreateModal(true);
	}

    return (
        <div className="NewScreenModal modal">
			<div className="modal-header">
				<h2>Create a new Actionfigure Screen</h2>			
			</div>
			<p>Ready to create a new Actionfigure Screen? Our Customer Success team is here to help!</p>
			<p>Simply click the Contact Customer Success button below and drop us an email at support@actionfigure.ai to let us know you are interested in adding a new Screen!</p>
			<div className="bottom-container">
				{
					isAdmin
					? <button className="admin-button" onClick={hadleAdminClick}>Admin Only - Create New</button>
					: <></>
				}
				<button className="cancel-button" onClick={() => setShowNewScreenModal(false)}>Cancel</button>
				<button className="modal-button" onClick={handleEmailRequest}>Open</button>
			</div>	
        </div>
    )
}

const AdminCreateModal = ({ setShowAdminCreateModal, getCustomers, setState }) => {

	const [customers, setCustomers] = useState([]);
	const [selectedCustomerId, setSelectedCustomerId] = useState(process.env.REACT_APP_ACTIONFIGURE_CUSTOMER_ID);

	const createCustomerOptions = async () => {
		await getCustomers()
		.then(data => {
			const customerOptions = data.map(customer => {
				//Skip the Actionfigure option as it exists as a separate radio button option
				if (customer.id === process.env.REACT_APP_ACTIONFIGURE_CUSTOMER_ID) {
					return;
				}
				return {
					value: customer.id,
					label: customer.name
				}
			}).filter(n=>n)
			setCustomers(customerOptions);
		})
	}
	
	useEffect(() => {
		createCustomerOptions();
	}, [])

	const handleCreate = async() => {
		setState({
			playlist: {
				customerId: selectedCustomerId,
				label: 'No name provided',
				language: 'en',
				locationType: 'coordinates',
				screenType: 'standby',
				createdAt: new Date()
			},
			transportationConfigs: constants.DEFAULT_TRANSPORTATION_CONFIGS
		})
		setShowAdminCreateModal(false);
	}

    return (
        <div className="AdminCreateModal modal">
			<div className="modal-header">
				<h2>Choose a customer</h2>				
			</div>
			<p>Which customer should have access to this Screen?</p>
			<div className="customer-options">
				<div className="radio-container">
					<input type="radio" className="radio-input" checked={selectedCustomerId !== process.env.REACT_APP_ACTIONFIGURE_CUSTOMER_ID} readOnly />
					<Select  
						onChange={(option) => setSelectedCustomerId(option.value)}
						options={customers}
						value={ customers.find(customer => customer.value === selectedCustomerId) }
						className={"dropdown"}
						styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
					/>
				</div>
				<div className="radio-container">
					<input type="radio" className="radio-input" onChange={() => setSelectedCustomerId(process.env.REACT_APP_ACTIONFIGURE_CUSTOMER_ID)} checked={selectedCustomerId === process.env.REACT_APP_ACTIONFIGURE_CUSTOMER_ID} />
					<p className="text">Actionfigure only</p>
				</div>
			</div>
			<div className="bottom-container">
				<button className="cancel-button" onClick={() => setShowAdminCreateModal(false)}>Cancel</button>
				<button className="admin-button" onClick={handleCreate}>Create</button>			
			</div>	
        </div>
    )
}

const OpenScreenModal = ({ playlists, selectedPlaylist, handleScreenSelection, setShowOpenScreenModal }) => {

	const [highlightPlaylist, setHighlightPlaylist] = useState(selectedPlaylist);

	const playlistRows = playlists.length === 0
	? <></>
	: playlists.map(pl => {
		const currentDate = new Date();

		const activationDate = pl.activationDate
		? new Date(pl.activationDate)
		: null;

		const deactivationDate = pl.deactivationDate
		? new Date(pl.deactivationDate)
		: null;

		const status = activationDate && currentDate < activationDate
		? "Inactive"
		: deactivationDate && currentDate > deactivationDate
		? "Expired"
		: "Active"

		return <tr key={pl?.id} className={`playlist-row ${pl?.id === highlightPlaylist?.id ? 'selected' : ''}`} onClick={() =>setHighlightPlaylist(pl.url? pl : null)}>
			<td>{pl?.label}</td>
			<td>{formatDate(pl?.updatedAt)}</td>
			<td>{pl?.screenType}</td>
			<td>{status}</td>
		</tr>
	})

    return (
        <div className="OpenScreenModal modal">
			<div className="modal-header">
				<h2>Your Actionfigure Screens</h2>				
			</div>
			<div className="table-container">
				<table className="screens-table">
					<tbody>
						<tr className="header-row">
							<th className="table-header">Screen name</th>
							<th className="table-header">Last updated</th>
							<th className="table-header">What's playing</th>
							<th className="table-header">Status</th>
						</tr>
						{playlistRows}
					</tbody>
				</table>
			</div>
			<div className="bottom-container">
				<button className="cancel-button" onClick={() => setShowOpenScreenModal(false)}>Cancel</button>
				<button className="modal-button" onClick={() => handleScreenSelection(highlightPlaylist)}>Open</button>
			</div>
        </div>
    )
}

const PlayModal = ({ setShowPlayModal, shortCode }) => {

	const handleEmailRequest = () => {
		window.open('mailto:support@actionfigure.ai?subject=Issue with what\'s playing on my Screen')
		setShowPlayModal(false);
	}

    return (
        <div className="PlayModal modal">
			<div className="modal-header">
				<h2>Here’s whats playing on your Screen</h2>				
			</div>
			<p>The preview below shows what your Screen should be playing now. If what is actually playing on your screen doesn’t match the content here, please contact our friendly Customer Support team using the button below.</p>
			<p>Note: The orientation, resolution, and browser used on your TV, Phone, or Tablet device may mean the way this content shows up may differ.</p>
			<iframe className="iframe" src={`${process.env.REACT_APP_LOADER_URL}/${shortCode}`} />
			<div className="bottom-container">
				<button className="cancel-button" onClick={() => handleEmailRequest(false)}>Contact Customer Support</button>
				<button className="modal-button" onClick={() => setShowPlayModal(false)}>Close</button>
			</div>	
        </div>
    )
}

const LicenseModal = ({ state, setShowLicenseModal, handleInputChange, saveScreen, customer }) => {

	const devices = state?.playlist?.devices;
	
	const [selectedLimitRadio, setSelectedLimitRadio] = useState(state?.playlist?.activationLimit > 0);
	const [selectedDateRadio, setSelectedDateRadio] = useState(state?.playlist?.deactivationDate !== null);
	const [checkboxValue, setCheckboxValue] = useState('coordinates');

	const remaininDateText = state?.playlist?.deactivationDate
	? `${formatDate(state?.playlist?.deactivationDate)} (${calculateDateDifference(new Date, new Date(state?.playlist?.deactivationDate))} days remaining)`
	: 'unlimited'

	const activationDate = formatDate(state.playlist?.activationDate);
	const deactivationDate = formatDate(state.playlist?.deactivationDate);

	const handleRadioSelect = (property, value, radioCallback, radioValue) => {
		handleInputChange(property, value);
		radioCallback(radioValue)
		// setSelectedLimitRadio(value);
		// setActivationLimit(value ? 0 : null)
	}

	const handleCheckboxToggle = () => {
		setCheckboxValue(prevVal => {
			//Checkbox will turn to true
			if (prevVal === 'coordinates') {
				handleInputChange('locationType', 'locationCode');
			}
			//Checkbox will turn to false
			else {
				handleInputChange('locationType', 'coordinates');
			}

			//Set checkbox Value
			return prevVal === 'coordinates' ? 'locationCode' : 'coordinates'
		});
	}
	
	const handleSubmit = async() => {
		setShowLicenseModal(false);
		await saveScreen()
	}

    return (
        <div className="LicenseModal modal">
			<div className="modal-header">
				<h2>Manage license</h2>
			</div>
			<div className="license-container">
				<div className="left-col pure-u-1-2">
					<div className="input-container">
						<p className="pure-u-1-4">"API" Key:</p>
						<input className="license-input" value={customer?.apiKey || ''} disabled />
					</div>
					<div className="input-container">
						<p className="pure-u-1-4">Hub screen:</p>
						<input className="license-input" value={state?.playlist?.locationCode || ''} onChange={e => handleInputChange('locationCode', e.target.value)} />
						<input 
							className="checkbox-button"
							type="checkbox" 
							checked={state?.playlist?.locationType === 'locationCode'}
							onChange={handleCheckboxToggle}
						/>
					</div>
					<div className="input-container">
						<p className="pure-u-1-4">Environment:</p>
						<input className="license-input" value="Production (citymotion.io)" disabled />
					</div>
					<div className="input-container">
						<p className="pure-u-1-4">Player URL:</p>
						<input className="license-input" value={`${process.env.REACT_APP_LOADER_URL}/${state?.playlist?.shortCode}`} disabled />
					</div>
					<div className="input-container">
						<p className="pure-u-1-4">Full URL:</p>
						<input className="license-input" value={state?.playlist?.url} disabled />
					</div>
				</div>
				<div className="right-col pure-u-1-3">
					<div className="input-container">
						<p className="label pure-u-1-3">Current activations:</p>
						<div>
							<div className="radio-container">
								<input type="radio" className="radio-input" style={{visibility: 'hidden'}} checked={selectedLimitRadio} readOnly />
								<p className="text">{devices.length}</p>
							</div>
						</div>			
					</div>
					<div className="input-container">
						<p className="pure-u-1-3">Maximum activations:</p>
						<div>
							<div className="radio-container">
								<input type="radio" className="radio-input" checked={selectedLimitRadio} onChange={() => handleRadioSelect('activationLimit', null, setSelectedLimitRadio, true)} />
								<input type="text" className="license-input" value={state?.playlist?.activationLimit || ''} onChange={(e) => handleInputChange('activationLimit', e.target.value)} />
							</div>
							<div className="radio-container">
								<input type="radio" className="radio-input" checked={!selectedLimitRadio} onChange={() => handleRadioSelect('activationLimit', null, setSelectedLimitRadio, false)} />
								<p className="text">Unlimited</p>
							</div>
						</div>
					</div>
					<div className="input-container">
						<p className="pure-u-1-3">Activation date:</p>
						<div>
							<div className="radio-container">
								<input type="radio" className="radio-input" style={{visibility: 'hidden'}} checked={selectedLimitRadio} readOnly />
								<input type="date" className="license-input" value={activationDate || ''} onChange={(e) => handleInputChange('activationDate', e.target.value)} />
							</div>
						</div>
					</div>
					<div className="input-container">
						<p className="pure-u-1-3">Deactivation date:</p>
						<div>
							<div className="radio-container">
								<input type="radio" className="radio-input" checked={selectedDateRadio} onChange={() => handleRadioSelect('deactivationDate', null, setSelectedDateRadio, true)} />
								<input type="date" className="license-input" value={deactivationDate || ''} onChange={(e) => handleRadioSelect('deactivationDate', e.target.value, setSelectedDateRadio, true)} />
							</div>
							<div className="radio-container">
								<input type="radio" className="radio-input" checked={!selectedDateRadio} onChange={(e) => handleRadioSelect('deactivationDate', null, setSelectedDateRadio, false)} />
								<p className="text">Unlimited</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="activation-details">
				<div className="left-col pure-u-1-2">
					<div className="input-container">
						<p className="pure-u-1-5">Activation code:</p>
						<p className="license-input large-text">{state?.playlist?.shortCode}</p>
					</div>
					<div className="input-container">
						<p className="pure-u-1-5">Activations:</p>
						<p className="license-input">{devices.length} of {state?.playlist?.activationLimit || 'unlimited'}</p>
					</div>
					<div className="input-container">
						<p className="pure-u-1-5">Valid dates:</p>
						<p className="license-input">{formatDate(state?.playlist?.activationDate)} through {remaininDateText}</p>
					</div>
				</div>
			</div>
			<div className="bottom-container">
				<button className="modal-button" onClick={handleSubmit}>Ok</button>
			</div>
        </div>
    )
}

const StatusModal = ({ setStatusMessage, statusMessage }) => {
    return (
        <div className="StatusModal modal">
			<p className={`${statusMessage.error ? "error" : ""}`}>{statusMessage.message}</p>
			<div className="bottom-container">
				<button className="modal-button" onClick={() => setStatusMessage({message: null, error: false})}>Ok</button>			
			</div>
        </div>
    )
}

const UndoConfirmModal = ({ setShowUndoConfirmModal, undoChange }) => {

	const handleUndoConfirm = () => {
		undoChange();
		setShowUndoConfirmModal(false);
	}

    return (
        <div className="UndoConfirmModal modal">
			<p>This will remove all changes since the last time you saved. Are you sure?</p>
			<div className="bottom-container">
				<button className="cancel-button" onClick={() => setShowUndoConfirmModal(false)}>Cancel</button>
				<button className="modal-button" onClick={handleUndoConfirm}>Ok</button>			
			</div>
        </div>
    )
}

export default ScreenPage
