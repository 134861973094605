import React, { useState } from 'react';
import Select from 'react-select';
import Switch from "react-switch";
import constants from './constants.json';
import dropdownOptions from './dropdownOptions.json';

// When some toggles are enabled, then associated dropdowns must get a
// default value.
let defaultOptions = {
    'menuEnabled': {
        'menu': constants.DEFAULT_TRANSPORTATION_CONFIGS.menu
    },
    'navBarEnabled': {
        'barPosition': constants.DEFAULT_TRANSPORTATION_CONFIGS.barPosition,
        'openTo': constants.DEFAULT_TRANSPORTATION_CONFIGS.openTo,
    }
};

const InteractivityConfiguration = ({ state, setState, setStateChanged, customer}) => {
    const configs = state?.playlist?.interactivityConfigs || {};
    const defaultConfigs = constants.DEFAULT_TRANSPORTATION_CONFIGS;

    const hasPermission = (feature) => {
        return !customer?.enabledDash2Navs.includes(feature) ? 'disabled-section' : '';
    }

    const handleConfigChanges = (keys, values) => {
        const tempConfigs = {...state.playlist.interactivityConfigs};
        keys.forEach((key, index) => {
            tempConfigs[key] = values[index];
        });

        setState({
            ...state,
            playlist: {
                ...state.playlist,
                interactivityConfigs: tempConfigs
            },
        });
        setStateChanged(true);
    }

    const getLabelAndSwitch = (label, key) => {
        let checkValue = configs && key in configs ? configs[key] : defaultConfigs[key];

        return <div className="input-container">
            <div className="label">{label}:</div>
            <Switch
                className="switch"
                checked={ checkValue }
                onChange={ (checked) => handleConfigChanges([key], [checked]) }
                onColor="#2386EE"
                uncheckedIcon
                checkedIcon={false}
            />
        </div>
    }

    /*
     * These switches enable/disable a dropdown
     */
    const getLabelAndSwitchToggle = (label, key) => {
        let onChange = (checked) => handleConfigChanges([key], [checked]);

        // If we are enabling some toggles for the first time, set any
        // relevant default values.
        if (Object.keys(defaultOptions).includes(key) && !(key in configs)) {
            let moreKeys = [];
            let moreValues = [];
            for (let [nestedKey, value] of Object.entries(defaultOptions[key])) {
                moreKeys.push(nestedKey);
                moreValues.push(value);
            }
            onChange = (checked) => handleConfigChanges([key].concat(moreKeys), [checked].concat(moreValues));
        }

        return <div className="input-container">
            <div className="label">{label}:</div>
            <Switch
                className="switch"
                checked={configs && key in configs ? configs[key] : false}
                onChange={onChange}
                onColor="#2386EE"
                uncheckedIcon
                checkedIcon={false}
            />
        </div>
    }

    /**
     * dropdownKey - This key in dropdownOptions.json file contains the dropdown options
     *     that appear in the <Select> component
     * configKey - The key in the `interactivityConfigs` json
     * dependencyKey - What other key needs to be enabled before this dropdown is enabled
     */
    const getLabelAndSelect = (label, dropdownKey, configKey, dependencyKey) => {
        let classNames = configs && dependencyKey in configs && configs[dependencyKey] ? '' : 'disabled-section';

        return <div className="input-container">
            <div className="label">{label}:</div>
            <Select
                onChange={ (e) => handleConfigChanges([configKey], [e.value]) }
                defaultValue={dropdownOptions[dropdownKey][0]}
                options={dropdownOptions[dropdownKey]}
                value={dropdownOptions[dropdownKey].find(option => option.value === (configs ? configs[configKey] : defaultConfigs[configKey]))}
                className={`dropdown-list ${classNames}`}
                styles={basicConfigDropdownStyling}
            />
        </div>
    }

    /* React-Select custom styling */
    const basicConfigDropdownStyling = {
        container: (baseStyles, state) => ({
          ...baseStyles,
          // Too much gray if the dropdown is disabled.
          boxShadow: state.isDisabled ? "unset" : "0px 2px 8px 4px rgba(0, 0, 0, 0.07)",
          borderRadius: "8px"
        }),
        control: (baseStyles, state) => ({
            ...baseStyles,
            border: "none",
            cursor: "pointer"
        }),
        indicatorSeparator: (baseStyles, state) => ({
            ...baseStyles,
            display: "none"
        }),
        option: (baseStyles, state) => ({
            ...baseStyles,
            color: state.isDisabled ? "#919191" : "inherit",
            cursor: "pointer"
        })
    };

    return (
        <div className="InteractivityConfiguration">
            <div className="photo">
            </div>
            <div className="configuration">
                <h2>Interactivity</h2>

                <div className="section disabled-section">
                    <h3>Content on the go QR codes</h3>
                    <div className="description">
                        Enable people to interact with your content like never before. For each view you display (TransitScreen, Nearby, etc), a QR code will appear that allows the passerby to take that content with them in seconds on their mobile phone.
                    </div>
                    <div>
                        { getLabelAndSwitch('Enable', 'qrEnabled') }
                    </div>
                </div>

                <div className={`section ${hasPermission('transportationConfig')}`}>
                    <h3>TransitScreen&reg; Search and weather navigation bar</h3>
                    <div>
                        { getLabelAndSwitchToggle('Show', 'menuEnabled') }
                        { getLabelAndSelect('Type', 'TYPE', 'menu', 'menuEnabled') }
                    </div>
                </div>

                <div className={`section ${hasPermission('transportationConfig')}`}>
                    <h3>TransitScreen&reg; mode and trip planner navigation bar</h3>
                    { getLabelAndSwitchToggle('Show', 'navBarEnabled') }
                    { getLabelAndSelect('Location', 'BAR_POSITION', 'barPosition', 'navBarEnabled')}
                    { getLabelAndSelect('Opening Mode', 'OPEN_TO', 'openTo', 'navBarEnabled') }
                </div>

                <div className={`section ${hasPermission('tripPlanner')}`}>
                    <h3>Trip planner</h3>
                    <div className="description">
                        Help users get from A to B using our multi-modal trip planner feature. When enabled, a Trip Planner button will appear in the mode and trip planner navigation bar. When tapped, the user will be able to enter their destination and will be provided with a number of options to get them there.
                    </div>
                    <div>
                        { getLabelAndSwitch('Show', 'tripPlan') }
                    </div>
                </div>

                <div className="section">
                    <h3>Advanced mobile interactivity</h3>
                    <div className="description">
                        These features allow users to indicate their favorite stops/stations, tap into a "card" to see a map with enhanced arrivals information, and even turn on/off the ability to open other transportation apps (Uber, Lyft, etc) at the press of a button.
                    </div>
                    <div>
                        { getLabelAndSwitch('Enable', 'interactive') }
                        { getLabelAndSwitch('Favorites', 'favorites') }
                        { getLabelAndSwitch('Maps', 'maps') }
                        { getLabelAndSwitch('Other apps', 'externalLinks') }
                    </div>
                </div>
            </div>
        </div>
    )
}


export default InteractivityConfiguration;
