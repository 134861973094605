import React, {
    useState
} from 'react';
import Select from 'react-select';
import Switch from "react-switch";
import dropdownOptions from './dropdownOptions.json';
import constants from './constants.json';

const TransportationContent = ({
    configs,
    handleColorChange,
    handleConfigChange,
    handleMultiConfigChange,
    handleSliderChange,
    access
}) => {

    const [searchEnabled, setSearchEnabled] = useState(configs?.menu !== null);
    const [modeEnabled, setModeEnabled] = useState(configs?.barPosition !== null);

    const handleShowToggle = (cb, prop, value, checked) => {
        cb(checked);
        handleConfigChange(prop, checked ? value : null)
    }

    const allowedModes = constants.ALLOWED_MODES.map(mode => {
        let isChecked = false;
        if (configs?.allowedModes) {
            isChecked = configs?.allowedModes.includes(mode.value);
        }
        return <div className="mode-container" key={mode.value}>
            <input type="checkbox" checked={isChecked} onChange={() => handleMultiConfigChange('allowedModes', mode.value)} />
            <p>{mode.label}</p>
        </div>
    })

    return (
        <div className="TransportationContent">
            <div className="transportation-box">
                <h3>Transportation content</h3>
                <div className="left-col pure-u-1-2">                    
                    <h4>Modes of transportation</h4>
                    <p>Choose the Transportation types (“modes”) that you would like to show on your Screen. These are general categories so for instance “Rail” may include anything that runs on a rail, such as trains and subways.</p>
                    <div className="allowed-modes">
                        {allowedModes}
                    </div>
                    <div className="input-container">
                        <p className="label pure-u-1-6">Search area:</p>
                        <div className="search-radius-container">
                            <input type="range" min="0.1722" max="1.8278" step="0.4139" list="tickmarks" value={configs.radius ? configs.radius : "1"} onChange={(e) => handleSliderChange('radius', e.target.value)}></input>
                            <datalist id="tickmarks">
                                {/* The option values are the label divided by 1208 (m), up to 4 decimal places.
                                The search radius here eventually becomes the radius _multiplier_ in MOB.
                                So if radius=1, that means use the default radius (1208 m). If radius=2, that means
                                double the radius, not search within 2 km. */}
                                <option value="0.1722" label="0.2 km"></option>
                                <option value="0.5861" label="0.7 km"></option>
                                <option value="1" label="1.2 km"></option>
                                <option value="1.4139" label="1.7 km"></option>
                                <option value="1.8278" label="2.2 km"></option>
                            </datalist>
                        </div>
                    </div>
                </div>
                <div className="pure-u-1-2">

                </div>   
            </div>
            <div className="color-box">
                <h3>Colors and layout</h3>
                <div className="left-col pure-u-1-3">
                    <div className="input-container">
                        <p className="label pure-u-1-6">Background:</p>
                        <input className="input" value={configs?.mainBg || ''} onChange={(e) => handleColorChange('mainBg', e.target.value,)}></input>
                        <div className="color-preview" style={{backgroundColor: configs?.mainBg || '#000000'}}></div>
                    </div>
                    <div className="input-container">
                        <p className="label pure-u-1-6">Text:</p>
                        <input className="input" value={configs?.mainText || ''} onChange={(e) => handleColorChange('mainText', e.target.value)}></input>
                        <div className="color-preview" style={{backgroundColor: configs?.mainText || '#000000'}}></div>
                    </div>
                    <div className="input-container" style={{marginBottom: '1em'}}>
                        <p className="label pure-u-1-6">Accents:</p>
                        <input className="input" value={configs?.accentColor || ''} onChange={(e) => handleColorChange('accentColor', e.target.value)}></input>
                        <div className="color-preview" style={{backgroundColor: configs?.accentColor || '#000000'}}></div>
                    </div>
                </div>
                <div className="right-col pure-u-1-2">
                    <div className="input-container">
                        <p className="label pure-u-1-6">Icon style:</p>
                        <Select  
                            onChange={(e) => handleConfigChange('ui', e.value)}
                            options={dropdownOptions.UI}
                            value={dropdownOptions.UI.find(option => option.value === configs?.ui)}
                            className={"input"}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        />
                    </div>
                    {
                        access === 'screen'
                        ? <div className="input-container">
                            <p className="label pure-u-1-6">Resolution:</p>
                            <div style={{width: '100%'}}>                            
                                <div className="slider-container">
                                <input className="zoom-range" type="range" min="50" max="300" step="1" list="tickmarks" value={configs.zoom} onChange={(e) => handleSliderChange('zoom', e.target.value)}></input>
                                    <datalist id="tickmarks">
                                        <option value="50" label="Low"></option>
                                        <option value="300" label="High"></option>
                                    </datalist>
                                </div>
                            </div>
                        </div>
                        : <></>
                    }                    
                </div>
            </div>
            <div className="cards-box">
                <h3>Transportation information “cards”</h3>
                <div className="left-col pure-u-1-3">
                    <div className="input-container">
                        <p className="label pure-u-1-6">Background:</p>
                        <input className="input" value={configs?.cardBg || ''} onChange={(e) => handleColorChange('cardBg', e.target.value,)}></input>
                        <div className="color-preview" style={{backgroundColor: configs?.cardBg || '#000000'}}></div>
                    </div>
                    <div className="input-container">
                        <p className="label pure-u-1-6">Text:</p>
                        <input className="input" value={configs?.cardText || ''} onChange={(e) => handleColorChange('cardText', e.target.value)}></input>
                        <div className="color-preview" style={{backgroundColor: configs?.cardText || '#000000'}}></div>
                    </div>
                    <div className="input-container">
                        <p className="label pure-u-1-6">Border:</p>
                        <input className="input" value={configs?.borderColor || ''} onChange={(e) => handleColorChange('borderColor', e.target.value)}></input>
                        <div className="color-preview" style={{backgroundColor: configs?.borderColor || '#000000'}}></div>
                    </div>
                    <div className="input-container">
                        <p className="label pure-u-1-6">Time format:</p>
                        <Select  
                            onChange={(e) => handleConfigChange('timeDisplay', e.value)}
                            options={dropdownOptions.TIME_FORMAT}
                            value={dropdownOptions.TIME_FORMAT.find(option => option.value === configs?.timeDisplay)}
                            className={"input"}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        />
                    </div>
                    <div className="input-container">
                        <p className="label pure-u-1-6">Sort order:</p>
                        <Select  
                            onChange={(e) => handleConfigChange('sortOrder', e.value)}
                            options={dropdownOptions.SORT_ORDER}
                            value={dropdownOptions.SORT_ORDER.find(option => option.value === configs?.sortOrder)}
                            className={"input"}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        />
                    </div>
                </div>
                <div className="right-col pure-u-1-2">
                    <div className="input-container">
                        <p className="label pure-u-1-6">Card header:</p>    
                        <Switch className="switch" checked={configs?.cardHeader === undefined ? true : configs?.cardHeader } onChange={(checked) => handleConfigChange('cardHeader', checked)} onColor="#2386EE" uncheckedIcon checkedIcon={false}/>                    
                    </div>
                    <div className="input-container">
                        <p className="label pure-u-1-6">Vehicle color:</p>    
                        <Switch className="switch" checked={configs?.vehicleColor === undefined ? true : configs?.vehicleColor } onChange={(checked) => handleConfigChange('vehicleColor', checked)} onColor="#2386EE" uncheckedIcon checkedIcon={false}/>                    
                    </div>
                    <div className="input-container">
                        <p className="label pure-u-1-6">Soft corners:</p>    
                        <Switch className="switch" checked={configs?.borderRounding === undefined ? true : configs?.borderRounding } onChange={(checked) => handleConfigChange('borderRounding', checked)} onColor="#2386EE" uncheckedIcon checkedIcon={false}/>                    
                    </div>
                    <div className="input-container">
                        <p className="label pure-u-1-6">Shadow:</p>    
                        <Switch className="switch" checked={configs?.cardShadow === undefined ? true : configs?.cardShadow } onChange={(checked) => handleConfigChange('cardShadow', checked)} onColor="#2386EE" uncheckedIcon checkedIcon={false}/>                    
                    </div>
                    <div className="input-container">
                        <p className="label pure-u-1-6">Walk time:</p>    
                        <Switch className="switch" checked={configs?.cardWalkTime === undefined ? true : configs?.cardWalkTime } onChange={(checked) => handleConfigChange('cardWalkTime', checked)} onColor="#2386EE" uncheckedIcon checkedIcon={false}/>                    
                    </div>
                    <div className="input-container">
                        <p className="label pure-u-1-6">Group rows:</p>    
                        <Switch className="switch" checked={configs?.groupTransitRows === undefined ? true : configs?.groupTransitRows } onChange={(checked) => handleConfigChange('groupTransitRows', checked)} onColor="#2386EE" uncheckedIcon checkedIcon={false}/>                    
                    </div>
                    <div className="input-container" style={{marginBottom: '1em'}}>
                        <p className="label pure-u-1-6">Expandable:</p>    
                        <Switch className="switch" checked={configs?.interactive === undefined ? true : configs?.interactive } onChange={(checked) => handleConfigChange('interactive', checked)} onColor="#2386EE" uncheckedIcon checkedIcon={false}/>                    
                    </div>
                </div>
            </div>
            {
                access === 'mobile'
                ? <div className="search-box">
                    <h3>Search and weather bar</h3>
                    <div className="left-col pure-u-1-3">
                        <div className="input-container">
                            <p className="label pure-u-1-6">Show:</p>
                            <Switch className="switch" checked={configs?.menu !== null} onChange={(checked) => handleShowToggle(setSearchEnabled, 'menu', 'anywhere', checked)} onColor="#2386EE" uncheckedIcon checkedIcon={false}/>
                        </div>
                        <div className="input-container">
                            <p className="label pure-u-1-6">Type:</p>
                            <Select  
                                onChange={(e) => handleConfigChange('menu', e.value)}
                                options={dropdownOptions.TYPE}
                                value={dropdownOptions.TYPE.find(option => option.value === configs?.menu)}
                                className={`input ${searchEnabled ? "" : "disabled"}`}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                isDisabled={!searchEnabled}
                            />
                        </div>
                        <div className="input-container">
                            <p className="label pure-u-1-6">Background:</p>
                            <input className={`input ${searchEnabled ? "" : "disabled"}`} value={configs?.menuBg || ''} onChange={(e) => handleColorChange('menuBg', e.target.value)} disabled={!searchEnabled}></input>
                            <div className="color-preview" style={{backgroundColor: configs?.menuBg || '#000000'}}></div>
                        </div>
                        <div className="input-container">
                            <p className="label pure-u-1-6">Text:</p>
                            <input className={`input ${searchEnabled ? "" : "disabled"}`} value={configs?.menuText || ''} onChange={(e) => handleColorChange('menuText', e.target.value)} disabled={!searchEnabled}></input>
                            <div className="color-preview" style={{backgroundColor: configs?.menuText || '#000000'}}></div>
                        </div>
                    </div>
                    <div className="right-col pure-u-1-2"></div>
                </div>
                : <></>
            }
            {
                access === 'mobile'
                ? <div className="mode-box">
                    <h3>Mode selection bar</h3>
                    <div className="left-col pure-u-1-3">
                        <div className="input-container">
                            <p className="label pure-u-1-6">Show:</p>
                            <Switch className="switch" checked={configs?.barPosition !== null} onChange={(checked) => handleShowToggle(setModeEnabled, 'barPosition', 'bottom', checked)} onColor="#2386EE" uncheckedIcon checkedIcon={false}/>
                        </div>
                        <div className="input-container">
                            <p className="label pure-u-1-6">Background:</p>
                            <input className={`input ${modeEnabled ? "" : "disabled"}`} value={configs?.navBg || ''} onChange={(e) => handleColorChange('navBg', e.target.value)} disabled={!searchEnabled}></input>
                            <div className="color-preview" style={{backgroundColor: configs?.navBg || '#000000'}}></div>
                        </div>
                        <div className="input-container">
                            <p className="label pure-u-1-6">Text:</p>
                            <input className={`input ${modeEnabled ? "" : "disabled"}`} value={configs?.navText || ''} onChange={(e) => handleColorChange('navText', e.target.value)} disabled={!searchEnabled}></input>
                            <div className="color-preview" style={{backgroundColor: configs?.navText || '#000000'}}></div>
                        </div>
                        <div className="input-container">
                            <p className="label pure-u-1-6">Border:</p>
                            <input className={`input ${modeEnabled ? "" : "disabled"}`} value={configs?.borderColor || ''} onChange={(e) => handleColorChange('borderColor', e.target.value)} disabled={!searchEnabled}></input>
                            <div className="color-preview" style={{backgroundColor: configs?.borderColor || '#000000'}}></div>
                        </div>
                    </div>
                    <div className="right-col pure-u-1-2">
                        <div className="input-container">
                            <p className="label pure-u-1-6">Opening mode:</p>
                            <div className="radio-container">
                                <input type="radio" className="radio-input" checked={configs?.openTo === 'all'} onChange={() => handleConfigChange('openTo', 'all')} />
                                <p className="text">Only show “All” tab</p>
                            </div>
                        </div>
                        <div className="input-container">
                            <p className="label pure-u-1-6" style={{visibility: "hidden"}}>Opening mode:</p>
                            <div className="radio-container">
                                <input type="radio" className="radio-input" checked={configs?.openTo !== 'all'} readOnly />
                                <Select  
                                    onChange={(e) => handleConfigChange('openTo', e.value)}
                                    options={dropdownOptions.OPEN_TO}
                                    value={dropdownOptions.OPEN_TO.find(option => option.value === configs?.openTo)}
                                    className={`radio-select ${modeEnabled ? "" : "disabled"}`}
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    isDisabled={!modeEnabled}
                                />
                            </div>
                        </div>
                        <div className="input-container">
                            <p className="label pure-u-1-6">Location:</p>
                            <Select  
                                onChange={(e) => handleConfigChange('barPosition', e.value)}
                                options={dropdownOptions.BAR_POSITION}
                                value={dropdownOptions.BAR_POSITION.find(option => option.value === configs?.barPosition)}
                                defaultValue={{
                                    "label": "Bottom of Screen",
                                    "value": "bottom"
                                }}
                                className={`input ${modeEnabled ? "" : "disabled"}`}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                isDisabled={!modeEnabled}
                            />
                        </div>
                    </div>
                </div>
                : <></>
            }
            {
                access === 'mobile'
                ? <div className="advanced-box">
                    <h3>Advanced Mobile features</h3>
                    <div className="left-col pure-u-1-3">
                        <p>These features are some of the most powerful on Actionfigure Mobile. You can allow users to indicate their favorite stops/stations, tap into a “card” to see a map with enhanced arrivals information, and turn on/off the ability to open other transportation apps at the press of a button (Uber, Lyft, etc).</p>
                    </div>
                    <div className="right-col pure-u-1-2">
                        <div className="input-container">
                            <p className="label pure-u-1-6">Favorites:</p>    
                            <Switch className="switch" checked={configs?.favorites === undefined ? true : configs?.favorites } onChange={(checked) => handleConfigChange('favorites', checked)} onColor="#2386EE" uncheckedIcon checkedIcon={false}/>                    
                        </div>
                        <div className="input-container">
                            <p className="label pure-u-1-6">Maps:</p>    
                            <Switch className="switch" checked={configs?.maps === undefined ? true : configs?.maps } onChange={(checked) => handleConfigChange('maps', checked)} onColor="#2386EE" uncheckedIcon checkedIcon={false}/>                    
                        </div>
                        <div className="input-container">
                            <p className="label pure-u-1-6">Other apps:</p>    
                            <Switch className="switch" checked={configs?.externalLinks === undefined ? true : configs?.externalLinks } onChange={(checked) => handleConfigChange('externalLinks', checked)} onColor="#2386EE" uncheckedIcon checkedIcon={false}/>                    
                        </div>
                    </div>
                </div>
                : <></>
            }
            {
                access === 'screen'
                ? <div className="scroll-box">
                    <h3>Scrolling content</h3>
                    <div className="left-col pure-u-1-3">
                        <p>If there are more transportation options than can fit on your display at one time, you can enable Scrolling. Scrolling will vertically “roll” each column of transportation content.</p>
                    </div>
                    <div className="right-col pure-u-1-2">
                        <div className="input-container">
                            <p className="label pure-u-1-6">Scroll content:</p>    
                            <Switch className="switch" checked={configs?.scroll === undefined ? true : configs?.scroll } onChange={(checked) => handleConfigChange('scroll', checked)} onColor="#2386EE" uncheckedIcon checkedIcon={false}/>                    
                        </div>
                        <div className="input-container">
                            <p className="label pure-u-1-6">Scroll speed:</p>    
                            <Select  
                                onChange={(e) => handleConfigChange('scrollSpeed', e.value)}
                                options={dropdownOptions.SCROLL_SPEED}
                                value={dropdownOptions.SCROLL_SPEED.find(option => option.value === configs?.scrollSpeed)}
                                defaultValue={{
                                    "label": "Slow",
                                    "value": "slow"
                                }}
                                className={`input disabled`}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                isDisabled={true}
                            />
                        </div>
                    </div>
                </div>
                : <></>
            }
        </div>
    );
}

export default TransportationContent;
