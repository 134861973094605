import React from 'react';
import { Route, useHistory, Switch } from 'react-router-dom';
import Login from './Login';

import en from './localization/en.json';

const AppWithRouterAccess = () => {
    const history = useHistory();

    const customAuthHandler = () => {
        history.push('/login');
    };

    // Determine language localization at this level
    const lang = en;

    return (
        <Switch>
            <Route path='/'><Login lang={lang} /></Route>
            <Route path='/login' render={() => <Login />} />
        </Switch>
    );
};
export default AppWithRouterAccess;
