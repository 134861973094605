import React, { useState, useEffect, useContext } from 'react';
import Layout from './components/Layout';
import { useAuth0 } from "@auth0/auth0-react";
import { Circles } from 'react-loader-spinner';
import { Context } from './components/Context';
import Dashboard from './Dashboard';
import { setAccessToken, setRefreshTokenFunction } from "./bizClient";
import { config } from './config';

import visual1 from './images/login/login-visual1.jpg';
import visual2 from './images/login/login-visual2.jpg';
import visual3 from './images/login/login-visual3.jpg';
import visual4 from './images/login/login-visual4.jpg';
import visual5 from './images/login/login-visual5.jpg';
import visual6 from './images/login/login-visual6.jpg';
import visual7 from './images/login/login-visual7.jpg';

const Login = ({ lang }) => {
	const { user, isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently, logout } = useAuth0();
	const [state, setState] = useContext(Context);

	const visuals = [visual1, visual2, visual3, visual4, visual5, visual6, visual7];

	function getRandomInt(max) {
		return Math.floor(Math.random() * max);
	}

	const [backgroundImage, setBackgroundImage] = useState(visuals[getRandomInt(visuals.length)])

	useEffect(() => {
		// Once authenticated, get and store the access token
		const fetchToken = async () => {
			if (isAuthenticated && !state.accessToken) {
				try {
					setRefreshTokenFunction(getAccessTokenSilently); //Store refresh function in bizClient.js
					const token = await getAccessTokenSilently({
						authorizationParams: config.auth0AuthorizationParams
					});
					setState((prevState) => ({
						...prevState,
						accessToken: token,
					}));					
					setAccessToken(token); //Set token to a global variable
				} catch (error) {
					console.error("Error fetching access token:", error);
					logout({logoutParams: {returnTo: window.location.origin}}); //Force logout if error likely due to scopes
				}
			}
		};
		
		const login = async () => {
			await loginWithRedirect({
			  appState: { targetUrl: window.location.origin },
			  authorizationParams: {
				audience: "https://actionfigure.us.auth0.com/api/v2/",
				scope: "openid profile email offline_access"
			  },
			});		
		};

		//Refresh token on reload
		if (!state.accessToken) {
			fetchToken();
		}

		if (isLoading || isAuthenticated) {
			return;
		}

		login();
	}, [isLoading, isAuthenticated, loginWithRedirect, window.location.origin]);

	return user && state.accessToken
	? <Layout><Dashboard lang={lang} backgroundImage={backgroundImage} user={user} isLoading={isLoading} /></Layout> 
	: <div className="Loading">
		<Circles
			height="80"
			width="80"
			color="#2386EE"
			ariaLabel="circles-loading"
			visible={true}
		/>
	</div>	
};

export default Login;
