const BIZ_BASE_URL  = process.env.REACT_APP_BIZ_BASE_URL || 'https://ts-business-core-staging.herokuapp.com';

let accessToken = null; //Store token globally
let refreshTokenFn = null; //Store the function to refresh the token

//Function to decode JWT and check expiration
const isTokenExpired = (token) => {
    if (!token) return true;
    const payload = JSON.parse(atob(token.split(".")[1])); //Decode JWT payload
    const expiry = payload.exp * 1000; //Convert to milliseconds
    return Date.now() >= expiry;
  };

export const setAccessToken = (token) => {
    accessToken = token;
};

//Function to store the refresh function (called from React)
export const setRefreshTokenFunction = (refreshFn) => {
    refreshTokenFn = refreshFn;
};

export const bizRequest = async (endpoint, method = "GET", headers = {}, body = null) => {
    if (!accessToken || isTokenExpired(accessToken)) {
        //Token expired or doesn't exist so get the new token
        try {
            accessToken = await refreshTokenFn();
            setAccessToken(accessToken); //Store new token
        } catch(e) {
            throw new Error("Unauthorized: Token expired");
        }
    }

    const url = `${BIZ_BASE_URL}${endpoint}`;

    const config = {
        method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`, //Attach token automatically
          ...headers,
        },
        ...(body && { body: typeof body === "string" ? body : JSON.stringify(body) }),
    };

    try {
        const response = await fetch(url, config);
        if (!response.ok) {
            throw new Error(`Error: ${response.status} ${response.statusText}`);
        }

         // Check for empty response body
        const contentType = response.headers.get("content-type");
        if (!contentType || !contentType.includes("application/json")) {
            return response;
        }

        return await response.json();
    } catch (error) {
        console.error("API request failed:", error);
        throw error;
    }
};
