import { useState, useEffect, useMemo } from 'react';
import Select from 'react-select';

import { getBasePlaylists, savePlaylist, updatePlaylistVersion } from './utils';
import { formatDate, formatDateMonthFirst, getCustomers } from '../../utils';
import { getLocations } from '../Locations/utils';
import constants from './constants.json';
import { ReactSVG } from 'react-svg';
import ScreenConfiguration from './ScreenConfiguration';
import TransportationContent from './TransportationContent';
import NearbyConfiguration from './NearbyConfiguration';
import BrandingConfiguration from './BrandingConfiguration';
import InteractivityConfiguration from './InteractivityConfiguration';
import screenFeatures from './screenFeatures.json';
import dropdownOptions from './dropdownOptions.json';

import buildingIcon from '../../images/icons/screens/building.svg';
import coffeeIcon from '../../images/icons/screens/coffee.svg';
import copyIcon from '../../images/icons/screens/copy.svg';
import eyeIcon from '../../images/icons/screens/eye.svg';
import gearIcon from '../../images/icons/screens/gear.svg';
import keyIcon from '../../images/icons/screens/key.svg';
import openReport from '../../images/icons/analytics/open-report.svg';
import paperPlaneIcon from '../../images/icons/screens/paper-plane.svg';
import plusIcon from '../../images/icons/screens/white-plus.svg';
import returnIcon from '../../images/icons/screens/return.svg';
import saveIcon from '../../images/icons/screens/save.svg';
import transitIcon from '../../images/icons/screens/transit.svg';
import tripPlannerIcon from '../../images/icons/screens/trip-planner.svg';
import paintbrushIcon from '../../images/icons/screens/paintbrush.svg';
import interactivityIcon from '../../images/icons/screens/interactivity.svg';
import weatherIcon from '../../images/icons/screens/weather.svg';

const icons = {
	gearIcon,
	transitIcon,
	coffeeIcon,
	buildingIcon,
	paperPlaneIcon,
	tripPlannerIcon,
	paintbrushIcon,
	interactivityIcon,
	weatherIcon
};

const ScreenPage = ({
	lang,
	userData,
	access,
	customer,
	adminMenu
}) => {
    const [state, setState] = useState({
        playlist: {
			language: 'en',
			rotation: 0
		},
		transportationConfigs: constants.DEFAULT_TRANSPORTATION_CONFIGS
    })
	const [savedState, setSavedState] = useState({
        playlist: {
			language: 'en',
			rotation: 0
		},
		transportationConfigs: constants.DEFAULT_TRANSPORTATION_CONFIGS
    })
	const [stateChanged, setStateChanged] = useState(false);
    const [selectedTab, setSelectedTab] = useState('screenConfig');
	const [playlists, setPlaylists] = useState({});
	const [showNewScreenModal, setShowNewScreenModal] = useState(false);
	const [showAdminCreateModal, setShowAdminCreateModal] = useState(false);
	const [showOpenScreenModal, setShowOpenScreenModal] = useState(false);
	const [showLicenseModal, setShowLicenseModal] = useState(false);
	const [statusMessage, setStatusMessage] = useState({
		message: null,
		error: false
	});
	const [showUndoConfirmModal, setShowUndoConfirmModal] = useState(false);
	const [locations, setLocations] = useState([]);

	const getPlaylists = async () => {
		return await getBasePlaylists(userData.customerId);
	}

	useEffect(() => {
		getPlaylists(userData.customerId)
		.then(data => {
			setPlaylists(data)
			//If only one playlist, then set that one playlist as the selected one from the start
			if (data.length === 1) {
				const playlist = data[0];
				let transportationConfigs = {...constants.DEFAULT_TRANSPORTATION_CONFIGS};

				if (playlist?.url) {
					const urlObject = new URLSearchParams(playlist?.url.split('?')[1]);
					// Update current state to BIZ values
					for (const [key, value] of urlObject.entries()) {
						//Set boolean types first
						if (value === 'true' ) transportationConfigs[key] = true;
						else if (value === 'false' ) transportationConfigs[key] = false;
						else transportationConfigs[key] = value;
					}
				}

				// Initial saving of state
				setState({
					playlist: playlist,
					transportationConfigs: transportationConfigs
				});
			}
		});
	}, [])


	useEffect(() => {
		getLocations(userData.customerId)
		.then(data => {
			setLocations(data);
		});
	}, []);

	const oneViewOptions = useMemo(() => {
		if (!customer) {
			return []; // Return an empty array if customer is not available
		}

		let options = [];
		dropdownOptions.VIEWS.forEach((option) => {
			// Dynamically identify option as disabled based on the features
			// that the customer purchased.
			option.isDisabled = !customer.enabledDash2Navs.includes(option.requirement);
			options.push(option);
	    });

	    return options;
	}, [customer]); // Recalculate only when customer changes

	const handleInputChange = (property, value) => {
		const tempPlaylist = {...state.playlist};
        tempPlaylist[property] = value;
        // Update the location object, too.
        if (property === "locationId") {
            tempPlaylist["location"] = locations.find((location) => location.id === value);
        }
        setState({
            ...state,
            playlist: tempPlaylist
        });
		setStateChanged(true);
    }

    const handleColorChange = (property, value) => {
		const tempConfigs = {...state.transportationConfigs};
		tempConfigs[property] = value;

		setState({
            ...state,
            transportationConfigs: tempConfigs
        });
		setStateChanged(true);
    }

	//For transportation configs
	const handleConfigChanges = (propNames, values) => {
		const tempConfigs = {...state.transportationConfigs};
		propNames.forEach((item, index) => {
			tempConfigs[item] = values[index];
		});

		setState({
			...state,
			transportationConfigs: tempConfigs
		});
		setStateChanged(true);
	}

	const handleMultiInputChanges = (propNames, values) => {
		const tempPlaylist = {...state.playlist};        
		
		propNames.forEach((item, index) => {
		  tempPlaylist[item] = values[index];
		});

		setState({
            ...state,
            playlist: tempPlaylist
        });
		setStateChanged(true);
	}

	const saveScreen = async () => {
		await savePlaylist(state, customer)
		.then(data => {
			//Fetch playlists again with update
			if (data.status === 200) {
				setStatusMessage({
					message: 'Your updates have been saved.',
					error: false
				});
				setStateChanged(false);
				getPlaylists(userData.customerId)
				.then(data => {
					setState({
						...state,
						//Update the current playlist in case BIZ gives more details
						//Finding based on label and customerId bc this may be a new playlsit that was just created
						playlist: data.find(pl => pl.label === state.playlist.label && pl.customerId === state.playlist.customerId),
					})
					setPlaylists(data);
				});
			}
			//Failed save
			else {
				setStatusMessage({
					message: 'Error saving, please try again.',
					error: true
				});
			}
		});
	}

	const createScreen = (customerId) => {
		let defaultView = dropdownOptions.VIEWS.find((option) => customer.enabledDash2Navs.includes(option.requirement));
		let defaultScreenType = defaultView ? defaultView.value : 'standby';
		// Default is all modes allowed
		let transportationConfigs = {...constants.DEFAULT_TRANSPORTATION_CONFIGS};
		setState({
			playlist: {
				customerId: customerId,
				geolocation: false,
				label: 'No name provided',
				language: 'en',
				locationType: 'coordinates',
				screenType: defaultScreenType,
				deviceType: 'screen',
				createdAt: new Date(),
				_new: true
			},
			transportationConfigs: transportationConfigs
		})
	}

	const handleScreenSelection = (playlist) => {
		const urlObject = new URLSearchParams(playlist?.url?.split('?')[1]);
		let transportationConfigs = {...constants.DEFAULT_TRANSPORTATION_CONFIGS};
		// Update current state to BIZ values
		for (const [key, value] of urlObject.entries()) {
			//Set boolean types first
			if (value === 'true' ) transportationConfigs[key] = true;
			else if (value === 'false' ) transportationConfigs[key] = false;
			else transportationConfigs[key] = value;
		}

		// Initial saving of state
		setState({
			playlist: {
				...playlist,
				language: playlist?.language ?? 'en' //For the legacy screens that did not have langauges preset
			},
			transportationConfigs: transportationConfigs
		});
		setSavedState({
			playlist: {
				...playlist,
				language: playlist?.language ?? 'en'
			},
			transportationConfigs: transportationConfigs
		})
		setStateChanged(false);

		setShowOpenScreenModal(false);
	}

	const userHasAccess = (featureKey) => {
		// The Basic Configuration tab is accessible by default if user has perms for screen editing.
		const defaultAllowed = ['screenConfig'].includes(featureKey);
		return defaultAllowed || customer.enabledDash2Navs.includes(featureKey) || adminMenu;
	}

    const getUserAccessClassNames = (feature) => {
    	var retval = '';
    	// No access means the tab can't be selected.
    	if (!userHasAccess(feature.key) || !feature.enabled || !state?.playlist?.createdAt) {
    		retval += 'disabled-icon-container';
    	} else if (selectedTab === feature.key) {
    		retval += 'selected-tab';
    	}

    	return retval;
    }

	const fillSvgColor = (svg, stroke, fill) => {
		if (stroke || fill) {
			for (var i = 0; i < svg.children.length; i++) {
                svg.children[i].setAttribute('stroke', stroke);
				svg.children[i].setAttribute('fill', fill);
            }
		}
	}

	const determineToggle = (key) => {
		if (key === 'tripplanner') {
			//See if tripPlan is set to true, then turn on toggle
			return state.transportationConfigs?.tripPlan ? state.transportationConfigs?.tripPlan : false;
		}
	}

    const modifyIconColor = (svg, feature) => {
        const needsFill = ['coffeeIcon', 'interactivityIcon', 'weatherIcon', 'paintbrushIcon'];
        if (!userHasAccess(feature.key) || !feature.enabled || !state?.playlist?.createdAt) {
        	// Gray if no access
            for (var i = 0; i < svg.children.length; i++) {
                svg.children[i].setAttribute('stroke', '#CCD2D3');
                if (needsFill.includes(feature.icon)) {
                	svg.children[i].setAttribute('fill', '#CCD2D3');
                }
            }
        } else if (selectedTab == feature.key) {
        	for (var i = 0; i < svg.children.length; i++) {
                svg.children[i].setAttribute('stroke', 'white');
                if (needsFill.includes(feature.icon)) {
                    svg.children[i].setAttribute('fill', 'white');
                }
            }
        }
		//Some icons are only for toggle, and won't change the page displayed
		else if (determineToggle(feature.key)) {
        	for (var i = 0; i < svg.children.length; i++) {
                svg.children[i].setAttribute('stroke', 'white');
            }
        } else if (userHasAccess(feature.key) && feature.enabled && state?.playlist?.createdAt) {
            // Explicitly turn it the "active" color
            for (var i = 0; i < svg.children.length; i++) {
                svg.children[i].setAttribute('stroke', '#373739');
                if (needsFill.includes(feature.icon)) {
                    svg.children[i].setAttribute('fill', '#373739');
                }
            }
        }
    }

	const handleFeatureToggle = (key) => {
		//Set the toggle value to a new one, or if the same icon has been clicked, turn toggle off		
		if (key === 'tripplanner') {
			//Turn tripPlan on or off depending on toggle
			handleConfigChanges(['tripPlan'], state.transportationConfigs?.tripPlan !== null ? [!state.transportationConfigs?.tripPlan] : [false]);
		}	
	}

    const getMenuItem = (feature) => {
        if (feature.endGrouping) {
            menu.push(<div className="vertical-line" key={feature.key}></div>);
            return;
		} else if (feature.module.includes(access)) {
			//First check for access per feature item
			return <div key={feature.key} title={feature.title}>
				<div
					className={`icon-container ${getUserAccessClassNames(feature)} ${determineToggle(feature.key) ? "selected-feature" : ""}`} 
					title={feature.title}
					//The tab can either have a full page to edit, or be a toggle feature to turn on/off
					onClick={() => feature.hasPage ? setSelectedTab(feature.key) : handleFeatureToggle(feature.key)
				}>
					<ReactSVG className="icon feature-icon" src={icons[feature.icon]} beforeInjection={(svg) => modifyIconColor(svg, feature)}/>
				</div>
			</div>
		}
    }

	const undoChange = () => {
		setState(savedState);
		setStateChanged(false);
	}

    var menu = [];
    screenFeatures.forEach((feature) => {
		menu.push(getMenuItem(feature));
	})

	//Different modals we have for this page
	const renderModal = () => {
		if (showNewScreenModal) return <NewScreenModal setShowNewScreenModal={setShowNewScreenModal} setShowAdminCreateModal={setShowAdminCreateModal} isAdmin={userData.role === 'af_admin'} customerId={userData.customerId} createScreen={createScreen} />;
		if (showAdminCreateModal) return <AdminCreateModal setShowAdminCreateModal={setShowAdminCreateModal} getCustomers={getCustomers} setState={setState} createScreen={createScreen} />;
		if (showOpenScreenModal) return <OpenScreenModal playlists={playlists} selectedPlaylist={state?.playlist} handleScreenSelection={handleScreenSelection} setShowOpenScreenModal={setShowOpenScreenModal} />;
		if (showLicenseModal) return <LicenseModal state={state} setShowLicenseModal={setShowLicenseModal} handleInputChange={handleInputChange} handleMultiInputChanges={handleMultiInputChanges} saveScreen={saveScreen} customer={customer} stateChanged={stateChanged} />;
		if (showUndoConfirmModal) return <UndoConfirmModal setShowUndoConfirmModal={setShowUndoConfirmModal} undoChange={undoChange} />;
		if (statusMessage.message) return <StatusModal setStatusMessage={setStatusMessage} statusMessage={statusMessage} />
		return null;
	};

	return (
		<div className="ScreenPage">
			{renderModal()}
            <div className="screen-actions">
                <div className="buttons-and-name">
                    <div className="buttons-container pure-u-1-9">
                        <button className="open-screen" onClick={() => setShowNewScreenModal(true)}>
                            <img className="open-icon" src={plusIcon}/>
                        </button>
                        <button className="open-screen" title="Open a Screen or Mobile" onClick={() => setShowOpenScreenModal(true)}>
                            <img className="open-icon" src={openReport}/>
                        </button>
                    </div>
                    <div className="name-container pure-u-1-7">
                        <div className="name">
                            { state?.playlist?.label ? state.playlist.label : "No screen selected" }
                        </div>
                        {
                            state?.playlist?.shortCode
                            ? <div className="activation-code-description">Activation code: <span className="screen-activation-code">{state.playlist.shortCode.toUpperCase()}</span></div>
                            : <div className="open-a-screen">Open a Screen to view configurations</div>
                        }
                    </div>
				</div>
				<div className="screen-tabs">
					{menu}
					<div className="vertical-line"></div>
					<div title="Preview Screen">
						<a href={`${process.env.REACT_APP_LOADER_URL}/${state?.playlist?.shortCode}`} target="_blank" className={`${!state?.playlist?.shortCode ? 'disabled' : ''}`}>
							<div className={`icon-container ${!state?.playlist?.createdAt ? "disabled" : ""}`} title="Preview screen">
								<ReactSVG className="icon preview-screen-icon" src={eyeIcon} beforeInjection={(svg) => !state?.playlist?.createdAt ? fillSvgColor(svg, '#CCD2D3', '#CCD2D3') : null}/>
							</div>
						</a>
					</div>
                    <div title="Undo all changes">
						<div className={`icon-container ${!state?.playlist?.createdAt ? "disabled" : ""}`} title="Undo all changes" onClick={() => setShowUndoConfirmModal(true)}>
							<ReactSVG className="icon" src={returnIcon} beforeInjection={(svg) => !state?.playlist?.createdAt ? fillSvgColor(svg, '#CCD2D3') : null}/>
						</div>
					</div>
                    <div title="Save changes" className={`icon-container  ${!state?.playlist?.createdAt ? "disabled" : ""} ${stateChanged ? "changed" : ""}`} onClick={saveScreen}>
						<ReactSVG className="icon" src={saveIcon} beforeInjection={(svg) => !state?.playlist?.createdAt ? fillSvgColor(svg, null, '#CCD2D3') : stateChanged ? fillSvgColor(svg, null, '#fff') : null}/>
					</div>
					<div title="Manage license" className={`icon-container ${!state?.playlist?.createdAt ? "disabled" : "manage-license-button"}`} onClick={() => setShowLicenseModal(state.playlist?.createdAt ? true: false)}>
						<ReactSVG className="icon" src={keyIcon} beforeInjection={(svg) => !state?.playlist?.createdAt ? fillSvgColor(svg, '#CCD2D3', null) : null} />
					</div>
				</div>
			</div>
			{
				selectedTab === 'screenConfig'
				? <ScreenConfiguration state={state} setState={setState} handleInputChange={handleInputChange} handleMultiInputChanges={handleMultiInputChanges} customerId={userData.customerId} selectedProduct={state?.playlist?.product} customer={customer} locations={locations} oneViewOptions={oneViewOptions} />
				: selectedTab == 'transportationConfig' && userHasAccess('transportationConfig')
				? <TransportationContent
					state={state}
					setState={setState}
					setStateChanged={setStateChanged}
				/>
				: selectedTab == 'nearby' && userHasAccess('nearby')
				? <NearbyConfiguration state={state} setState={setState} setStateChanged={setStateChanged} />
				: selectedTab == 'brandingConfiguration'
				? <BrandingConfiguration
					state={state}
					setState={setState}
					setStateChanged={setStateChanged}
					customer={customer}
					oneViewOptions={oneViewOptions}
				/>
				: selectedTab === 'interactivityConfiguration'
				? <InteractivityConfiguration
					state={state}
					setState={setState}
					setStateChanged={setStateChanged}
					customer={customer}
				/>
				: <ScreenConfiguration state={state} setState={setState} handleInputChange={handleInputChange} handleMultiInputChanges={handleMultiInputChanges} customerId={userData.customerId} selectedProduct={state?.playlist?.product} customer={customer} locations={locations} oneViewOptions={oneViewOptions} />
			}
        </div>
	)
}

const NewScreenModal = ({ setShowNewScreenModal, setShowAdminCreateModal, isAdmin, customerId, createScreen }) => {

	useEffect(() => {
		// Prevent scrolling when modal is open
		document.body.classList.add("no-scroll");
	
		return () => {
			// Allow scrolling when modal is closed
			document.body.classList.remove("no-scroll");
		};
	}, []);
	
	const handleEmailRequest = () => {
		window.open('mailto:support@actionfigure.ai?subject=New Actionfigure Screen inquiry')
		setShowNewScreenModal(false);
	}

	//Open Admin Create modal
	const handleAdminClick = () => {
		setShowNewScreenModal(false);
		//Actionfigure customer setting
		if (customerId === process.env.REACT_APP_ACTIONFIGURE_CUSTOMER_ID) {
			setShowAdminCreateModal(true);
		}
		//Already masked as a separate customer
		else {
			createScreen(customerId);
		}
	}

    return (
		<div className="modal-wrapper">
			<div className="NewScreenModal modal">
				<div className="modal-header">
					<h2>Create a new Actionfigure Screen</h2>			
				</div>
				<p>Ready to create a new Actionfigure Screen? Our Customer Success team is here to help!</p>
				<p>Simply click the Contact Customer Success button below and drop us an email at support@actionfigure.ai to let us know you are interested in adding a new Screen!</p>
				<div className="bottom-container">
					{
						isAdmin
						? <button className="button admin-button" onClick={handleAdminClick}>Admin Only - Create New</button>
						: <></>
					}
					<button className="button cancel-button" onClick={() => setShowNewScreenModal(false)}>Cancel</button>
					<button className="button modal-button" onClick={handleEmailRequest}>Open</button>
				</div>	
			</div>
		</div>
    )
}

const AdminCreateModal = ({ setShowAdminCreateModal, getCustomers, setState, createScreen }) => {

	const [customers, setCustomers] = useState([]);
	const [selectedCustomerId, setSelectedCustomerId] = useState(process.env.REACT_APP_ACTIONFIGURE_CUSTOMER_ID);

	const createCustomerOptions = async () => {
		await getCustomers()
		.then(data => {
			const customerOptions = data.map(customer => {
				//Skip the Actionfigure option as it exists as a separate radio button option
				if (customer.id === process.env.REACT_APP_ACTIONFIGURE_CUSTOMER_ID) {
					return;
				}
				return {
					value: customer.id,
					label: customer.name
				}
			}).filter(n=>n)
			
			setCustomers(customerOptions.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase())));
		})
	}
	
	useEffect(() => {
		createCustomerOptions();
		// Prevent scrolling when modal is open
		document.body.classList.add("no-scroll");
	
		return () => {
			// Allow scrolling when modal is closed
			document.body.classList.remove("no-scroll");
		};		
	}, [])

	const handleCreate = () => {
		createScreen(selectedCustomerId);
		setShowAdminCreateModal(false);
	}

    return (
		<div className="modal-wrapper">
			<div className="AdminCreateModal modal">
				<div className="modal-header">
					<h2>Choose a customer</h2>				
				</div>
				<p>Which customer should have access to this Screen?</p>
				<div className="customer-options">
					<div className="radio-container disabled-section">
						<input type="radio" className="radio-input" checked={selectedCustomerId !== process.env.REACT_APP_ACTIONFIGURE_CUSTOMER_ID} readOnly disabled={true} />
						<Select  
							onChange={(option) => setSelectedCustomerId(option.value)}
							options={customers}
							value={ customers.find(customer => customer.value === selectedCustomerId) }
							className={"dropdown"}
							styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
							isDisabled={true}
						/>
					</div>
					<div className="radio-container">
						<input type="radio" className="radio-input" onChange={() => setSelectedCustomerId(process.env.REACT_APP_ACTIONFIGURE_CUSTOMER_ID)} checked={selectedCustomerId === process.env.REACT_APP_ACTIONFIGURE_CUSTOMER_ID} />
						<p className="text">Actionfigure only</p>
					</div>
				</div>
				<div className="bottom-container">
					<button className="button cancel-button" onClick={() => setShowAdminCreateModal(false)}>Cancel</button>
					<button className="button admin-button" onClick={handleCreate}>Create</button>			
				</div>	
			</div>
		</div>
    )
}

const OpenScreenModal = ({ playlists, selectedPlaylist, handleScreenSelection, setShowOpenScreenModal }) => {

	useEffect(() => {
		// Prevent scrolling when modal is open
		document.body.classList.add("no-scroll");
	
		return () => {
			// Allow scrolling when modal is closed
			document.body.classList.remove("no-scroll");
		};
	}, []);

	const [highlightPlaylist, setHighlightPlaylist] = useState(selectedPlaylist);

	const handleRowClick = (pl) => () => setHighlightPlaylist(pl.url ? pl : null);

	const playlistRows = playlists.length === 0
	? <></>
	: playlists.map(pl => {
		const currentDate = new Date();

		const activationDate = pl.activationDate
		? new Date(pl.activationDate)
		: null;

		const deactivationDate = pl.deactivationDate
		? new Date(pl.deactivationDate)
		: null;

		const status = activationDate && currentDate < activationDate
		? "Inactive"
		: deactivationDate && currentDate > deactivationDate
		? "Expired"
		: "Active"

		return <tr key={pl?.id} className={`playlist-row ${pl?.id === highlightPlaylist?.id ? 'selected' : ''}`} onClick={handleRowClick(pl)}>
			<td>{pl?.label}</td>
			<td>{formatDate(pl?.updatedAt)}</td>
			<td>{pl?.screenType}</td>
			<td>{status}</td>
		</tr>
	})

	const screenSelection = () => {
		if (!highlightPlaylist) return; // Prevents errors if no playlist is selected
		handleScreenSelection(highlightPlaylist);
	}

	const closeOpenScreenModal = () => {
		setShowOpenScreenModal(false);
	}

    return (
		<div className="modal-wrapper">
			<div className="OpenScreenModal modal">
				<div className="modal-header">
					<h2>Your Actionfigure Screens</h2>				
				</div>
				<div className="table-container">
					<table className="screens-table">
						<tbody>
							<tr className="header-row">
								<th className="table-header">Screen name</th>
								<th className="table-header">Last updated</th>
								<th className="table-header">What's playing</th>
								<th className="table-header">Status</th>
							</tr>
							{playlistRows}
						</tbody>
					</table>
				</div>
				<div className="bottom-container">
					<button className="button cancel-button" onClick={closeOpenScreenModal}>Cancel</button>
					<button className="button modal-button" onClick={screenSelection}>Open</button>
				</div>
			</div>
		</div>
    )
}

const LicenseModal = ({ state, setShowLicenseModal, handleInputChange, handleMultiInputChanges, saveScreen, customer, stateChanged }) => {

	useEffect(() => {
		// Prevent scrolling when modal is open
		document.body.classList.add("no-scroll");
	
		return () => {
			// Allow scrolling when modal is closed
			document.body.classList.remove("no-scroll");
		};
	}, []);

	const devices = state?.playlist?.devices;
	
	const handleSubmit = async() => {
		setShowLicenseModal(false);

		if (!stateChanged) return; //Just return if nothing was changed

		await saveScreen();
	}

	const handleRefresh = async () => {
		try {
			const resp = await updatePlaylistVersion(state?.playlist?.id);
			if (resp.status === 201) {
				console.log("Success");
			} else {
				console.error("Version update failed");
			}
		} catch (error) {
			console.error("Error updating playlist version:", error);
		} finally {
			setShowLicenseModal(false);
		}
	}

	//Copies text to clipboard
	const copyText = (value) => {
		if (!value) return;

		navigator.clipboard.writeText(value);
	}

    return (
		<div className="modal-wrapper">
			<div className="LicenseModal modal">
				<div className="modal-header">
					<h2>Manage license</h2>
				</div>
				<div className="license-container">
					<div className="left-col">
						<div className="input-container">
							<p className="label">"API" Key:</p>
							<div className="input-wrapper">
								<input className="license-input" value={customer?.apiKey || ''} disabled />
								<img src={copyIcon} alt="copy" className="input-icon" onClick={() => copyText(customer?.apiKey)}/>
							</div>
						</div>					
						<div className="input-container">
							<p className="label">Environment:</p>
							<div className="input-wrapper">
								<input className="license-input" value="Production (citymotion.io)" disabled />
							</div>
						</div>
						<div className="input-container">
							<p className="label">Activation code:</p>
							<div className="input-wrapper">
								<input className="license-input" value={state?.playlist?.shortCode} disabled />
								<img src={copyIcon} alt="copy" className="input-icon" onClick={() => copyText(state?.playlist?.shortCode)} />
							</div>
						</div>
						<div className="input-container">
							<p className="label">Player URL:</p>
							<div className="input-wrapper">
								<input className="license-input" value={`${process.env.REACT_APP_LOADER_URL}/${state?.playlist?.shortCode}`} disabled />
								<img src={copyIcon} alt="copy" className="input-icon" onClick={() => copyText(`${process.env.REACT_APP_LOADER_URL}/${state?.playlist?.shortCode}`)} />
							</div>
						</div>
						<div className="input-container">
							<p className="label">Full URL:</p>
							<div className="input-wrapper">
								<input className="license-input" value={state?.playlist?.url} disabled />
								<img src={copyIcon} alt="copy" className="input-icon" onClick={() => copyText(state?.playlist?.url)} />
							</div>
						</div>
						<div className="input-container">
							<p className="label">Type:</p>
							<div className="radio-group">
								<label className="radio-row">
									<input
										className="radio-button"
										id="location-anywhere"
										type="radio"
										name="locationType"  // Grouping the radio buttons
										value=""
										checked={state?.playlist?.locationType === 'coordinates' && !state?.playlist?.geolocation}
										onChange={() => handleMultiInputChanges(['locationType', 'geolocation'], ['coordinates', false])}
									/>
									Anywhere (default)
								</label>
								<label className="radio-row">
									<input
										className="radio-button"
										id="location-anywhere-geolocation"
										type="radio"
										name="locationType"
										value=""
										checked={state?.playlist?.locationType === 'coordinates' && state?.playlist?.geolocation}
										onChange={() => handleMultiInputChanges(['locationType', 'geolocation'], ['coordinates', true])}
									/>
									Anywhere with Geolocation
								</label>
								{/* Hub Option (Radio + Label + Input) */}
								<label className="radio-row hub-row">
									<input
										className="radio-button"
										id="location-hub"
										type="radio"
										name="locationType"
										value=""
										checked={state?.playlist?.locationType === 'locationCode'}
										onChange={() => handleMultiInputChanges(['locationType', 'locationCode', 'geolocation'], ['locationCode', state?.playlist?.locationCode, false])}
									/>
									<span>Hub</span>
									<input
										type="text"
										className="hub-input"
										placeholder="Enter Hub Name"
										value={state?.playlist?.locationCode || ''}
										onChange={e => handleMultiInputChanges(['locationType', 'locationCode', 'geolocation'], ['locationCode', e.target.value, false])}
									/>
								</label>
							</div>
						</div>
					</div>
					<div className="right-col">
						<div className="input-container">
							<p className="label">Enable this code:</p>
							<div className="radio-group">
								<label className="radio-row">
									<input
										className="radio-button"
										id="creation-date"
										type="radio"
										name="activationDate"  // Grouping the radio buttons
										value={state.playlist?.activationDate || ''}
										checked={state?.playlist?.activationDate === null}
										onChange={(e) => handleInputChange('activationDate', null)}
									/>
									Creation date (default)
								</label>
								<label className="radio-row">
									<input
										className="radio-button"
										id="activation-date"
										type="radio"
										name="activationDate"
										value={state.playlist?.activationDate || ''}
										checked={state?.playlist?.activationDate !== null}
										readOnly
									/>
									<input
										type="date"
										className="date-input"
										value={formatDate(state?.playlist?.activationDate) || ''}
										onChange={(e) => handleInputChange('activationDate', e.target.value)}
									/>
								</label>
							</div>
						</div>
						<div className="input-container">
							<p className="label">Disable this code:</p>
							<div className="radio-group">
								<label className="radio-row">
									<input
										className="radio-button"
										id="deactivate-never"
										type="radio"
										name="deactivationDate"  // Grouping the radio buttons
										value=""
										checked={state?.playlist?.deactivationDate === null}
										onChange={(e) => handleInputChange('deactivationDate', null)}
									/>
									Never (default)
								</label>
								<label className="radio-row">
									<input
										className="radio-button"
										id="deactivation-date"
										type="radio"
										name="deactivationDate"
										value=""
										checked={state?.playlist?.deactivationDate !== null}
										readOnly
									/>
									<input
										type="date"
										className="date-input"
										value={formatDate(state?.playlist?.deactivationDate) || ''}
										onChange={(e) => handleInputChange('deactivationDate', e.target.value)}
									/>
								</label>
							</div>
						</div>
						<div className="input-container">
							<p className="label">Activation limit:</p>
							<div className="radio-group">
								<label className="radio-row">
									<input
										className="radio-button"
										id="activationLimit-none"
										type="radio"
										name="activationLimit"  // Grouping the radio buttons
										checked={state?.playlist?.activationLimit === null}
										value=""
										onChange={(e) => handleInputChange('activationLimit', null)}
									/>
									None (default)
								</label>
								<label className="radio-row">
									<input
										className="radio-button"
										id="activation-limit"
										type="radio"
										name="activationLimit"
										checked={state?.playlist?.activationLimit !== null}
										value=""
										readOnly
									/>
									<input
										type="text"
										className="license-input"
										value={state?.playlist?.activationLimit || ''}
										onChange={(e) => handleInputChange('activationLimit', e.target.value)}
									/>
								</label>
							</div>
						</div>
					</div>
				</div>		
				<div className="about-container">				
					<div className="left-col">
						<div className="modal-header">
							<h2>About this screen</h2>
						</div>
						<div className="input-container">
							<p className="label">Activation code:</p>
							<p className="text-value bold-text">{state?.playlist?.shortCode || ''}</p>
						</div>
						<div className="input-container">
							<p className="label">Activations:</p>
							<p className="text-value">{devices.length} of {state?.playlist?.activationLimit || 'unlimited'}</p>
						</div>
						<div className="input-container">
							<p className="label">Valid from:</p>
							<p className="text-value">{formatDateMonthFirst(state?.playlist?.activationDate) || ''}</p>
						</div>
						<div className="input-container">
							<p className="label">Valid through:</p>
							<p className="text-value">{formatDateMonthFirst(state?.playlist?.deactivationDate) || 'unlimited'}</p>
						</div>
					</div>
					<div className="right-col">
						<button className="button cancel-button">Contact Support</button>
						<button className="button refresh-button" onClick={handleRefresh}>Refresh screen</button>
						<button className="button modal-button" onClick={handleSubmit}>Close</button>
					</div>
				</div>
			</div>
		</div>
    )
}

const StatusModal = ({ setStatusMessage, statusMessage }) => {

	useEffect(() => {
		// Prevent scrolling when modal is open
		document.body.classList.add("no-scroll");
	
		return () => {
			// Allow scrolling when modal is closed
			document.body.classList.remove("no-scroll");
		};
	}, []);

	const closeStatusModal = () => {
		setStatusMessage({message: null, error: false})
	}

    return (
		<div className="modal-wrapper">
			<div className="StatusModal modal">
				<p className={`${statusMessage.error ? "error" : ""}`}>{statusMessage.message}</p>
				<div className="bottom-container">
					<button className="button modal-button" onClick={closeStatusModal}>Ok</button>			
				</div>
			</div>
		</div>
    )
}

const UndoConfirmModal = ({ setShowUndoConfirmModal, undoChange }) => {

	useEffect(() => {
		// Prevent scrolling when modal is open
		document.body.classList.add("no-scroll");
	
		return () => {
			// Allow scrolling when modal is closed
			document.body.classList.remove("no-scroll");
		};
	}, []);

	const handleUndoConfirm = () => {
		undoChange();
		setShowUndoConfirmModal(false);
	}

	const closeUndoConfirmModal = () => {
		setShowUndoConfirmModal(false)
	}

    return (
		<div className="modal-wrapper">
			<div className="UndoConfirmModal modal">
				<p>This will remove all changes since the last time you saved. Are you sure?</p>
				<div className="bottom-container">
					<button className="button cancel-button" onClick={closeUndoConfirmModal}>Cancel</button>
					<button className="button modal-button" onClick={handleUndoConfirm}>Ok</button>			
				</div>
			</div>
		</div>
    )
}

export default ScreenPage
