import { v4 as uuidv4 } from 'uuid';

const saveCustomer = async (customer, uploadedImage, updatedBy) => {
    const imageKey = uuidv4();
    //Only upload image if there is a new file to upload
    const imageUploadUrl = uploadedImage.file 
    ? await fetch(`${process.env.REACT_APP_BIZ_BASE_URL}/api/customers/getCustomerImageUploadUrl/${customer.id}/${uploadedImage.type}/${imageKey}`)
        .then(response => response.json())
        .then(async url => {
            return await fetch(url, {
                method: 'PUT',
                body: uploadedImage.file,
                headers: {
                    'Content-Type': uploadedImage.file.type
                }
            })
            .then(response => {
                if (response.status === 200) {
                    return `${process.env.REACT_APP_AWS_CUSTOMER_LOGO_URL}/${customer.id}/${imageKey}.${uploadedImage.type}` 
                }
                return null
            })
            .catch(error => {
                console.error(error);
            });
        })
        .catch(e => {
            console.log(e)
        })
    : null;

    const url = `${process.env.REACT_APP_BIZ_BASE_URL}/api/customers/updateCustomer`;

    return await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            ...customer,
            customerLogo: imageUploadUrl ? imageUploadUrl : customer?.customerLogo,
            updatedBy
        })
    })
    .then(resp => {
        return resp
    })
    .catch((error) => {
        console.error(error);
    });
}

export {
    saveCustomer
}
