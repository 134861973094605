import { bizRequest } from "../../bizClient"; // Import the API helper

const getReports = async (id, setReportData) => {
    const reports = await bizRequest(`/api/reports/getReportsByCustomerId/${id}`)
    .then(data => {
        //Sort by most recently completed reports
        data.sort((a,b) => new Date(b.createdAt) - new Date(a.createdAt));
        setReportData(data)
        return data
    })
    return reports
}

const getInsightsPlaylists = async (customerId, setInsightsPlaylists) => {
    const reports = await bizRequest(`/api/playlists/getPlaylistByProductType/${customerId}/insights`)
    .then(data => {
        setInsightsPlaylists(data)
        return data
    })
    return reports
}

const removeReport = async (id, customerId, setReportData, setSelectedReport) => {
    try {
        await bizRequest(`/api/reports/deactivateReport/${id}`, 'PUT', {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        })
        .then(response => {
            //Refresh reports list
            getReports(customerId, setReportData);
            //Selected report is deleted so set to null
            setSelectedReport({});
        })
    } catch(e) {
        console.error('something went wrong', e);
    }
}

const formatDateDigits = (num) => {
    return num < 10 ? `0${num}` : num
}

const formatDate = (dateTime) => {
    const date = dateTime ? new Date(dateTime) : null
    return date
    ? `${date.getFullYear()}-${formatDateDigits(date.getMonth() + 1)}-${formatDateDigits(date.getDate())}`
    : null
}

const truncateReportName = (name) => {
    //If less than 35 characters, just show everything
    if (name.length <= 35) return name;

    return `${name.slice(0,35)}...`
}

const downloadCsv = async (reportId, reportLabel) => {
    const url = await bizRequest(`/api/reports/getAwsUrl/${reportId}.csv/${reportLabel}`)
    .then(async data => {
        const link = document.createElement('a')
        link.href = data
        link.setAttribute('download', `${reportLabel}.csv`)
        document.body.appendChild(link)
        link.click()
        link.remove()
        return data
    })
    .catch(e => {
        console.log(e)
    }) 
    return url
}

const getCsvUrl = async (fileName, label) => {
	const url = `/api/reports/getAwsUrl/${fileName}/${label}`;
    return await bizRequest(url)
    .then(data => {
        return data
    })
    .catch((error) => {
        console.error('Something went wrong:', error);
    });
}

export {
    getReports,
    getInsightsPlaylists,
    removeReport,
    formatDate,
    truncateReportName,
    downloadCsv,
    getCsvUrl
}
