import React from 'react';
import Select from 'react-select';
import Switch from "react-switch";
import { HexColorInput } from "react-colorful";
import { PopoverPicker } from '../ColorPicker/PopoverPicker';
import dropdownOptions from './dropdownOptions.json';
import constants from './constants.json';

const BrandingConfiguration = ({
    state,
    setState,
    setStateChanged,
    customer,
    oneViewOptions
}) => {

    const configs = state?.playlist?.brandingConfigs;
    const defaultConfigs = constants.DEFAULT_TRANSPORTATION_CONFIGS;

    const handleColorChange = (property, value) => {
        const tempConfigs = {...state.playlist.brandingConfigs};
        tempConfigs[property] = value;
        setState({
            ...state,
            playlist: {...state.playlist, brandingConfigs: tempConfigs}
        });
        setStateChanged(true);
    }

    const handleConfigChanges = (propNames, values) => {
        const tempConfigs = {...state.playlist.brandingConfigs};
        propNames.forEach((item, index) => {
            tempConfigs[item] = values[index];
        });
        setState({
            ...state,
            playlist: {...state.playlist, brandingConfigs: tempConfigs}
        });
        setStateChanged(true);
    }

    const isCustomizable = () => {
        let retval = true;

        // If a non-TransitScreen view was purhased
        let viewPurchased = oneViewOptions.some((option) => option.value !== 'transportation' && !option.isDisabled);
        if (state?.playlist?.product === 'screen_pro' && !viewPurchased) {
            retval = false;
        }

        return retval;
    }

    const isBrandingEnabled = (key) => {
        let retval = false;
        let interactivityConfigs = state?.playlist?.interactivityConfigs || {};
        for (let [configKey, value] of Object.entries(interactivityConfigs)) {
            if (configKey === key) {
                retval = value;
            }
        }

        return retval;
    }

    const getLabelAndColorPicker = (label, key) => {
        let onChange = (colorVal) => handleColorChange(key, colorVal);

        return <div className="input-container">
            <div className="label">{label}:</div>
            <HexColorInput
                className="input-color"
                color={configs && configs[key] ? configs[key] : defaultConfigs[key]}
                onChange={onChange}
                prefixed={true}
            />
            <PopoverPicker
                color={configs && configs[key] ? configs[key] : '#' + defaultConfigs[key]}
                onChange={onChange}
            />
        </div>
    }

    const getLabelAndSwitch = (label, key) => {
        let onChange = (checked) => handleConfigChanges([key], [checked]);

        return <div className="input-container">
            <div className="label">{label}:</div>
            <Switch
                className="switch"
                checked={ configs && key in configs ? configs[key] : defaultConfigs[key] }
                onChange={onChange}
                onColor="#2386EE"
                uncheckedIcon
                checkedIcon={false}
            />
        </div>
    }

    const getLabelAndSelect = (label, dropdownKey, configKey) => {
        let onChange = (e) => handleConfigChanges([configKey], [e.value]);

        return <div className="input-container">
            <div className="label">{label}:</div>
            <Select
                onChange={onChange}
                options={dropdownOptions[dropdownKey]}
                value={dropdownOptions[dropdownKey].find(option => option.value === (configs ? configs[configKey] : defaultConfigs[configKey]))}
                className={"dropdown-list"}
                defaultValue={dropdownOptions[dropdownKey][0]}
                styles={basicConfigDropdownStyling}
            />
        </div>
    }

    /* React-Select custom styling */
    const basicConfigDropdownStyling = {
        container: (baseStyles, state) => ({
          ...baseStyles,
          // Too much gray if the dropdown is disabled.
          boxShadow: state.isDisabled ? "unset" : "0px 2px 8px 4px rgba(0, 0, 0, 0.07)",
          borderRadius: "8px"
        }),
        control: (baseStyles, state) => ({
            ...baseStyles,
            border: "none",
            cursor: "pointer"
        }),
        indicatorSeparator: (baseStyles, state) => ({
            ...baseStyles,
            display: "none"
        }),
        option: (baseStyles, state) => ({
            ...baseStyles,
            color: state.isDisabled ? "#919191" : "inherit",
            cursor: "pointer"
        })
    };

    return (
        <div className="BrandingConfiguration">
            <div className="photo">
            </div>
            <div className="configuration">
                <h2>Branding</h2>

                <div className={isCustomizable() ? '' : 'disabled-section'}>
                    <h3>Primary colors and style</h3>
                    <div className="description">
                        Ensure your content is formatted properly by indicating where you plan to display it. If showing your content on a mounted TV or similar device, you will also need to set the orientation.
                    </div>
                    <div className="primary">
                        <div>
                            { getLabelAndColorPicker('Background', 'mainBg') }
                            { getLabelAndColorPicker('Text', 'mainText') }
                            { getLabelAndColorPicker('Accents', 'accentColor') }
                        </div>
                        <div>
                            { getLabelAndSelect('Icon style', 'UI', 'ui')}
                        </div>
                    </div>
                </div>

                <div className={isCustomizable() ? '' : 'disabled-section'}>
                    <h3>Information "cards"</h3>
                    <div className="description">
                        Ensure your content is formatted properly by indicating where you plan to display it. If showing your content on a mounted TV or similar device, you will also need to set the orientation.
                    </div>
                    <div className="cards">
                        <div className="cards__row">
                            { getLabelAndColorPicker('Background', 'cardBg') }
                            { getLabelAndSwitch('Card header', 'cardHeader') }
                        </div>
                        <div className="cards__row">
                            { getLabelAndColorPicker('Text', 'cardText') }
                            { getLabelAndSwitch('Soft corners', 'borderRounding') }
                        </div>
                        <div className="cards__row">
                            { getLabelAndColorPicker('Border', 'borderColor') }
                            { getLabelAndSwitch('Shadow', 'cardShadow') }
                        </div>
                    </div>
                </div>

                <div className={`${state?.playlist?.product === 'screen_pro' && !isBrandingEnabled('menuEnabled') ? 'disabled-section' : ''}`}>
                    <h3>TransitScreen&reg; Search and weather navigation bar</h3>
                    <div className="description"> Available only when enabled via the Interactivity features view.</div>
                    <div className="searchAndWeather">
                        <div>
                            { getLabelAndColorPicker('Background', 'menuBg') }
                            { getLabelAndColorPicker('Text', 'menuText') }
                        </div>
                    </div>
                </div>

                <div className={`${state?.playlist?.product === 'screen_pro' && !isBrandingEnabled('navBarEnabled') ? 'disabled-section' : ''}`}>
                    <h3>TransitScreen&reg; mode and trip planner navigation bar</h3>
                    <div className="description">Available only when enabled via the Interactivity features view.</div>
                    <div className="modeAndTripPlanner">
                        <div>
                            { getLabelAndColorPicker('Background', 'navBg') }
                            { getLabelAndColorPicker('Text', 'navText') }
                            { getLabelAndColorPicker('Border', 'borderColor') }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BrandingConfiguration;
