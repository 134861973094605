import React, { useEffect, useState, createContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { setAccessToken } from "../bizClient";

const Context = createContext([{}, () => {}]);

const DEFAULT_STATE = {
  customer: {}, // Existing state
  userData: {}, // Existing state
};

const Provider = (props) => {
  const [state, setState] = useState(DEFAULT_STATE);
  return <Context.Provider value={[state, setState]}>{props.children}</Context.Provider>;
};

export { Context, Provider };
