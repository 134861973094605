import React, { useState, useEffect } from 'react';
import {
	NavLink,
} from 'react-router-dom';
import Select from 'react-select';

import logo from '../../images/logos/af-logo.svg';
import openReport from '../../images/icons/analytics/open-report.svg';
import downloadReport from '../../images/icons/analytics/download.svg';
import disabledDownloadReport from '../../images/icons/analytics/download-disabled.svg';
import deleteReport from '../../images/icons/analytics/trash.svg';
import clip from '../../images/icons/analytics/clip.svg';
import disabledClip from '../../images/icons/analytics/clip-disabled.svg';
import lightClip from '../../images/icons/analytics/clip-light.svg';
import popout from '../../images/icons/analytics/popout.svg';
import disabledPopout from '../../images/icons/analytics/popout-disabled.svg';
import disabledDeleteReport from '../../images/icons/analytics/trash-disabled.svg';
import sort from '../../images/icons/analytics/sort.svg';
import filter from '../../images/icons/analytics/filter.svg';
import refresh from '../../images/icons/analytics/refresh.svg';
import lightbulb from '../../images/icons/analytics/lightbulb.svg'
import recreate from '../../images/icons/analytics/recreate.svg'


import { getReports, removeReport, truncateReportName, downloadCsv, getInsightsPlaylists, getCsvUrl } from './controller';
import { constants, formatDate } from '../../utils'

const ReportAnalyticsPage = ({
	userData,
	customer,
	lang
}) => {

	const [openModal, setOpenModal] = useState(false);
	const [openInsightsModal, setOpenInsightsModal] = useState(false);
	const [highlightedReport, setHighlightedReport] = useState({});
	const [selectedReport, setSelectedReport] = useState({});
	const [insightsPlaylists, setInsightsPlaylists] = useState([]);
	const [selectedAddressLabel, setSelectedAddressLabel] = useState('');
	const [selectedUrl, setSelectedUrl] = useState({value: '1-77NIUTa5gQGN0y9lXadBR5' ,label: 'Location - Standard'});
	const [dropdownOptions, setDropdownOptions] = useState([]);
	const [reportData, setReportData] = useState([])
	const [showRemoveModal, setShowRemoveModal] = useState(false);
	const [showRecreateModal, setShowRecreateModal] = useState(false);


	const urlOptions = [
		{value: '1-77NIUTa5gQGN0y9lXadBR5' ,label: 'Location - Standard'},
		{value: '1-3cUT5LPdQdf4nt4T8cKb1e' ,label: 'Location - Advanced'},
		{value: '1-6gspMOHVYeXnZGnXIJZn1y' ,label: 'Relocation - Driving only'},
		{value: '1-1QoqmwXIYRIFvuFyFj0mtf' ,label: 'Relocation - Transit only'},
		{value: '1-5OGsPYkal3PgB4OtZZ045J' ,label: 'Relocation - Advanced (with AI)'},
		// {value: '1-77NIUTa5gQGN0y9lXadBR5' ,label: 'View all results'},
	]

	useEffect(() => {
		getReports(customer.id, setReportData);
		getInsightsPlaylists(customer.id, setInsightsPlaylists);
	}, [])

	const handleReportSelection = async (report) => {
		if (report.status === 'ready' && Object.keys(highlightedReport).length !== 0) {
			const userInputs = report?.userInputs;
			await getCsvUrl(userInputs?.fileName, userInputs?.reportName)
			.then(url => {
				setSelectedReport({
					...report,
					downloadUrl: url
				});
				const labelOptions = report.addressLabels.map(label => {
					return {
						value: label,
						label
					}
				})
				setDropdownOptions(labelOptions);
				setSelectedAddressLabel(labelOptions[0] || {value: '', label: 'Select...'});
				setOpenModal(false);
			})
		}
	}

	return ( 
		<div className="ReportAnalyticsPage">
			<div className="report-selector">
				<button className="open-report pure-u-1-5" onClick={() => setOpenModal(true)}>
					<img className="open-icon" src={openReport}/>
				</button>
				<div className="report-name pure-u-1-7">
					{
						selectedReport.name
						? <div className="name-container">
							<p className="name">{truncateReportName(selectedReport.name)}</p>
							<p>Generated on {formatDate(selectedReport.generatedDate)} by {selectedReport.createdBy}</p>
						</div>							
						: <div className="name-container">
							<p className="name">No report selected.</p>
							<p>Open a report to view analytics</p>
						</div>	
					}
				</div>
				<div className="report-dropdown pure-u-1-5">
					<Select  
						onChange={(option) => setSelectedAddressLabel(option)}
						options={dropdownOptions}
						value={selectedAddressLabel}
						className={"dropdown"}
						isDisabled={dropdownOptions.length === 0}
						styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
					/>
				</div>
				<div className="report-dropdown pure-u-1-5">
					<Select  
						onChange={(option) => setSelectedUrl(option)}
						options={urlOptions}
						value={selectedUrl}
						className={"dropdown"}
						isDisabled={!selectedReport?.id}
						styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
					/>
				</div>
				<div className="report-actions pure-u-1-4">
					<div className={`icon-container insight-icon`} title="Send Actionfigure Insight link" onClick={() => setOpenInsightsModal(true)}>
						<img className="icon" src={lightbulb} />
					</div>
					<div className="vertical-line"></div>
					<div className={`icon-container`} title="Recreate report" onClick={selectedReport?.id ? () => setShowRecreateModal(true) : null}>
						<img className="icon" src={recreate} />
					</div>
					<div className={`icon-container ${selectedReport.name ? "" : "disabled"}`} title="Copy Link" onClick={() => navigator.clipboard.writeText(`${process.env.REACT_APP_LOADER_URL}/${selectedReport.shortCode}`)}>
						<img className="icon" src={selectedReport.name ? clip : disabledClip} />
					</div>
					{/* Open go.actionfigure.ai link if there is a short code*/}
					<div className={`icon-container ${selectedReport.name ? "" : "disabled"}`} title="Open in new window" onClick={() => selectedReport.shortCode ? window.open(`${process.env.REACT_APP_LOADER_URL}/${selectedReport.shortCode}`, '_blank').focus() : null}>
						<img className="icon" src={selectedReport.name ? popout : disabledPopout} />
					</div>
					<div className={`icon-container ${selectedReport.name ? "" : "disabled"}`} title="Download data" onClick={() => downloadCsv(selectedReport.id, selectedReport.name)}>
						<img className="icon" src={selectedReport.name ? downloadReport : disabledDownloadReport} />
					</div>
					<div className="vertical-line"></div>
					<div className={`icon-container ${selectedReport.name ? "" : "disabled"}`} title="Delete report" onClick={() => setShowRemoveModal(true)}>
						<img className="icon" src={selectedReport.name ? deleteReport : disabledDeleteReport} />
					</div>
				</div>
			</div>
			{
				openModal
				? <Modal 
					reportData={reportData} 
					highlightedReport={highlightedReport}
					setHighlightedReport={setHighlightedReport}
					setOpenModal={setOpenModal}
					handleReportSelection={handleReportSelection}
					selectedReport={selectedReport}
					customerId={customer.id}
					setReportData={setReportData}
				/>
				: <></>
			}
			{
				openInsightsModal
				? <InsightsModal insightsPlaylists={insightsPlaylists} setOpenInsightsModal={setOpenInsightsModal}/>
				: <></>
			}
			{
				showRemoveModal
				? <RemoveModal
					selectedReport={selectedReport}
					customerId={customer.id}
					setReportData={setReportData}
					setSelectedReport={setSelectedReport}
					setShowRemoveModal={setShowRemoveModal}
					setDropdownOptions={setDropdownOptions}
				/>
				: <></>
			}
			{
				showRecreateModal && selectedReport?.userInputs !== null
				? <RecreateModal
					setShowRecreateModal={setShowRecreateModal}
					userInputs={selectedReport?.userInputs}
					downloadUrl={selectedReport?.downloadUrl}
					userData={userData}
					customer={customer}
					lang={lang}
				/>
				: <></>
			}
			<div className="iframe-container">
				<iframe className="sigma-iframe" zoom='50' width='100%' height='100%' src={`https://app.sigmacomputing.com/embed/${selectedUrl.value}?ReportID=${selectedReport?.id}&Office-for-Analysis=${selectedAddressLabel?.value}&:show_footer=false`}/>
			</div>
		</div>
	)
}

const InsightsModal = ({insightsPlaylists, setOpenInsightsModal}) => {

	const [userInputs, setUserInputs] = useState({
		shortCode: '',
		personId: '',
		link: ''
	})

	const handleInputChange = (val, param) => {
		const tempInputs = {...userInputs};
		tempInputs[param] = val;
		setUserInputs(tempInputs);
	}

    return (
        <div className="foresight-modal">
			<div className="input-container">
				<p className="pure-u-1-8">Instance: </p>
				<Select
					onChange={(e) => handleInputChange(e.value, 'shortCode')}
					options={insightsPlaylists.map(pl => {
						return { value: pl.shortCode, label: pl.shortCode }
					})}
					value={{ value: userInputs.shortCode, label: userInputs.shortCode }}
					className={"dropdown text-input pure-u-1-3"}
					styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
				/>
			</div>
			<div className="input-container">
				<p className="pure-u-1-8">Person ID: </p>
				<input className={`text-input pure-u-1-3`} onChange={(e) => handleInputChange(e.target.value, 'personId')} value={userInputs.personId}></input>
			</div>
			<div className="input-container">
				<p className="pure-u-1-8">Insight link: </p>
				<input className={`text-input pure-u-1-3`} onChange={(e) => handleInputChange(e.target.value, 'link')} value={`${process.env.REACT_APP_LOADER_URL}/${userInputs.shortCode}?personId=${userInputs.personId}`} disabled></input>
				<div className="icon-container" title="Copy Link" onClick={() => navigator.clipboard.writeText(`${process.env.REACT_APP_LOADER_URL}/${userInputs.shortCode}?personId=${userInputs.personId}`)}>
					<img className="icon" src={lightClip} />
				</div>
			</div>
			<button className="done-button" onClick={() => setOpenInsightsModal(false)}>Done</button>
        </div>
    )
}

const Modal = ({reportData, highlightedReport, setHighlightedReport, setOpenModal, handleReportSelection, selectedReport, customerId, setReportData }) => {

	const [searchOwner, setSearchOwner] = useState(null);
	const [filteredReports, setFilteredReports] = useState([...reportData]);

	const filterReports = (searchString) => {
		//Update search value
        setSearchOwner(searchString);
		setFilteredReports(searchString.length > 0 ? reportData.filter(report => {
			if (report.createdBy) {
				return report.createdBy.slice(0, searchString.length).toLowerCase() === searchString.toLowerCase();   
			}
			return false
		 }) : reportData); //If no search string, set to regular customers array
	}

	const reportRows = filteredReports.length === 0 || !filteredReports
	? <tr><td>No reports are avaialble</td></tr>
	: filteredReports.map((report, index) => {
		const formattedGenerationTime = formatDate(report.createdAt);

		return <tr 
			key={report.id} 
			className={`report-row ${report.id === highlightedReport.id ? "selected" : ""} ${report.status === 'processing' || report.status === 'preparing' ? "processing" : ""} ${report.id === selectedReport.id ? 'current-report' : ""}`} 
			onClick={() => report.status === 'ready' ? setHighlightedReport(report) : null}
		>
				<td>{report.name}</td>			
				<td>{formattedGenerationTime}</td>
				<td>{report.createdBy}</td>
				<td>{report.status.charAt(0).toUpperCase() + report.status.slice(1)}</td>
		</tr>
	})

    return (
        <div className="modal">
			<div className="modal-header">
				<h2>Your Foresight reports</h2>
				<div className="header-buttons">
					<button className="header-button" title="Coming soon"><img src={sort} /></button>
					<button className="header-button" title="Coming soon"><img src={filter} /></button>
					<button onClick={() => getReports(customerId, setReportData)} className="header-button"><img src={refresh} /></button>
				</div>
			</div>
			<div className="table-container">
				<table className="reports-table">
					<tbody>
						<tr className="header-row">
							<th className="table-header">Name</th>
							<th className="table-header">Date</th>
							<th className="table-header">Owner</th>
							<th className="table-header">Status</th>
						</tr>
						{reportRows}
					</tbody>
				</table>
			</div>
			<div className="bottom-container">
				<div className="filter-container">
                    <p>Search</p>
                    <input 
                        onChange={(e) => filterReports(e.target.value)}
                        className={"owner-search"}
                        value={searchOwner || ''}
                    />
                </div> 
				<div className="buttons-container">
					<button className="modal-button cancel" onClick={() => setOpenModal(false)}>Cancel</button>
					<button className={`modal-button ${Object.keys(highlightedReport).length === 0 ? 'disabled-button' : " "}`} onClick={() => handleReportSelection(highlightedReport)}>Open</button>
				</div>
			</div>
        </div>
    )
}

const RemoveModal = ({ selectedReport, customerId, setReportData, setSelectedReport, setShowRemoveModal, setDropdownOptions }) => {
	
	const handleRemove = () => {
		removeReport(selectedReport.id, customerId, setReportData, setSelectedReport);
		setDropdownOptions([]);
		setShowRemoveModal(false);
	}

    return (
        <div className="modal">
			<h2>Are you sure you want to delete {selectedReport.name}?</h2>
			<div className="remove-container">
					<button className="modal-button cancel" onClick={() => setShowRemoveModal(false)}>No</button>
					<button className={`modal-button`} onClick={handleRemove}>Yes</button>
			</div>
        </div>
    )
}

const RecreateModal = ({ setShowRecreateModal, userInputs, downloadUrl, userData, customer, lang }) => {

	const formatAddress = (label, address) => {
		if (!label || label === '') return;
		return `${label} - ${address.address}`
	}

    return (
        <div className="recreate-modal modal">
			<h2 style={{textAlign: 'left'}}>Recreate report</h2>
			<div className="scroll-section">
				<div className="input-container">
					<p className="pure-u-1-5">Direction of travel:</p>
					<p className="pure-u-1-2">{userInputs?.isFromOffice?.label}</p>
				</div>
				<div className="input-container">
					<p className="pure-u-1-5">Day of week:</p>
					<p className="pure-u-1-2">{constants.WEEKDAYS[userInputs?.selectedDay.value]}</p>
				</div>
				<div className="input-container">
					<p className="pure-u-1-5">Time of day:</p>
					<p className="pure-u-1-2">{userInputs?.travelTime?.value}</p>
				</div>
				<div className="input-container">
					<p className="pure-u-1-5">Current office address:</p>
					<p className="pure-u-1-2">{formatAddress(userInputs?.addressLabels[0], userInputs?.addresses[0])}</p>
				</div>
				<div className="input-container">
					<p className="pure-u-1-5">Prospective address 1:</p>
					<p className="pure-u-1-2">{formatAddress(userInputs?.addressLabels[1], userInputs?.addresses[1])}</p>
				</div>
				<div className="input-container">
					<p className="pure-u-1-5">Prospective address 2:</p>
					<p className="pure-u-1-2">{formatAddress(userInputs?.addressLabels[2], userInputs?.addresses[2])}</p>
				</div>
				<div className="input-container">
					<p className="pure-u-1-5">Prospective address 3:</p>
					<p className="pure-u-1-2">{formatAddress(userInputs?.addressLabels[3], userInputs?.addresses[3])}</p>
				</div>
				<div className="input-container">
					<p className="pure-u-1-5">Prospective address 4:</p>
					<p className="pure-u-1-2">{formatAddress(userInputs?.addressLabels[4], userInputs?.addresses[4])}</p>
				</div>
				<div className="input-container">
					<p className="pure-u-1-5">Prospective address 5:</p>
					<p className="pure-u-1-2">{userInputs?.addresses[5]}</p>
				</div>
				<div className="input-container">
					<p className="pure-u-1-5">Employee location list:</p>
					<p className="pure-u-1-2"><a href={downloadUrl} download={userInputs.reportName}>Download file</a></p>
				</div>
			</div>
			<div className="remove-container">
				<button className="modal-button cancel" onClick={() => setShowRecreateModal(false)}>Cancel</button>
				<NavLink 
					to={{
						pathname: '/foresight',
						userInputs: userInputs
					}} 
					className={`modal-button`}
				>
					<p style={{marginTop: '0.6em'}}>Recreate report</p>
				</NavLink>
			</div>
        </div>
    )
}

export default ReportAnalyticsPage;
