const config = {
    version: '0.9.2',
    oktaPasswordRecoveryURL: 'https://dev-9116264.okta.com/api/v1/authn/recovery/password',
    oktaUserCookieName: "ts-dash-user",
    rememberUserLoginCookieName: "ts-dash-login",
    loggedInHomeEndpoint: "/",    
};

const googleMapScriptLibraries = ['places'];

const auth0AuthorizationParams = {
    audience: "https://actionfigure.us.auth0.com/api/v2/",
    scope: "openid profile email offline_access",
}

export {
    config,
    googleMapScriptLibraries,
    auth0AuthorizationParams
};